import axios from 'axios';
import DomainService from './domain';

export default class SalesService {
    domainService = new DomainService();

    getSales(token) {
        return axios.get(this.domainService.getActiveDomain() + `admin/user/${token}/sales`).then(res => res.data).catch(err => {
            if(err.status === 400 || err.status === 401){
                alert(err.error.message);
                return null;
            }
        })
    }
}
