import React, { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import { HEADER_BUTTONS } from './AppHeaderControllers';
import { Route, useLocation } from 'react-router-dom';
import './StoreDashboard';
import { useHistory } from 'react-router-dom';
import AuthService from './service/auth.service';
import { Button } from 'primereact/button';
import { storeDashboard } from './StoreDashboard';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import i18n from './service/i18n';
import AppLanguageService from './service/app-language.service';

const AppTopbar = (props) => {
    const appLangS = new AppLanguageService();
    const { t, i18n } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(i18n.language);
    const notificationsItemClassName = classNames('notifications-item', { 'active-menuitem': props.topbarNotificationMenuActive });
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });
    const [isMobile, setIsMobile] = useState([]);
    const [isLoged, setIsLoged] = useState(false);
    const [profile, setProfile] = useState(null);
    const history = useHistory();
    const authService = new AuthService();
    const options = [
        { label: t('english'), value: 'en' },
        { label: t('german'), value: 'de' }
    ];
    const isRunningInElectron = window.electron?.isElectron;

    function redirect(path){
        history.push(path);
    }
    useEffect(() => {
        if(navigator.userAgent.includes('Electron')){
            let fullAppPath
            if(navigator.platform.includes("Win") || navigator.platform.includes('Linux')){
                fullAppPath = window.location.href.split('resources/app.asar/build/index.html')[0]
                console.log('win', 'lin')
            }else{
                fullAppPath = window.location.href.split('Backoffice.app/Contents/Resources')[0]
                console.log('mac')
            }
            let splitAppPath = fullAppPath.split('file://')[1]
            console.log('splitAppPath', splitAppPath)
            const fetchLanguage = async () => {
                const appLang = await appLangS.loadAppLanguage(splitAppPath);
                setSelectedOption(appLang)
                i18n.changeLanguage(appLang);
            }
            fetchLanguage();
        }else{
            i18n.changeLanguage(selectedOption)
        }
        setIsMobile(window.innerWidth < 800);
        storeDashboard.subscribe((action) => {
            if(action == 2){
                setProfile(authService.getProfile());
            }
        }); 
        setIsLoged(authService.isLoged());
        if(isLoged) setProfile(authService.getProfile());
    }, [isLoged]);


    const { pathname } = useLocation();
    const buttons = isMobile ? HEADER_BUTTONS[pathname] || [] : [];

    const login = () => {
        if(!isLoged){
            history.push("/login");
        }
    }
    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <div className='topbar-left-menu'>
                    <button type="button" className="menu-button p-link mr-2" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-left"></i>
                    </button>

                    <span className="topbar-separator"></span>

                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <AppBreadcrumb routers={props.routers} />
                    </div>
                </div>
                <div className='topbar-left-user-block'>
                    <Dropdown value={selectedOption} options={options} onChange={(e) => {setSelectedOption(e.value); i18n.changeLanguage(e.value)}} placeholder={selectedOption} className='lang-dropdown mr-5' />
               {
                    isLoged && profile?.profilePicLink ? 
                        <img className="mobile-logo marg-left-auto" src={profile?.profilePicLink} onClick={() => history.push("/showOrganizer")}/>
                        : 
                        <Button type="button" icon="pi pi-user" className='default-icon marg-left-auto' onClick={login}></Button>
               }
               </div>
            </div>

            <AppMenu model={props.menu} menuMode={props.menuMode} active={props.menuActive} mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick} onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick}></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                <ul className="topbar-menu">
                    {
                      
                            buttons.map((btn, index) => {
                                return (
                                    <li className='search-item' key={index}>

                                        <button type="button" className='p-link' onClick={btn.onClick || (() => history.push(btn.path))}>
                                            <i className={btn.icon}></i>
                                        </button>
                                    </li>
                                )
                            })
                    
                    }
                    <li className={notificationsItemClassName}>
                        <ul className="notifications-menu fade-in-up">
                            <li role="menuitem">
                                <button type="button" className="p-link" tabIndex="0">
                                    <i className="pi pi-shopping-cart"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">New Order</div>
                                        <div className="notification-detail">You have <strong>3</strong> new orders.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-check-square"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Withdrawn Completed</div>
                                        <div className="notification-detail">Funds are on their way.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-chart-line"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Monthly Reports</div>
                                        <div className="notification-detail">New reports are ready.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-comments"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Comments</div>
                                        <div className="notification-detail"><strong>2</strong> new comments.</div>
                                    </div>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button type="button" className="p-link">
                                    <i className="pi pi-exclamation-circle"></i>
                                    <div className="notification-item">
                                        <div className="notification-summary">Chargeback Request</div>
                                        <div className="notification-detail"><strong>1</strong> to review.</div>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className={profileItemClassName}>
                        {/* <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src="assets/demo/images/avatar/profile.jpg" alt="diamond-layout" className="profile-image" />
                            <span className="profile-name">Amelia Stone</span>
                        </button> */}
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-user"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-cog"></i>
                                    <span>Settings</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-calendar"></i>
                                    <span>Calendar</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-inbox"></i>
                                    <span>Inbox</span>
                                </button>
                            </li>
                            <li>
                                <button type="button" className="p-link">
                                    <i className="pi pi-power-off"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppTopbar;
