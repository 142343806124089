import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Checkbox } from 'primereact/checkbox';
import couponImg from '../../../assets/images/pay/coupon.png';
import creditImg from '../../../assets/images/pay/credit.png';
import giropayImg from '../../../assets/images/pay/giropay.png';
import paypalImg from '../../../assets/images/pay/paypal.png';
import sofortImg from '../../../assets/images/pay/sofort.png';
import { InputNumber } from 'primereact/inputnumber';

const SettingsGeneral = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);

    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])

    const save = () => {
        setEventDialog(false);

        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)

        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };

    const reset = () => {
        const { title, start, end } = clickedEvent;
        setChangedEvent({ title, start, end, allDay: null });
    };

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
        </>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-2 mb-2" />
        </div>
    );


    return (
        <div class="card">
            <div class="p-flex-group mt-49 flex-align">
                <div className="group-block w-49">
                    <label htmlFor='iban' class="sized">{t('IBAN')}</label>
                    <InputNumber id='iban'  value={null} options={null}  />
                </div>
                <div className="group-block w-50">
                    <label htmlFor='bic' class="sized">Bic</label>
                    <InputText id='bic' value={null} options={null}  />
                </div>
            </div>
            <div className="field w-100 mt-30">
                <label htmlFor='bic' class="sized">Bank</label>
                <InputText id='bic' className='mt-1' value={null} options={null}  />
            </div>
            <div class="p-flex-group jcsb mt-30 payment-row">
                <div className="group-block w-15 mr-15">
                    <label className='sized'>{t('activatedPaymentOptions')}</label>
                    <div className='flex jcc' style={{alignItems: "center"}}>
                        <Image height='30' className='inverted-img' src={paypalImg}></Image>
                        <Checkbox className='ml-10'></Checkbox>
                    </div>
                </div>
                <div className="group-block w-15 mr-15">
                    <label className='sized text-center'>{t('creditCard')}</label>
                    <div className='flex jcc' style={{alignItems: "center"}}>
                        <Image height='30' className='inverted-img' src={creditImg}></Image>
                        <Checkbox className='ml-10' checked={true}></Checkbox>
                    </div>
                </div>
                <div className="group-block w-15 mr-15">
                    <label className='sized text-center'>GiroPay</label>
                    <div className='flex jcc' style={{alignItems: "center"}}>
                        <Image height='30' src={giropayImg}></Image>
                        <Checkbox className='ml-10'></Checkbox>
                    </div>
                </div>
                <div className="group-block w-15 mr-15">
                    <label className='sized text-center'>{t('sofortPayment')}</label>
                    <div className='flex jcc' style={{alignItems: "center"}}>
                        <Image height='30' src={sofortImg}></Image>
                        <Checkbox className='ml-10'></Checkbox>
                    </div>
                </div>
                <div className="group-block w-15 mr-15">
                    <label className='sized text-center'>{t('coupon')}</label>
                    <div className='flex jcc' style={{alignItems: "center"}}>
                        <Image height='30' className='inverted-img' src={couponImg}></Image>
                        <Checkbox className='ml-10'></Checkbox>
                    </div>
                </div>
            </div>
            <div class="p-flex-group mt-30">
                <div className="group-block w-49 mr-15">
                    <label class="sized">{t('priceOnInvoice')}</label>
                    <Dropdown className='border-custom' placeholder={t('priceUsedGrossPrice')} value={null} options={null}  />
                </div>
                <div className="group-block w-49">
                    <label class="sized">{t('vat')}</label>
                    <InputText  value={null} options={null}  />
                </div>
            </div>
            <div class="p-flex-group mt-30">
                <div className="group-block w-100 mr-15">
                    <label class="sized">{t('NOTE_MAX_STRING')}</label>
                    <InputText className='mt-10' />
                    <InputText className='mt-20' />
                    <InputText className='mt-20' />
                </div>
            </div>
            <div class="p-flex-group mt-30">
                <Button label={t('save')} className='uppercase'></Button>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SettingsGeneral, comparisonFn);
