import React, { useEffect, useState, useRef } from 'react';
import { t } from 'i18next';


const Impressum = () => {

    return(
        <div>
        <div className='main-banner'>
            <div className='darker'></div>
            <h2 className='legal-header'>{t('IMPRINT_SIMPLE')}</h2>
        </div>
        <div className='legal-content'>
            <div className='contact-content mt-35'>
                <h4 className='legal-content-header'>
                    Anbieterkennzeichnung gem. § TDG, verantwortlich:
                </h4>
                <div className='legal-content-text'>
                    <p>
                    (IT)² Interactive GmbH, Joseph-von-Fraunhofer Str. 20, Technologiezentrum, 44227 Dortmund
                    vertretungsberechtigter Geschäftsführer: Dipl. Inform. Klaus Lewerenz
                    </p>
                    <p>E-Mail: info@it2in.de</p>
                    <p>Tel: 0231/ 97 00 382</p>
                    <p>Registergericht: Amtsgericht Frankfurt am Main</p>
                    <p>Register-Nr.: HRB 13654</p>
                    <p>Inhaltlich Verantwortlicher nach § 55 Abs. 2 RSTV: Klaus Lewerenz</p>
                </div>
            </div>
            <div className='info-content mt-30'>
                <h4 className='legal-content-header'>Rechtliche Hinweise, Qualitätssicherung, Haftungsausschluss</h4>
                <div className='legal-content-text'>
                    <p>
                        Diese Website wurde mit höchster Sorgfalt zusammengestellt. Für die Fehlerfreiheit und Genauigkeit der 
                        enthaltenen Informationen übernimmt die (IT)² Interactive GmbH, nachfolgend (IT)² Interactive genannt dennoch keine Gewähr. 
                        Alle Inhalte des spooorts Portals werden Ihnen ohne Haftung seitens des Herausgebers (IT)² Interactive zur Verfügung gestellt.(IT)² 
                        Interactive behält sich das Recht auf Änderungen oder Ergänzungen der bereitgestellten Informationen vor. Jegliche Haftung für Schäden, 
                        die direkt oder indirekt aus der Benutzung dieser Website entstehen, ist ausgeschlossen, soweit diese nicht auf Vorsatz oder grober 
                        Fahrlässigkeit beruhen. Trotz sorgfältiger inhaltlicher Kontrolle übernimmt (IT)² Interactive keine Haftung für die Inhalte externer Links. 
                        Für den Inhalt der verlinkten Seiten sind ausschließlich deren Herausgeber verantwortlich. Der Inhalt des spooorts Portals ist urheberrechtlich geschützt. 
                    </p>
                </div>
            </div>
        </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Impressum, comparisonFn);