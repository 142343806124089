import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Checkbox } from 'primereact/checkbox';
import instagramImg from '../../../assets/images/social/instagram.png';
import facebookImg from '../../../assets/images/social/facebook.png';
import whatsappImg from '../../../assets/images/social/whatsapp.png';
import youtubeImg from '../../../assets/images/social/youtube.png';
import xingImg from '../../../assets/images/social/xing.png';
import linkedinImg from '../../../assets/images/social/linkedin.png';
import twitterImg from '../../../assets/images/social/twitter@2x.png';
import andererImg from '../../../assets/images/social/social.png';

const SettingsSocial = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);

    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])

    const save = () => {
        setEventDialog(false);

        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)

        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };

    const reset = () => {
        const { title, start, end } = clickedEvent;
        setChangedEvent({ title, start, end, allDay: null });
    };

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
        </>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-2 mb-2" />
        </div>
    );


    return (
        <div class="card">
            <div class="p-flex-group jcsb mt-30">
                <div className="flex  w-49 mr-15 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            Instagram
                        </label>
                        <Image height='30' className='inverted-img' src={instagramImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
                <div className="flex w-49 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            Facebook
                        </label>
                        <Image height='30' className='inverted-img' src={facebookImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="flex  w-49 mr-15 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            Whatsapp
                        </label>
                        <Image height='30' src={whatsappImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
                <div className="flex w-49 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            YouTube
                        </label>
                        <Image height='30'className='inverted-img' src={youtubeImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="flex  w-49 mr-15 social">
                    <div className="flex-column g-5 text-center" style={{minWidth: "54px"}}>
                        <label className='sized'>
                            Xing
                        </label>
                        <Image height='30' className='inverted-img' src={xingImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
                <div className="flex w-49 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            LinkedIn
                        </label>
                        <Image height='30' className='inverted-img' src={linkedinImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="flex  w-49 mr-15 social">
                    <div className="flex-column g-5 text-center" style={{minWidth: "54px"}}>
                        <label className='sized'>
                            Twitter
                        </label>
                        <Image height='30' src={twitterImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
                <div className="flex w-49 social">
                    <div className="flex-column g-5 text-center">
                        <label className='sized'>
                            {t('another')}
                        </label>
                        <Image height='30' className='inverted-img' src={andererImg}></Image>
                    </div>
                    <div className='flex align-center ml-10 w-100'>
                        <InputText className='w-100'></InputText>
                    </div>
                </div>
            </div>
            
            <div class="p-flex-group mt-30">
                <Button label={t('save')} className='uppercase'></Button>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SettingsSocial, comparisonFn);
