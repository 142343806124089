import { Dialog } from "primereact/dialog";
import React, { useEffect, useState, useTransition } from "react";
import MapService from "../../../../service/map.service";
import { Button } from "primereact/button";
import { Galleria } from 'primereact/galleria';
import { t } from "i18next";

const mapService = new MapService();

const responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

const ImagectrlDialog = (props) => {

    const itemTemplate = (item) => {
        return <div className="image-gallery" style={{height: "400px", maxWidth: "100%", overflow: "hidden" }}><img  src={item.itemImageSrc} alt={item.alt} style={{ height: '100%', display: 'block' }} /></div>
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: '100%', maxHeight: "70px", display: 'block' }} />
    }

    const imageUpload = () => {
        props.upload();
    }

    const deleteAllImages = () => {
        props.delete();
    }

    const closeDialog = () => {
        props.close();
    }

    return (
        <Dialog className='dialog-container' visible={props.visible} onHide={closeDialog} style={{ width: '600px' }} modal>
            <Button style={props.style} label={t('uploadPhoto')} className='p-button-outlined' onClick={imageUpload}></Button>
            <Galleria value={props.images} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px' }}
                item={itemTemplate} thumbnail={thumbnailTemplate} />
            <br/>
            <div className="flex g-5">
                <Button style={props.style} label={t('DELETE_ALL_PHOTOS')} className='p-button-outlined' onClick={deleteAllImages}></Button>
                <Button style={props.style} label={t('close')} className='p-button-outlined' onClick={closeDialog}></Button>
            </div>
        </Dialog>
    );
}

export default ImagectrlDialog;
