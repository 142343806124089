import React, { useEffect, useState, useRef } from 'react';
import { t } from 'i18next';
import { Button } from 'primereact/button';


const LegalContacts = () => {

    return(
        <div>
        <div className='main-banner'>
            <div className='darker'></div>
            <h2 className='legal-header'>{t('legal_contact')}</h2>
        </div>
        <div className='legal-content'>
            <div className='info-content mt-35'>
                <h4 className='legal-content-header'></h4>
                <div className='legal-content-text'>
                   <p>Haben Sie Fragen, Wünsche, Anregungen oder möchten Sie uns einfach etwas mitteilen. Dann sind Sie hier genau richtig! Geben Sie einfach Ihre persönlichen Daten und Ihre Nachricht ein und drücken Sie dann auf Absenden.</p>
                </div>
                <Button className='w-40 mt-30 '>{t('write_mail')}</Button>
            </div>
        </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LegalContacts, comparisonFn);