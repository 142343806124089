import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import countries from '../../../assets/static/countries.json';
import regions from '../../../assets/static/regions.json';
import AuthService from '../../../service/auth.service';


const OrganizerData = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }
    const authService = new AuthService();
    const [profile, setProfile] = useState(authService.getEmptyProfile());

    useEffect(() => {
        authService.getPrivateProfile().then(profile => {
           if (profile) {
               setProfile(profile);
           }
       });
    }, [])

    const save = () => {
        let token = authService.getToken();
        authService.updateProfile(profile, token);
    };

    const reset = () => {
        setProfile(authService.getEmptyProfile());
    };

    const setFirstLastNames = (event) => {
        let names = event.target.value.split(' ');
        if (names.length > 0) {
            setProfile(prev => ({
                ...prev,
                forName: names[0]
            }));
        }
        if (names.length > 1) {
            setProfile(prev => ({
                ...prev,
                surName: names[1]
            }));
        }
    }

    const footer = (
        <>
        <div className='p-flex-group mt-30'>
            <Button type="button" label={t('save')} onClick={save} />
        </div>
        </>
    );

    return (
        <div>
            <div className='card'>
                <div class="p-flex-group">
                    <div className="group-block w-100">
                        <label>{t('organizerName')}</label>
                        <InputText className='w-100' placeholder={profile.forName + " " + profile.surName} onChange={setFirstLastNames} />
                    </div>
                </div>
                <hr />
                <div class="p-flex-group mt-20">
                    <div className="group-block w-65">
                        <label>{t('street')}</label>
                        <InputText placeholder={profile?.homeStreet} onChange={e => setProfile(prev => ({
                            ...prev,
                            homeStreet: e.target.value
                        }))} />
                    </div>
                    <div className="group-block w-33 ml-10" style={styles.icon}>
                        <label>{t('houseNum')}</label>
                        <InputText className='w-100' placeholder={profile?.homeStreetNo} onChange={e => setProfile(prev => ({
                            ...prev,
                            homeStreetNo: e.target.value
                        }))} />
                    </div>
                </div>
                <div class="p-flex-group mt-20">
                    <div className="group-block w-33">
                        <label>{t('index')}</label>
                        <InputText placeholder={profile?.zip} onChange={e => setProfile(prev => ({
                            ...prev,
                            zip: e.target.value
                        }))} />
                    </div>
                    <div className="group-block w-65 ml-10">
                        <label>{t('area')}</label>
                        <InputText className='w-100' placeholder={profile?.city} onChange={e => setProfile(prev => ({
                            ...prev,
                            city: e.target.value
                        }))} />
                    </div>
                </div>
                <div class="p-flex-group mt-20">
                    <div className="group-block w-49">
                        <label>{t('country')}</label>
                        <Dropdown options={countries.map(c => c.name)} placeholder={profile.country} onChange={e => setProfile(prev => ({
                            ...prev,
                            country: e.target.value
                        }))} />
                    </div>
                    <div className="group-block w-49 ml-10">
                        <label>{t('region')}</label>
                        <Dropdown placeholder={profile.area} options={regions.map(r => r.name)} onChange={e => setProfile(prev => ({
                            ...prev,
                            area: e.target.value
                        }))} />
                    </div>
                </div>
                <div class="p-flex-group mt-20">
                    <div className="group-block w-49">
                        <label>eMail</label>
                        <InputText placeholder='info@dielaufgesellschaft.de' className='w-100' />
                    </div>
                    <div className="group-block w-49 ml-10">
                        <label>Url</label>
                        <InputText placeholder={profile.url} className='w-100' onChange={e => setProfile(prev => ({
                            ...prev,
                            url: e.target.value
                        }))} />
                    </div>
                </div>
                <div class="p-flex-group mt-20">
                    <div className="group-block w-100">
                        <Editor style={{ height: '320px' }} placeholder={profile.contents} onTextChange={e => setProfile(prev => ({
                            ...prev,
                            contents: e.target.value
                        }))} />
                    </div>
                </div>
            </div>
            {footer}
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OrganizerData, comparisonFn);
