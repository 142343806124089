import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useHistory } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Image } from 'primereact/image';
import winterStyleImg from '../../../assets/images/bib/winterstyle_20199.jpg';
import defaultStyleImg from '../../../assets/images/bib/desertstyle_20199.jpg';
import redStyleImg from '../../../assets/images/bib/redstyle_20199.jpg';
import { useLocation } from 'react-router-dom';

const SettingsPayment = () => {

    const [editDialogVisible, setEditDialogVisible]=useState(false);
    const[imageUpload, setImageUpload]=useState('')
    const imageUploadInputRef = useRef(null);
    const location = useLocation();
    const [options, setOptions] = useState([
        {
            id: 0,
            category: "Gutschein",
            name: "Default",
        },
        {
            id: 1,
            category: "Rechnung",
            name: "Default",
        },
        {
            id: 2,
            category: "Startnummer",
            name: "Winter Style",
        },
        {
            id: 3,
            category: "Urkunde",
            name: "Default",
        }
    ]);
    const history = useHistory()
    const styles = {
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {

        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        }
    }


    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate');
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                saveImage(dataUrl, selectedFile.name.trim());
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        setImageUpload(dataUrl)
    }
    const openEditDialog=()=>{
        setEditDialogVisible(true);
        window.windowCheck();
    }

    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept=".png,.jpg,.jpeg"
        />
    );
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('questionPageText')}</p>
        </div>
    );

    const editTemplate =(
        <Button icon="pi pi-pencil" onClick={()=>openEditDialog()} tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}/>
    )
     
    const editDialogHeader=(
        <div className='table-header-container'>
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('templateNoticeContent')}</p>
            <Button icon="pi pi-plus" className="w-40 p-button-outlined" label={t('upload_own_template')} onClick={() => imageUploadInputRef.current.click()} />
        </div>
    )

    const editDialog=(
        <Dialog header={t('templates')} visible={editDialogVisible} onHide={() => setEditDialogVisible(false)} style={{ maxWidth: '850px' }} position="top" modal>
            {editDialogHeader}
            <hr className='mt-50' />
            <div class="p-flex-group template-flex">
                <div className="group-block w-50 mt-30">
                    <label class="info-label">Winter Style</label>
                    <Image src={winterStyleImg} width='auto' height='150px' className='mt-10'></Image>
                    <Checkbox checked={false} className='mt-10'></Checkbox>
                </div>
                <div className="group-block w-50 mt-30">
                    <label class="info-label">Default</label>
                    <Image src={defaultStyleImg} width='auto' height='150px' className='mt-10'></Image>
                    <Checkbox checked={false} className='mt-10'></Checkbox>
                </div>
                <div className="group-block w-50 mt-30">
                    <label class=" info-label">Red Style</label>
                    <Image src={redStyleImg} width='auto' height='150px' className='mt-10'></Image>
                    <Checkbox checked={false} className='mt-10'></Checkbox>
                </div>
            </div>
            <div class="p-flex-group mt-30">
                <Button className='uppercase' label='ok'  />
            </div>
            {hiddenInputImageUpload}
        </Dialog>
    )

    return (
        <div className='card'>
            <DataTable value={options} style={{ padding: "0px" }} responsiveLayout="scroll" header={header}  rows={10} emptyMessage={t('emptyMessage')}>
                <Column field="category" header={t('category')} sortable></Column>
                <Column field="name" header="Name" sortable></Column>
                <Column field='button' body={editTemplate} ></Column>
            </DataTable>
            {editDialog}
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SettingsPayment, comparisonFn);
