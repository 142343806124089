import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const Website = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const urlSufixes = [
        { name: ".spooorts.club" }, 
        { name: ".spooorts.de" }, 
        { name: ".spooorts.com" }
    ];
    let sufix = urlSufixes[0].name;

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);

    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])

    const save = () => {
        setEventDialog(false);

        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)

        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };

    const reset = () => {
        const { title, start, end } = clickedEvent;
        setChangedEvent({ title, start, end, allDay: null });
    };
    const redirectByButton=()=>{
       window.location.href = 'https://config.spooorts.de/';
    }

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
        </>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>: {t('WEBSITE_NOTE_MSG')}</p>
                <Button icon="pi pi-plus" onClick={() => redirectByButton()} className="p-button-outlined mr-2 mb-2" tooltip={t("WEBSITE_OBSERVE")} tooltipOptions={{ position: 'bottom' }} />
                <Button icon="pi pi-pencil" onClick={() => redirectByButton()} className="p-button-outlined mr-2 mb-2" tooltip={t("WEBSITE_DESIGN")}
                        tooltipOptions={{ position: 'top' }} />
                <Button icon="pi pi-sliders-h" onClick={() => redirectByButton()} className="p-button-outlined mr-2 mb-2" tooltip={t("WEBSITE_INTEGRATE")}
                        tooltipOptions={{ position: 'top' }}  />
                <Button icon="pi pi-copy" onClick={() => redirectByButton()} className="p-button-outlined mr-2 mb-2" tooltip={t("WEBSITE_URL_COPY")}
                        tooltipOptions={{ position: 'top' }}  />
        </div>
    );


    return (
        <div>
            {header}
            <div class="container-splitter responsive">
                <div class="left-block">
                    <div class='flex-column no-mb inner-block card-custom'>
                        <div class="container-resp">
                            <div class="block block-4">
                                <InputText className='w-100' disabled={true} placeholder={'https://my.spooorts.de#'}></InputText>
                            </div>
                            <div class="block block-5">
                                <InputText className='w-100'></InputText>
                            </div>
                            <div class="block block-3">
                                <Button className='w-100 btn-outline colored'>{t('save')}</Button>
                            </div>
                        </div>
                    </div>
                    <div class="block-sub flex-column inner-block no-mb card-custom">
                        <div>
                            <h6 className='colored'>{t('sponsorsInsteadOfAdvertising')}</h6>
                        </div>
                        <div>
                            <p className='sized'>{t('WEBSITE_PREMIUM_ADS_MSG')}</p>
                        </div>
                        <div class="container-resp">
                            <div class="block block-3">
                                <Button className='w-100 uppercase'>{t('moreInfo')}</Button>
                            </div>
                            <div class="block block-3">
                                <Button className='w-100 btn-outline colored'>{t('BTN_UPGRADE')}</Button>
                            </div>
                        </div>
                    </div>
                    <div class="block-sub flex-column inner-block no-mb card-custom">
                        <div>
                            <h6 className='colored'>{t('WEBSITE_OWN_URL')}</h6>
                        </div>
                        <div>
                            <p className='sized'>{t('WEBSITE_PREMIUM_URL_MSG')}</p>
                        </div>
                        <div class="container-resp">
                            <div class="block block-2">
                                <InputText className='w-100' disabled={true} placeholder='https://'></InputText>
                            </div>
                            <div class="block block-4">
                                <InputText className='w-100'></InputText>
                            </div>
                            <div class="block block-3">
                                <Dropdown className='w-100 border-custom' value={sufix} optionLabel='name' options={urlSufixes} placeholder={sufix} />
                            </div>
                            <div class="block block-3">
                                <Button className='w-100 btn-outline colored'>{t('BTN_ORDER')}</Button>
                            </div>
                        </div>
                    </div>
                    <div class="block-sub flex-column inner-block no-mb card-custom">
                        <div>
                            <h6 className='colored'>{t('sponsorsInsteadOfAdvertising')}</h6>
                        </div>
                        <div>
                            <p className='sized'>{t('websiteMessage')}</p>
                        </div>
                        <div class="container-resp">
                            <div class="block block-3">
                                <Button className='w-100 uppercase'>{t('moreInfo')}</Button>
                            </div>
                            <div class="block block-3">
                                <Button className='w-100 btn-outline colored'>{t('BTN_UPGRADE')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-block card-custom website-block">
                    <iframe src="https://m.spooorts.de" style={{ width: "100%", height: "596px" }} title='spooorts.de'></iframe>
                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Website, comparisonFn);
