// this script adds a header to each row in a table (for admin.spooorts.de)
export function windowCheck(){
    var tbody = document.querySelector('tbody');
    let empty=null
    if(tbody){
        var rows = Array.from(tbody.querySelectorAll('tr'));
        var rowsOdd=Array.from(tbody.getElementsByClassName('p-row-odd'));
        empty= tbody.querySelector('tr.p-datatable-emptymessage');
    }
    if(document.querySelector('thead')) var thead = document.querySelector('thead').cloneNode(true);

    if(!empty || empty==null){
        if(tbody){
            tbody.style.display='flex';
                if(window.innerWidth<375){
                    var numericWidth
                    if(rows){
                        rows.forEach(function(row, index){
                            if (index % 2 !== 1) {
                                row.classList.add('even');
                                var cloneThead =thead.cloneNode(true);
                                tbody.insertBefore(cloneThead, row);
                                cloneThead.style.display='table-header-group';
                            }
                            numericWidth = parseFloat(window.getComputedStyle(row).width)
                        })
                        var widthP=(numericWidth/window.innerWidth)*100
                        if(widthP>=30 ){
                            rowsOdd.forEach(function(row){
                                var cloneThead =thead.cloneNode(true);
                                cloneThead.classList.add('odd');
                                cloneThead.style.display='table-header-group';
                                tbody.insertBefore(cloneThead, row);
                            })
                        }
                        if(widthP<30){
                            document.querySelectorAll('thead.odd').forEach(thead => {
                                thead.style.display = 'none';
                                thead.remove();
                            });
                        }
                    }
                }
                if(window.innerWidth>375 && window.innerWidth<500){
                    var numericWidth
                    if(rows){
                        rows.forEach(function(row, index){
                            if (index % 2 !== 1) {
                                row.classList.add('even');
                                var cloneThead =thead.cloneNode(true);
                                cloneThead.style.display='table-header-group';
                                tbody.insertBefore(cloneThead, row);
                            }
                        numericWidth = parseFloat(window.getComputedStyle(row).width)
                        })
                        var widthP=(numericWidth/window.innerWidth)*100
                        if(widthP>=30){
                            rowsOdd.forEach(function(row){
                                var cloneThead =thead.cloneNode(true);
                                cloneThead.classList.add('odd');
                                cloneThead.style.display='table-header-group';
                                tbody.insertBefore(cloneThead, row);
                            })
                        }
                        if(widthP<30){
                            document.querySelectorAll('thead.odd').forEach(thead => {
                            thead.style.display = 'none';
                            thead.remove();
                            });
                        }
                    }
                }
                if(window.innerWidth>500 && window.innerWidth<769){
                    document.querySelectorAll('thead.odd').forEach(thead => {
                        thead.style.display = 'none';
                        thead.remove();
                    });
                    thead.style.display='none';
                    if(rows){
                        rows.forEach(function(row, index){
                            if (index % 2 !== 1) {
                                row.classList.add('even');
                                var cloneThead =thead.cloneNode(true);
                                cloneThead.style.display='table-header-group';
                                tbody.insertBefore(cloneThead, row);
                            }
                        })
                    }
                }
        }
    }
    else {
            const thead =document.querySelector('thead')
            thead.style.display='none'
            tbody.style.display='none'
            const dialog = Array.from(document.getElementsByClassName('p-tabview-panel'))
            if(dialog.length>0){
                dialog.forEach(panel=>{
                    const thead = panel.querySelector('thead');
                    const tbody = panel.querySelector('tbody');
                    if(thead) thead.style.display='none';
                    if(tbody) tbody.style.display='none';
                })
            }
    }
}

export function resize(){
    if(window.innerWidth<768){
        setTimeout(()=>{
            var tbody = document.querySelector('tbody');
            tbody.querySelectorAll('thead').forEach(thead => {
                thead.style.display = 'none';
                thead.remove();
            });
            windowCheck();
        },200)
    }
    else{
        var thead = document.querySelector('thead');
        thead.style.display='table-header-group;'
    }
}