import { addContact, addContactCategory, fileUpload, storeContact } from "./StoreContact";
import { filterDashboard, storeDashboard } from "./StoreDashboard";
import { add, search, storeShop } from "./stores/shop.store";

export const HEADER_BUTTONS = {
    "/": [
        // { label: "Header", icon: "pi pi-calendar-plus", onClick: onLog},
        // { label: "Header", icon: "pi pi-shopping-cart", path: "/shopSales" },
        // { label: "Header", icon: "pi pi-user-plus", path: "/editContacts" },
        // { label: "Header", icon: "pi pi-plus", onClick: onLog },
        // { label: "Header", icon: "pi pi-euro", onClick: onLog },
        // { label: "Header", icon: "pi pi-shopping-cart", onClick: onLog },
        // { label: "Header", icon: "pi pi-ticket", onClick: onLog },
        // { label: "Header", icon: "pi pi-filter", onClick: onStoreDashboardFilter }
    ],
    "/shopDelivery": [
        // { label: "Header", icon: "pi pi-plus", onClick: onShopAdd }
    ],
    // "/editContactCategory": [
    //     { label: "Header", icon: "pi pi-plus", onClick: onContactCategoryAdd }
    // ],
    // "/editContacts": [
    //     { label: "Header", icon: "pi pi-plus", onClick: onStoreContactAdd },
    //     { label: "Header", icon: "pi pi-upload", onClick: onContactFileUpload }
    // ],
    "/contactOur": [
        { label: "Header", icon: "pi pi-plus", onClick: onStoreContactAdd },
        { label: "Header", icon: "pi pi-upload", onClick: onLog }
    ]
};

function onShopSearch(){
    storeShop.dispatch(search());
}

function onShopAdd(){
    storeShop.dispatch(add());
}


function onStoreDashboardFilter () {
    storeDashboard.dispatch(filterDashboard());
}

function onContactFileUpload(){
    storeContact.dispatch(fileUpload());
}

function onStoreContactAdd(){
    storeContact.dispatch(addContact());
}

function onContactCategoryAdd() {
    storeContact.dispatch(addContactCategory());
}

function onLog () {
}