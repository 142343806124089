import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';

import FormLayoutDemo from './components/default/FormLayoutDemo';
import InputDemo from './components/default/InputDemo';
import FloatLabelDemo from './components/default/FloatLabelDemo';
import ButtonDemo from './components/default/ButtonDemo';
import TableDemo from './components/default/TableDemo';
import ListDemo from './components/default/ListDemo';
import TreeDemo from './components/default/TreeDemo';
import PanelDemo from './components/default/PanelDemo';
import OverlayDemo from './components/default/OverlayDemo';
import MediaDemo from './components/default/MediaDemo';
import MenuDemo from './components/default/MenuDemo';
import MessagesDemo from './components/default/MessagesDemo';
import FileDemo from './components/default/FileDemo';
import ChartDemo from './components/default/ChartDemo';
import MiscDemo from './components/default/MiscDemo';
import Documentation from './components/default/Documentation';
import BlocksDemo from './components/default/BlocksDemo';
import IconsDemo from './utilities/IconsDemo';
import CrudDemo from './pages/CrudDemo';
import CalendarDemo from './pages/CalendarDemo';
import Invoice from './pages/Invoice';
import Help from './pages/Help';
import EmptyPage from './pages/EmptyPage';
import InvalidStateDemo from './components/default/InvalidStateDemo';
import TimelineDemo from './pages/TimelineDemo';

import We from './components/spooorts/We';
import Events from './components/spooorts/Events';
import Training from './components/spooorts/Training';
import Shop from './components/spooorts/Shop';
import Contact from './components/spooorts/Contact';

import PrimeReact from 'primereact/api';
import { Tooltip } from "primereact/tooltip";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import Settings from './components/spooorts/Settings';
import About from './components/spooorts/About';

import { useTranslation } from 'react-i18next';
import trainingDates from './pages/training/training-dates';
import trainingGroups from './pages/training/training-groups';
import trainingPersonal from './pages/training/training-personal';
import TrainingPlan from './pages/training/training-plan';
import TrainingPlanCreate from './pages/training/training-plan-create';
import shopSales from './pages/shop/shop-sales';
import shopProducts from './pages/shop/shop-products';
import shopDelivery from './pages/shop/shop-delivery';
import shopProductsNew from './pages/shop/shop-products-new';
import shopSalesNew from './pages/shop/shop-sales-new';
import shopDiscountsNew from './pages/shop/shop-discounts-new';
import shopDeliveryNew from './pages/shop/shop-delivery-new';
import Coupons from './pages/we/coupons/coupons';
import Website from './pages/we/website/website';
import OurLogos from './pages/we/about/our-logos';
import OurPhotos from './pages/we/about/our-photos';
import OurSponsors from './pages/we/about/our-sponsors';
import OrganizerData from './pages/we/about/organizer-data';
import dashboard from './pages/we/dashboard/dashboard';
import SettingsGeneral from './pages/we/settings/settings-general';
import SettingsTemplates from './pages/we/settings/settings-templates';
import SettingsQuestions from './pages/we/settings/settings-questions';
import SettingsSocial from './pages/we/settings/settings-social';
import SettingsPayment from './pages/we/settings/settings-payment';
import EarlyBird from './pages/events/early-bird';
import shopProductCat from './pages/shop/shop-product-cat';
import shopWarehouse from './pages/shop/shop-warehouse';
import shopWarehouseDelivery from './pages/shop/shop-warehouse-delivery';
import contactNews from './pages/contact/contact-news';
import contactLetters from './pages/contact/contact-letters';
import contactMessages from './pages/contact/contact-messages';
import contactOur from './pages/contact/contact-our';
import contactCategories from './pages/contact/contact-categories';
import './headerStore';
import shopDiscounts from './pages/shop/shop-discounts';
import shopProductDialog from './pages/shop/shop-product-dialog';
import showOrganizerEvents from './pages/events/show-organizer-events';
import editRegistrations from './pages/events/edit-registrations';
import showOrganizerMaps from './pages/events/show-organizer-maps';
import showOrganizerResults from './pages/events/show-organizer-results';
import editEvent from './pages/events/edit-event';
import impressum from './components/spooorts/impressum';
import agbs from './components/spooorts/agbs';
import rechtliches from './components/spooorts/rechtliches';
import legalContacts from './components/spooorts/legalContacts';
import { InputSwitch } from 'primereact/inputswitch';

const App = () => {

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('assets/layout/images/logo-dark.svg');
    const [headerButtons, setHeaderButtons] = useState([]);

    const copyTooltipRef = useRef();
    const location = useLocation();

    // dark mode
    const [darkMode, setDarkMode] = useState(false);

    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;

    const { t } = useTranslation();

    const handleSwitchChange = (e) => {
        setDarkMode(e.value);
       console.log('e.value', darkMode)
       localStorage.setItem('darkMode', darkMode)
       let body = document.querySelector('body');
       if (!darkMode) {
        body.classList.add('darkMode');
        console.log('ls',localStorage.getItem('darkMode'))
      } else if (body && darkMode && body.classList.contains('darkMode')) {
        body.classList.remove('darkMode');
      }
    };

    const menu = [
        {
            label: t('we'), customIcon: "true", iconPath: "assets/layout/images/menu/we.svg", class: "layout-menuitem-root-image",
            items: [
                { label: "Dashboard", to: "/" },
                {
                    label: t('about'),
                    items: [
                        {
                            label: t('OUR_LOGO'), to: "/ourLogo"
                        },
                        {
                            label: t('OUR_PHOTOS'), to: "/ourPhotos"
                        },
                        {
                            label: t('sponsors'), to: "/ourSponsors"
                        },
                        {
                            label: t('ORGANIZER_DATA'), to: "/showOrganizer"
                        }
                    ]
                },
                { label: t('coupons'), to: "/coupons" },
                { label: t('websiteAndApp'), to: "/website" },
                {
                    label: t('settings'),
                    items: [
                        {
                            label: t('general'), to: "/settingsGeneral"
                        },
                        {
                            label: 'Social Media', to: "/settingsSocial"
                        },
                        {
                            label: t('PAYMENT_SIMPLE'), to: "/settingsPayment"
                        },
                        {
                            label: t('templates'), to: "/settingsTemplates"
                        },
                        {
                            label: t('questions'), to: "/settingsQuestions"
                        }
                    ]
                },
            ]
        },
        { separator: true },
        {
            label: t('events'), customIcon: "true", iconPath: "assets/layout/images/menu/events.svg", class: "layout-menuitem-root-image",
            items: [
                { label: t('ourEvents'), to: "/showOrganizerEvents" },
                { label: t('registrations'), to: "/editRegistrations" },
                { label: t('ealyBirdRebate'), to: "/earlybird" },
                { label: t('eventMap'), to: "/showOrganizerMaps" },
                { label: t('results'), to: "/showOrganizerResults" },
            ]
        },
        { separator: true },
        {
            label: "Training", customIcon: "true", iconPath: "assets/layout/images/menu/training.svg", class: "layout-menuitem-root-image",
            items: [
                { label: t('trainingDates'), to: "/trainingDates" },
                { label: t('trainingGroups'), to: "/trainingGroups" },
                { label: t('trainingPersonal'), to: "/trainingPersonal" },
                { label: t('trainingPlan'), to: "/trainingPlan" }
            ]
        },
        { separator: true },
        {
            label: "Shop", customIcon: "true", iconPath: "assets/layout/images/menu/shop.svg", class: "layout-menuitem-root-image",
            items: [
                { label: t('ourProducts'), to: "/shopProducts" },
                { label: t('PRODUCT_CATEGORY'), to: "/shopProductCategory" },
                { label: t('sales'), to: "/shopSales" },
                {
                    label: t('WAREHOUSE_SIMPLE'),
                    items: [
                        {
                            label: t('WAREHOUSE_STATUS'), to: "/showWarehouse"
                        },
                        {
                            label: t('WAREHOUSE_DELIVERY'), to: "/warehouseDelivery"
                        }
                    ]
                },
                { label: t('discounts'), to: "/shopDiscouts" },
                { label: t('shippingTypes'), to: "/shopDelivery" }
            ]
        },
        { separator: true },
        {
            label: t('contacts'), customIcon: "true", iconPath: "assets/layout/images/menu/contact.svg", class: "layout-menuitem-root-image",
            items: [
                { label: t('ourContacts'), to: "/editContacts" },
                { label: t('categories'), to: "/editContactCategory" },
                { label: t('NEW_MESSAGES_SIMPLE'), to: "/contactMessages" },
                { label: t('NEWS_LETTER'), to: "/contactLetters" },
                { label: t('NEWS_SIMPLE'), to: "/contactNews" }
            ]
        },
        // legal links
        { separator: true },
        {
            items: [
                { label: t('IMPRINT_SIMPLE'), to: "/impressum" },
                { label: 'AGBs', to: "/agbs" },
                { label: t('legal'), to: "/rechtliches" },
                { label: t('legal_contact'), to: "/legalContacts" }
            ] 
        },
        {separator: true},
        {
            label:<div className='menu-flex-row'>
            <span className='mr-5'>{t('dark_mode')}</span>
            <InputSwitch checked={darkMode} onChange={handleSwitchChange} />
            </div>    
        }
    ];

    const routers = [
        { path: '/', component: dashboard, state: headerButtons, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },
        { path: '/about', component: About, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('about') }] } },
        { path: '/coupons', component: Coupons, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('coupons') }] } },
        { path: '/website', component: Website, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('websiteAndApp') }] } },
        { path: '/settings', component: Settings, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('settings') }] } },
        { path: '/ourLogo', component: OurLogos, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('our_logo') }] } },
        { path: '/ourPhotos', component: OurPhotos, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('photos') }] } },
        { path: '/ourSponsors', component: OurSponsors, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('sponsors') }] } },
        { path: '/showOrganizer', component: OrganizerData, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('about') }] } },
        { path: '/settingsGeneral', component: SettingsGeneral, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('general') }] } },
        { path: '/settingsTemplates', component: SettingsTemplates, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('templates') }] } },
        { path: '/settingsQuestions', component: SettingsQuestions, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('questions_about_registration') }] } },
        { path: '/settingsSocial', component: SettingsSocial, meta: { breadcrumb: [{ parent: 'Dashboard', label: "Social Media" }] } },
        { path: '/settingsPayment', component: SettingsPayment, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('PAYMENT_SIMPLE') }] } },
        { path: '/showOrganizerEvents', component: showOrganizerEvents, meta: { breadcrumb: [{ parent: 'Events', label: t('events') }] } },
        { path: '/editRegistrations', component: editRegistrations, meta: { breadcrumb: [{ parent: 'Events', label: t('event_registration') }] } },
        { path: '/earlybird', component: EarlyBird, meta: { breadcrumb: [{ parent: 'Events', label: t('discounts') }] } },
        { path: '/showOrganizerMaps', component: showOrganizerMaps, meta: { breadcrumb: [{ parent: 'Events', label:'GPS Tracking / Eventmap' }] } },
        { path: '/showOrganizerResults', component: showOrganizerResults, meta: { breadcrumb: [{ parent: 'Events', label:t('results') }] } },
        { path: '/editEvent', component: editEvent, meta: { breadcrumb: [{ parent: 'Events', label:t('edit_event') }] } },
        { path: '/trainingDates', component: trainingDates, meta: { breadcrumb: [{ parent: 'Training', label: t('training_dates') }] } },
        { path: '/trainingGroups', component: trainingGroups, meta: { breadcrumb: [{ parent: 'Training', label: t('trainingGroups') }] } },
        { path: '/trainingPersonal', component: trainingPersonal, meta: { breadcrumb: [{ parent: 'Training', label: t('trainingPersonal') }] } },
        { path: '/trainingPlan', component: TrainingPlan, meta: { breadcrumb: [{ parent: 'Training', label: t('trainingPlan') }] } },
        { path: '/trainingPlanCreate', component: TrainingPlanCreate, meta: { breadcrumb: [{ parent: 'Training', label: t('trainingPlan') }] } },
        { path: '/shopProducts', component: shopProducts, meta: { breadcrumb: [{ parent: 'Shop', label: t('shopProducts') }] } },
        { path: '/shopProductCreate', component: shopProductsNew, meta: { breadcrumb: [{ parent: 'Training', label: 'Shop' }] } },
        { path: '/shopSales', component: shopSales, meta: { breadcrumb: [{ parent: 'Shop', label: t('sales') }] } },
        { path: '/shopSaleCreate', component: shopSalesNew, meta: { breadcrumb: [{ parent: 'Shop', label: t('product_sales') }] } },
        { path: '/shopDelivery', component: shopDelivery, meta: { breadcrumb: [{ parent: 'Shop', label: t('shippingTypes') }] } },
        { path: '/shopDiscouts', component: shopDiscounts, meta: { breadcrumb: [{ parent: 'Shop', label: t('discounts') }] } },
        { path: '/shopDiscountCreate', component: shopDiscountsNew, meta: { breadcrumb: [{ parent: 'Shop', label: t('discounts') }] } },
        { path: '/showWarehouse', component: shopWarehouse, meta: { breadcrumb: [{ parent: 'Shop', label: t('WAREHOUSE_STATUS') }] } },
        { path: '/warehouseDelivery', component: shopWarehouseDelivery, meta: { breadcrumb: [{ parent: 'Shop', label: t('deliveries') }] } },
        { path: '/shopDeliveryCreate', component: shopDeliveryNew, meta: { breadcrumb: [{ parent: 'Shop', label: t('edit_shipping_method') }] } },
        { path: '/shopProductCategory', component: shopProductCat, meta: { breadcrumb: [{ parent: 'Shop', label: t('shop_categories') }] } },
        { path: '/productDialog', component:shopProductDialog, meta:{breadcrumb: [{ parent: 'Shop', label: t('products') }]}},
        { path: '/contactMessages', component: contactMessages, meta: { breadcrumb: [{ parent: t('contact'), label: t('NEW_MESSAGES_SIMPLE') }] } },
        { path: '/contactLetters', component: contactLetters, meta: { breadcrumb: [{ parent: t('contact'), label: t('NEWS_LETTER') }] } },
        { path: '/contactNews', component: contactNews, meta: { breadcrumb: [{ parent: t('contact'), label: t('news') }] } },
        { path: '/editContacts', component: contactOur, meta: { breadcrumb: [{ parent: t('contact'), label: t('contacts') }] } },
        { path: '/editContactCategory', component: contactCategories, meta: { breadcrumb: [{ parent: t('contact'), label: t('CONTACT_CATEGORY') }] } },
        // legal links
        { path: '/impressum', component: impressum, meta: { breadcrumb: [{label: t('IMPRINT_SIMPLE') }] } },
        { path: '/agbs', component: agbs, meta: { breadcrumb: [{label:'AGBs' }] } },
        { path: '/rechtliches', component: rechtliches, meta: { breadcrumb: [{label: t('legal') }] } },
        { path: '/legalContacts', component: legalContacts, meta: { breadcrumb: [{label: t('legal_contact') }] } },
        // 
        { path: '/formlayout', component: FormLayoutDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }] } },
        { path: '/input', component: InputDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Input' }] } },
        { path: '/floatlabel', component: FloatLabelDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }] } },
        { path: '/invalidstate', component: InvalidStateDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }] } },
        { path: '/button', component: ButtonDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Button' }] } },
        { path: '/table', component: TableDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Table' }] } },
        { path: '/list', component: ListDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'List' }] } },
        { path: '/tree', component: TreeDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }] } },
        { path: '/panel', component: PanelDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }] } },
        { path: '/overlay', component: OverlayDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }] } },
        { path: '/media', component: MediaDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Media' }] } },
        { path: '/menu', component: MenuDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }] } },
        { path: '/messages', component: MessagesDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }] } },
        { path: '/file', component: FileDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'File' }] } },
        { path: '/chart', render: () => <ChartDemo colorMode={colorScheme} location={location} />, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }] } },
        { path: '/misc', component: MiscDemo, meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }] } },
        { path: '/icons', component: IconsDemo, meta: { breadcrumb: [{ parent: 'Utilities', label: 'Icons' }] } },
        { path: '/crud', component: CrudDemo, meta: { breadcrumb: [{ parent: 'Pages', label: 'Crud' }] } },
        { path: '/blocks', component: BlocksDemo, meta: { breadcrumb: [{ parent: 'PrimeBlocks', label: 'Blocks' }] } },
        { path: '/calendar', component: CalendarDemo, meta: { breadcrumb: [{ parent: 'Pages', label: 'Calendar' }] } },
        { path: '/timeline', component: TimelineDemo, meta: { breadcrumb: [{ parent: 'Pages', label: 'Timeline' }] } },
        { path: '/invoice', render: () => <Invoice logoUrl={logoUrl} location={location} />, meta: { breadcrumb: [{ parent: 'Pages', label: 'Invoice' }] } },
        { path: '/help', component: Help, meta: { breadcrumb: [{ parent: 'Pages', label: 'Help' }] } },
        { path: '/empty', component: EmptyPage, meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] } },
        { path: '/documentation', component: Documentation, meta: { breadcrumb: [{ parent: 'Pages', label: 'Documentation' }] } },
        { path: '/we', component: We, meta: { breadcrumb: [{ parent: 'UI Kit', label: "WIR" }] } },
        { path: '/events', component: Events, meta: { breadcrumb: [{ parent: 'UI Kit', label: "Events" }] } },
        { path: '/training', component: Training, meta: { breadcrumb: [{ parent: 'UI Kit', label: "Training" }] } },
        { path: '/shop', component: Shop, meta: { breadcrumb: [{ parent: 'UI Kit', label: "Shop" }] } },
        { path: '/contact', component: Contact, meta: { breadcrumb: [{ parent: 'UI Kit', label: "Contact" }] } }
    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const weIcon = document.getElementsByClassName('custom-we-icon')[0];
    });

    useEffect(()=>{
        const savedDarkMode = localStorage.getItem('darkMode') === 'true';
        if(!savedDarkMode){
            setDarkMode(true)  
        }
        let body = document.querySelector('body');
        if (!savedDarkMode) {
            body.classList.add('darkMode');
        } else {
            body.classList.remove('darkMode');
        }
    })

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme)

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.svg`;
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        const horizontalLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.svg`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        if (horizontalLogoLink) {
            horizontalLogoLink.src = horizontalLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme)
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById("app-logo");
        // const mobileLogoLink = document.getElementById("logo-mobile");
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const footerLogoLink = document.getElementById("footer-logo");
        const horizontalLogoLink = document.getElementById('logo-horizontal');

        setLogoUrl(`assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.svg`);

        if (appLogoLink) {
            appLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.svg`;
        }

        if (horizontalLogoLink) {
            horizontalLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.svg`;
        }

        // if (mobileLogoLink) {
        //     mobileLogoLink.src = logoUrl;
        // }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }

        /**
         *  CUSTOMIZATION START
         */



        /**
       *  CUSTOMIZATION FINISH
       */
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        }
        else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };


    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false)
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isHorizontal = () => {
        return menuMode === "horizontal";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay': menuMode === "overlay",
            'layout-static': menuMode === "static",
            'layout-slim': menuMode === "slim",
            'layout-horizontal': menuMode === "horizontal",
            'layout-sidebar-dim': colorScheme === "dim",
            'layout-sidebar-dark': colorScheme === "dark",
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === "static",
            'p-input-filled': inputStyle === "filled",
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '');

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-content-wrapper">
                <AppTopbar routers={routers} topbarNotificationMenuActive={topbarNotificationMenuActive} topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick} onSearchClick={toggleSearch} onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick} onRightMenuClick={onRightMenuButtonClick} onRightMenuButtonClick={onRightMenuButtonClick}
                    menu={menu} menuMode={menuMode} headerButtons={headerButtons} menuActive={menuActive} staticMenuMobileActive={staticMenuMobileActive} onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppTopbar>

                <div className="layout-content">
                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <AppBreadcrumb routers={routers} />
                    </div>

                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={withProps(router.component, { state: router.state })} render={router.render} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} render={router.render} />
                        })
                    }
                </div>

                <AppFooter />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            {/* <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange} colorScheme={colorScheme}
                changeColorScheme={changeColorScheme} menuTheme={menuTheme} changeMenuTheme={changeMenuTheme}
                componentTheme={componentTheme} changeComponentTheme={changeComponentTheme} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}></AppConfig> */}

            <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} />
        </div>
    );
}
function withProps(Component, props) {
    return function (matchProps) {
        return <Component {...props} {...matchProps} />
    }
}

export default App;
