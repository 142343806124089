import { createStore } from 'redux';

export const search = () => {
    return {
        type: "SEARCH"
    };
};
export const add = () => {
    return {
        type: "ADD"
    };
};
const counter = (state = 0, action) => {
    switch (action.type) {
        case "SEARCH": return 0;
        case "ADD": return 1;
    }
};

export let storeShop = createStore(counter);