import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import TrainingService from '../../service/TrainingService';
import { t } from 'i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useHistory } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import ShopService from '../../service/shop.service';

const ShopProductsNew = () => {
    const [deliveryModes, setDeliveryModes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();
    const styles = {
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {

        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        }
    }

    const shopService = new ShopService();

    useEffect(() => {

    }, [])

    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate');
    }

    const addNewProduct = () => {

    }

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined mr-2 mb-2" />
        </div>
    );

    const footer = (
        <div style={styles.footer}>
            <Button type="button"  label={t('save')} onClick={addNewProduct} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={addNewProduct} />
        </div>
    );

    return (
        <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={t('shopProducts')}>
                    <div class="p-flex-group">
                        <div className="group-block w-65">
                            <label>{t('product')}</label>
                            <InputText />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('language')}</label>
                            <Dropdown placeholder="1" />
                        </div>
                    </div>
                    <hr/>
                    <div class="p-flex-group">
                        <div className="group-block w-33">
                            <Button icon="pi pi-plus" className="w-65 p-button-outlined" label={t('addPhoto')} />
                        </div>
                        <div className="group-block w-33" style={styles.icon}>
                            <i class="pi pi-shopping-cart"></i>
                        </div>
                        <div class="p-flex-group w-33">
                            <div className="group-block">
                                <Checkbox checked={false}></Checkbox>
                            </div>
                            <div className="group-block w-85 ml-10" style={{ justifyContent: "center" }}>
                                <label>{t('showProductInPhoto')}</label>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="p-flex-group">
                        <div className="group-block w-65">
                            <label>{t('price')}</label>
                            <InputText />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('currency')}</label>
                            <Dropdown placeholder="1" />
                        </div>
                    </div>
                    <hr/>
                
                    <hr/>
                    <div class="p-flex-group">
                        <div className="group-block w-100">
                            <label>{t('description')}</label>
                            <InputTextarea className="h-100-fixed"></InputTextarea>
                        </div>
                    </div>
                    {footer}
                </TabPanel>

                <TabPanel header={t('shippingTypes')}>
                    <DataTable value={deliveryModes} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="type" header={t('shippingType')} sortable></Column>
                        <Column field="price" header={t('price')} sortable></Column>
                        <Column field="currency" header={t('currency')} sortable></Column>
                        <Column field="country" header={t('shippingCountry')} sortable></Column>
                    </DataTable>
                    {footer}
                </TabPanel>

                <TabPanel header={t('discounts')}>
                    <DataTable  responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="title" header={t('title')} sortable></Column>
                        <Column field="amount" header={t('amount')} sortable></Column>
                        <Column field="unit" header={t('unit')} sortable></Column>
                        <Column field="from" header={t('from')} sortable></Column>
                        <Column field="until" header={t('until')} sortable></Column>
                    </DataTable>
                </TabPanel>
            </TabView>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopProductsNew, comparisonFn);
