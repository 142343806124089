import { t } from "i18next";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import TrainingService from "../../service/TrainingService";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from 'react-router-dom';

/**
 * TODO:
 * 1. optimise useEffect by reducing the num of states 
 * 2. get rid of styles variable by using css/scss
 * 3. create sub components for each tab
 */

const TrainingPlanCreate = () => {
    let emptyGroup = {
        group: {
            id: null,
            name: '',
            trainer: '',
            level: null,
            notes: ''
        },
        specific:{
            id: null,
            week: null,
            day: null,
            sportType:  '',
            trainingType: '',
            duration: null,
            durUnit: '',
            notes: ''
        },
        endurance: {
            id: null,
            week: null,
            day: null,
            sportType:  '',
            trainingType: '',
            distance: null,
            distanceUnit: '',
            duration: null,
            durUnit: '',
            notes: ''
        },
        power: {
            id: null,
            week: null,
            day: null,
            sportType:  '',
            trainingType: '',
            duration: null,
            durUnit: '',
            notes: ''
        },
        units: {
            id:null,
            week:'',
            day:'',
            training:''
        }
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [trainingGroups, setTrainingGroups] = useState([]);
    const [trainingUnits, setTrainingUnits] = useState([]);
    const [durationUnits, setDurationUnits] = useState([]);
    const [planLevels, setPlanLevels] = useState([]);
    const [sportTypes, setSportTypes] = useState([]);
    const [trainingTypes, setTrainingTypes] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [days, setDays] = useState([]);
    const [trainingGroup, setTrainingGroup] = useState(emptyGroup)
    const [eventDialog, setEventDialog] = useState(false);
    const [level, setLevel] = useState(null);
    const [unitGroups, setUnitGroups] = useState([]);
    const [unitGroup, setUnitGroup] = useState(emptyGroup.units);
    const location = useLocation();

    const history = useHistory();
    const trainingService = new TrainingService();

    const styles = {
        table: {
            padding: "0px"
        },
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        button: {
            marginTop: "25px",
            backgroundColor: "#294f94",
            borderColor: "#294f94",
        },
        inputNote: {
            height: "200px"
        }
    }
    let durationUnitsConst = [t('hours'), t('minutes'), t('seconds')]

    useEffect(() => {
        trainingService.getPlanLevels().then(levels => setPlanLevels(levels.map((e) => e.nameKey = t(e.nameKey))));
        trainingService.getPlanWeeks().then(weeks => setWeeks(weeks));
        trainingService.getPlanDays().then(days => setDays(days));
        trainingService.getDurationUnits().then(units => {setDurationUnits(units.map((e) => e = t(e))); console.log('units', units)});
        trainingService.getSportTypes().then(types => setSportTypes(types.map((e) => e = t(e))));
        trainingService.getTrainingTypes().then(types => setTrainingTypes(types.map(e => e = t(e))));
    }, []);
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(()=>{
        // if(newProductDialogVisible ){
            if(activeIndex == 1) window.windowCheck();
        // }
    }, [activeIndex])

    const save = () => {
        let _trainingGroups = [...trainingGroups];

        trainingGroup.group.id = new Date().getTime();
        console.log('trainingGroup: ', trainingGroup);
        _trainingGroups.push(trainingGroup);

        setEventDialog(false);
        setTrainingGroups(_trainingGroups);
        setTrainingGroup(emptyGroup);

        let _unitGroups = [...unitGroups];
        _unitGroups.push(unitGroup);
        setEventDialog(false);
        setUnitGroups(_unitGroups);
        setUnitGroup(emptyGroup.units);
        window.windowCheck();
    };


    const backToList = () => {
        history.push('/trainingPlan');
        window.windowCheck();
    }

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-2 mb-2" />
        </div>
    );

    const footerTab1 = (
        <div style={styles.footer} className="buttons">
            <Button type="button" label={t('save')} style={styles.footer.button} className="m-tr-20" onClick={save} />
            <Button type="button" label={t('backToList')} style={styles.footer.button} className="p-button-outlined m-tr-20" onClick={backToList} />
        </div>
    );
    const footerTab2 = (
        <div style={styles.footer} className="more-buttons">
            <Button type="button" label={t('save')} style={styles.footer.button} className="m-tr-20" onClick={save} />
            <Button icon="pi pi-plus" type="button" label={t('specificUnit')} style={styles.footer.button} className="p-button-outlined m-tr-20" onClick={() => setActiveIndex(2)} />
            <Button icon="pi pi-plus" type="button" label={t('enduranceUnit')} style={styles.footer.button} className="p-button-outlined m-tr-20" onClick={() => setActiveIndex(3)} />
            <Button icon="pi pi-plus" type="button" label={t('powerUnit')} style={styles.footer.button} className="p-button-outlined m-tr-20" onClick={() => setActiveIndex(4)} />
            <Button type="button" label={t('backToList')} style={styles.footer.button} className="p-button-outlined m-tr-20" onClick={backToList} />
        </div>
    );
    const footerTab3 = (
        <div style={styles.footer} className="p-flex-group mt-30">
            <Button type="button" label={t('save')} style={styles.footer.button} onClick={save} />
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil"  className="mr-3" onClick={() => null}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}></Button>
        </div>
    )

    return (
        <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={t('trainingGroup')}>
                    <div className="p-flex-group">
                        <div className="group-block w-33">
                            <label>{t('planName')}</label>
                            <InputText id="name" value={trainingGroup.group.name} onChange={(e) => setTrainingGroup({ ...trainingGroup, group: { ...trainingGroup.group, ...{ name: e.target.value }}})} required autoFocus />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('trainer')}</label>
                            <InputText id="trainer" value={trainingGroup.group.trainer} onChange={(e) => setTrainingGroup({ ...trainingGroup, group: { ...trainingGroup.group, ...{ trainer: e.target.value } } })} />
                        </div>
                        <div className="group-block w-33">
                            <label htmlFor={t('notes')}>Level</label>
                            <Dropdown value={trainingGroup.group.level} className="border-custom" options={planLevels} onChange={(e) => setTrainingGroup({ ...trainingGroup, group: { ...trainingGroup.group, ...{ level: e.value }}})} placeholder={t('beginner')} />
                        </div>
                    </div>
                    <div className="p-flex-group">
                        <div className="group-block w-100 pt-30">
                            <label>{t('note')}</label>
                            <InputTextarea className="h-100-fixed" value={trainingGroup.group.notes} onChange={(e) => setTrainingGroup({ ...trainingGroup, group: { ...trainingGroup.group, ...{ notes: e.target.value }}})}></InputTextarea>
                        </div>
                    </div>
                    <hr />
                    {footerTab1}
                </TabPanel>
                <TabPanel header={t('units')}>
                    <DataTable value={unitGroups} responsiveLayout="scroll" header={header} paginator rows={10} style={{ border: "1px solid lightgrey" }} emptyMessage={t('emptyMessage')}> 
                        <Column field="week" header={t('week')} sortable></Column>
                        <Column field="day" header={t('day')} sortable></Column>
                        <Column field="training" header={t('training')} sortable></Column>
                        <Column field="edit" body={editTemplate}></Column>
                    </DataTable>
                    <hr />
                    {footerTab2}
                </TabPanel>
                <TabPanel header={t('specificTraining')}>
                    <div>
                        <div class="p-flex-group">
                            <div className="group-block w-15 mr-15">
                                <label>{t('week')}</label>
                                <Dropdown placeholder="1" value={level} options={weeks} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ week: e.value }}})} />
                            </div>
                            <div className="group-block w-15">
                                <label>{t('day')}</label>
                                <Dropdown placeholder="1" value={level} options={days} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ day: e.value }}})} />
                            </div>
                        </div>
                        <div className="container-block">
                            <div className="block-row">
                                <div className="group-block w-49">
                                    <label>{t('sportType')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} options={sportTypes} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ sportType: e.value }}})}/>
                                </div>
                                <div className="group-block w-49">
                                    <label>{t('trainingType')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} options={trainingTypes} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ trainingType: e.value }}})}/>
                                </div>
                            </div>
                            <div class="block-row mt-20">
                                <div className="group-block w-15">
                                    <label>{t('duration')}</label>
                                    <InputText onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ duration: e.target.value }}})} />
                                </div>
                                <div className="group-block">
                                    <Dropdown value={level} placeholder={t('selectPlease')} options={durationUnits} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ durUnit: e.value }}})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-flex-group">
                            <div className="group-block w-100 pt-30">
                                <label>{t('note')}</label>
                                <InputTextarea className="h-100-fixed" onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ notes: e.value }}})}></InputTextarea>
                            </div>
                        </div>
                        <hr />
                        {footerTab3}
                    </div>
                </TabPanel>
                <TabPanel header={t('enduranceTraining')}>
                    <div>
                        <div class="p-flex-group">
                            <div className="group-block w-15 mr-15">
                                <label>{t('week')}</label>
                                <Dropdown placeholder="1" value={level} options={weeks} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ week: e.value }}})} />
                            </div>
                            <div className="group-block w-15">
                                <label>{t('day')}</label>
                                <Dropdown placeholder="1" value={level} options={days} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ day: e.value }}})} />
                            </div>
                        </div>
                        <div className="container-block">
                            <div className="block-row">
                                <div className="group-block w-49">
                                    <label>{t('sportType')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} options={sportTypes} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ sportType: e.value }}})} />
                                </div>
                                <div className="group-block w-49">
                                    <label>{t('trainingType')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} options={trainingTypes} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ trainingType: e.value }}})} />
                                </div>
                            </div>
                            <div class="block-row mt-20">
                                <div className="group-block w-15">
                                    <label>{t('distance')}</label>
                                    <InputText onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ distance: e.target.value }}})} />
                                </div>
                                <div className="group-block mr-15-p">
                                    <Dropdown value={level} placeholder={t('selectPlease')} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ distanceUnit: e.value }}})} />
                                </div>
                                <div className="group-block w-15">
                                    <label>{t('duration')}</label>
                                    <InputText onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ duration: e.target.value }}})} />
                                </div>
                                <div className="group-block">
                                    <Dropdown value={level} placeholder={t('selectPlease')} options={durationUnits} onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ durUnit: e.value }}})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-flex-group">
                            <div className="group-block w-100 pt-30">
                                <label>{t('note')}</label>
                                <InputTextarea className="h-100-fixed" onChange={(e) => setTrainingGroup({ ...trainingGroup, endurance: { ...trainingGroup.endurance, ...{ notes: e.value }}})}></InputTextarea>
                            </div>
                        </div>
                        <hr />
                        {footerTab3}
                    </div>
                </TabPanel>
                <TabPanel header={t('powerTraining')}>
                    <div>
                        <div class="p-flex-group">
                            <div className="group-block w-15 mr-15">
                                <label>{t('week')}</label>
                                <Dropdown placeholder="1" value={level} options={weeks} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ week: e.value }}})} />
                            </div>
                            <div className="group-block w-15">
                                <label>{t('day')}</label>
                                <Dropdown placeholder="1" value={level} options={days} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ day: e.value }}})} />
                            </div>
                        </div>
                        <div className="container-block">
                            <div className="block-row">
                                <div className="group-block w-49">
                                    <label>{t('muscleGroup')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ week: e.value }}})} />
                                </div>
                                <div className="group-block w-49">
                                    <label>{t('trainingType')}</label>
                                    <Dropdown placeholder={t('selectPlease')} value={level} options={trainingTypes} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ week: e.value }}})} />
                                </div>
                            </div>
                            <div class="block-row mt-20">
                                <div className="group-block w-15">
                                    <label>{t('duration')}</label>
                                    <InputText onChange={(e) => setLevel(e.value)} />
                                </div>
                                <div className="group-block">
                                    <Dropdown value={level} placeholder={t('selectPlease')} options={durationUnits} onChange={(e) => setTrainingGroup({ ...trainingGroup, specific: { ...trainingGroup.specific, ...{ week: e.value }}})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-flex-group">
                            <div className="group-block w-100 pt-30">
                                <label>{t('note')}</label>
                                <InputTextarea className="h-100-fixed"></InputTextarea>
                            </div>
                        </div>
                        <hr />
                        {footerTab3}
                    </div>
                </TabPanel>
            </TabView>

            <Dialog visible={!!eventDialog} style={{ 'maxWidth': '750px' }} header={t('units')} footer={footerTab1} modal closable onHide={() => setEventDialog(false)}>
                <div className="p-fluid">
                    <div className="field">
                        <label htmlFor="week">{t('week')}</label>
                        <InputText id="week" value={unitGroup.week} onChange={(e) => setUnitGroup({ ...unitGroup, ...{ week: e.target.value } })} required autoFocus />
                    </div>
                    <hr />
                    <div className="field">
                        <label htmlFor="day">{t('day')}</label>
                        <InputText id="day" value={unitGroup.day} onChange={(e) => setUnitGroup({ ...unitGroup, ...{ day: e.target.value } })} required autoFocus />
                    </div>
                    <hr/>
                    <div className="field">
                        <label htmlFor="training">Training</label>
                        <InputText id="training" value={unitGroup.training} onChange={(e) => setUnitGroup({ ...unitGroup, ...{ training: e.target.value } })} required autoFocus />
                    </div>
                </div>
            </Dialog>          
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TrainingPlanCreate, comparisonFn);