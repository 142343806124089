
export default class RouterService {
    lang = "de";
    redirect = true;

    constructor(params = ""){
        const parts = params.split("&");
        parts.forEach(part => {
            if (part.includes("redirect")){
                this.redirect = part.split("=")[1] === "true" ? true: false;
            }
        })
        parts.forEach(part => {
            if (part.includes("lang")) {
                this.lang = part.split("=")[1];
                // i18n.changeLanguage(this.lang);
            }
        })
    }
}
