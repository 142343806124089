import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { useLocation } from 'react-router-dom';

const ContactMessages = () => {
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [eventDialog, setEventDialog] = useState(false);
    const [searchField, setSearchField]=useState('');
    const [news, setNews]=useState([]);
    const [dialogClosed, setDialogClosed]=useState(false)
    const location = useLocation();

    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            // textTransform: "uppercase"
        }
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    useEffect(() => {
        setEventDialog(false);
    }, []);
    const close=()=>{
        setEventDialog(false)
        setDialogClosed(true);
    }
    const search=()=>{}
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{": " + t('NEWS_MESSAGES_NOTE_MSG')}</p>
            {news.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                        tooltip={t('new_message')} tooltipOptions={{position:'top'}}></Button>
                </div>
            )}
            {news.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                        tooltip={t('new_message')} tooltipOptions={{position:'right'}}></Button>
                </div>
            )}
        </div>
    );

    const dialog = (
        <>
            <Dialog header={t('MESSAGE_SIMPLE')} visible={eventDialog} onHide={() => close()} style={{ maxWidth: '850px' }} position="center" modal>
                {/* <div class="card"> */}
                    <div class="flex-row start">
                        <div class="flex-column w-100 mobile-row start">
                            <Button icon="pi pi-envelope" className='mr-2'
                            tooltip={t('send_as_mail')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-comments"
                            tooltip={t('send_to_mobile')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div class="flex-row">
                        <div class="flex-column w-100">
                            <p>{t('LINK_SIMPLE')}</p>
                            <InputText className='w-100'></InputText>
                        </div>
                    </div>
                    <hr />
                    <div class="flex-row">
                        <div class="flex-column w-100">
                            <p>{t('MESSAGE_SIMPLE')}</p>
                            <InputTextarea className='w-100'></InputTextarea>
                        </div>
                    </div>
                    <div class="p-flex-group mt-30 g-10 buttons">
                        <Button label={t('save')} ></Button>
                        <Button label={t('close')} className='btn-outline colored' onClick={close}></Button>
                    </div>
                {/* </div> */}
            </Dialog>
        </>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={news} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="title" header={t('date')} sortable></Column>
                    <Column field="amount" header={t('title')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialog}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContactMessages, comparisonFn);
