import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState, useTransition } from "react";
import MapService from "../../../../service/map.service";
import { Button } from "primereact/button";
import appConfig from '../../../../assets/data/config.json';
import { useTranslation } from "react-i18next";
import { AutoComplete } from "primereact/autocomplete";
import { point } from "leaflet";

const mapService = new MapService();

const InputTwoPointsComponents = (props) => {
    const [searchFieldA, setSearchFieldA] = useState("");
    const [searchFieldB, setSearchFieldB] = useState("");
    const [pointA, setPointA] = useState(null);
    const [pointB, setPointB] = useState(null);
    const [suggestionsA, setSuggestionsA] = useState([]);
    const [suggestionsB, setSuggestionsB] = useState([]);
    const { t } = useTranslation();

    const searchForCities = (event, field) => {
        field === 0 ? setSearchFieldA(event) : setSearchFieldB(event);
        mapService.searchByCityName(event).then((results) => {
            if (!results) return;
            field === 0 ?
                setSuggestionsA(results.map(r => {
                    return {
                        name: r.display_name,
                        payload: r
                    }
                })) :
                setSuggestionsB(results.map(r => {
                    return {
                        name: r.display_name,
                        payload: r
                    }
                }));

                field === 0 ? 
                setPointA({payload: results[0]}):
                setPointB({payload: results[0]});
        });
    }

    const findLocationByCityName = () => {
        if (!pointA) {
            alert("Unknown Point A, pls. try one more time!");
            return;
        }
        if (!pointB) {
            alert("Unknown Point B, pls. try one more time!");
            return;
        }
        props.onCoordsFound({
            pointA: {
                name: pointA.payload.name,
                position: {
                    lat: pointA.payload.lat,
                    lng: pointA.payload.lon
                },
                payload: pointA.payload
            },
            pointB: {
                name: pointB.payload.name,
                position: {
                    lat: pointB.payload.lat,
                    lng: pointB.payload.lon
                },
                payload: pointB.payload
            }
        })
    }

    const onSelectSuggestion = (e, field) => {
        field === 0 ? setPointA(e.value) : setPointB(e.value);
    }

    return (
        <div>
            <h3 style={{ marginBottom: "10px" }}>Routing</h3>
            <div className="p-flex-group w-100">
                <div className="group-block w-40 mr-15">
                    <div>{t('STARTING_LOCATION')}</div>
                    <AutoComplete minLength={2} delay={1000} field="name" value={searchFieldA} suggestions={suggestionsA} completeMethod={e => searchForCities(e?.query, 0)} onSelect={e => onSelectSuggestion(e, 0)} onChange={e => setSearchFieldA(e.target.value)} className='w-100' />
                </div>
                <div className="group-block w-40 mr-15">
                    <div>{t('FINISH_LOCATION')}</div>
                    <AutoComplete minLength={2} delay={1000} field="name" value={searchFieldB} suggestions={suggestionsB} completeMethod={e => searchForCities(e?.query, 1)} onSelect={e => onSelectSuggestion(e, 1)} onChange={e => setSearchFieldB(e.target.value)} className='w-100' />
                </div>
                <div className="group-block w-20 jcfe">
                    <Button style={{ "backgroundColor": appConfig.color, "color": "white", borderColor: appConfig.color }} label="Los" onClick={findLocationByCityName}></Button>
                </div>
            </div>
        </div>
    );
}

export default InputTwoPointsComponents;
