import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import './service/i18n';
//import reportWebVitals from './reportWebVitals';
// Dynamically import repeat-header.js and assign to window
import('./repeat-header').then(({ windowCheck, resize }) => {
    window.windowCheck = windowCheck;
    window.resize = resize;
    console.log("windowCheck function is now available on window.");
  });


ReactDOM.render(
    <HashRouter>
        <AppWrapper />
    </HashRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
