import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { Apps } from "../../enums/apps.enum";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import AdminService from '../../service/admin.service';
import MapService from "../../service/map.service";
import { Chart } from "primereact/chart";
import AuthService from "../../service/auth.service";
import ImageService from "../../service/image.service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InputTextarea } from "primereact/inputtextarea";
import InputTwoPointsComponents from "./components/input-two-points/input-two-points.component";
import { areaIcon, caffeeIcon, getLeafletCoffeeIcon, getLeafletFinishIcon, getLeafletHotelIcon, getLeafletInfoIcon, getLeafletKmIcon, getLeafletMarkerIcon, getLeafletParkingIcon, getLeafletShowerIcon, goforestDefaultImgUpload, goforestLogo, goforestLogoWhite, hotelLogo, infoIcon, parkingIcon, showerIcon, getLeafletCircleMarker } from "./icons/map-icons";
import appConfig from '../../assets/data/config.json';
import RouterService from "../../service/router.service";
import { useTranslation } from "react-i18next";
import leftImage from '../../assets/icons/routing/left.png';
import rightImage from '../../assets/icons/routing/right.png';
import ringImage from '../../assets/icons/routing/ring.png';
import aImage from '../../assets/icons/routing/A.png';
import bImage from '../../assets/icons/routing/B.png';
import leftSharpImage from '../../assets/icons/routing/left-sharp.png';
import rightSharpImage from '../../assets/icons/routing/right-sharp.png';
import returnImage from '../../assets/icons/routing/return.png';
import straightImage from '../../assets/icons/routing/straight.png';
import leftSlightImage from '../../assets/icons/routing/slight-left.png';
import rightSlightImage from '../../assets/icons/routing/slight-right.png';
import EventService from "../../service/EventService";
import ImagectrlDialog from "./components/imagectrl-dialog.component/imagectrl-dialog.component";
import AppLanguageService from "../../service/app-language.service";

const turf = require('@turf/turf');

export const LeafletEditComponent = () => {
    const history = useHistory();
    const mapService = new MapService();
    const authService = new AuthService();
    const imageService = new ImageService();
    const eventService = new EventService();
    const routerService = new RouterService(history.location.pathname);
    const appLangS = new AppLanguageService();
    const { t, i18n } = useTranslation();
    const [autorouting, setAutorouting] = useState(true);
    const [hardAutoroutingDisable, setHardAutoroutingDisable] = useState(false);
    const [hardElevationDisable, setHardElevationDisable] = useState(false);
    const [kmMarkering, setKmMarkering] = useState(false);
    const [kmMarkers, setKmMarkers] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [circleMarkers, setCircleMarkers]=useState([])
    const [altitudes, setAltitudes] = useState([]);
    const [polyline, setPolyline] = useState([]);
    const [distance, setDistance] = useState([0.000]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestion, setSuggestion] = useState(null)
    const [map, setMap] = useState(null);
    const [app, setApp] = useState(Apps.Goforest); // App: 0 - default, 1 - goforest
    const [elevationData, setElevationData] = useState(null);
    const [searchField, setSearchField] = useState("");
    const [rounds, setRounts] = useState(1);
    const [menuToggle, setMenuToggle] = useState(false);
    const [imageUpload, setImageUpload] = useState(null);
    const imageUploadInputRef = useRef(null);
    const fileUploadInputRef = useRef(null);
    const [routeNames, setRouteNames] = useState([]);
    const [activeIcon, setActiveIcon] = useState("marker");
    const [lastAction, setLastAction] = useState([]);
    const [detailedRouteDisplay, setDetailedRouteDisplay] = useState(false);
    const [detailedRouteData, setDetailedRouteData] = useState([]);
    const [routeIsEdited, setRouteIsEdited] = useState(false);
    const [dialogNewPlanVisible, setDialogNewPlanVisible] = useState(false);
    const [dialogElevationChartVisible, setDialogElevationChartVisible] = useState(false);
    const [dialogRouteEditVisible, setDialogRouteEditVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState([]);
    const [imagectrlDialog, setImagectrlDialog] = useState(false);
    const [notSavedRoute, setNotSavedRoute]=useState([]);
    const appLang = "de";
    const [message, setMessage] = useState({
        type: '',
        message: ''
    });
    let [isPolyline, setIsPolilyne]=useState(false);
    let emptyRoute = {
        "routeName": "",
        "images": [],
        "lid": -1,
        "routeData": "",
        "routes": [],
        "contents": "",
        "categoryName": [],
        "startCity": "",
        "distanceTotal": 0,
        "distanceUnit": "km",
        "lang": routerService.lang,
        "markers": [],
        "id": -1,
        "cc": "",
        "saved": false
    };
    const [routeEditValues, setRouteEditValues] = useState(emptyRoute);
    const units = [
        "km",
        "mi"
    ];
    const style = {
        color: app == Apps.Default ? "#3b5997" : "#027100"
    }
    let [draggingMarkerPosition, setDraggingMarkerPosition] = useState(null);

    const iconMapping = {
        "circle": getLeafletCircleMarker(),
        "marker": getLeafletMarkerIcon(),
        "info": getLeafletInfoIcon(),
        "parking": getLeafletParkingIcon(),
        "shower": getLeafletShowerIcon(),
        "coffee": getLeafletCoffeeIcon(),
        "hotel": getLeafletHotelIcon(),
        "km": getLeafletKmIcon()
    };
    let savedRouteEditValues
    useEffect(() => {
        let fullAppPath
        if(navigator.platform.includes("Win") || navigator.platform.includes('Linux')){
            fullAppPath = window.location.href.split('resources/app.asar/build/index.html')[0]
            console.log('win', 'lin')
        }else{
            fullAppPath = window.location.href.split('GOForestDesktop.app/Contents/Resources')[0]
            console.log('mac')
        }
        let splitAppPath = fullAppPath.split('file://')[1]
        console.log('splitAppPath', splitAppPath)
        const fetchLanguage = async () => {
            const appLang = await appLangS.loadAppLanguage(splitAppPath);
            i18n.changeLanguage(appLang);
        }
        fetchLanguage();

        if (!navigator.onLine) {
            const message = t('NO_INTERNET_MESSAGE');
            alert(message);
            return;
        }
      
        savedRouteEditValues = localStorage.getItem('routeEditValues');
        if(savedRouteEditValues){
            const parsedRouteEditValues = JSON.parse(savedRouteEditValues);
            console.log(parsedRouteEditValues);
            if(parsedRouteEditValues.routeName.length > 0){
                setDialogNewPlanVisible(false);
            }
            setRouteEditValues(parsedRouteEditValues);
        }
        const savedDistance = localStorage.getItem('distance');
        if (savedRouteEditValues) {
            const parsedDistance = JSON.parse(savedDistance);
            setDistance(parsedDistance);
        }
        const savedImages = localStorage.getItem('images');
        if (savedImages) {
            const parsedImages = JSON.parse(savedImages);
            setImages(parsedImages);
        }
        const savedMarkers = localStorage.getItem('markers');
        if (savedMarkers) {
            const parsedMarkers = JSON.parse(savedMarkers);
            parsedMarkers.forEach(marker => {
                setMarkers(prevMarkers => [
                    ...prevMarkers,
                    {
                        icon: iconMapping[marker.name],
                        name: marker.name,
                        position: marker.position
                    }
                ]);
            });
        }
        const savedPolyline = localStorage.getItem('polyline');
        if (savedPolyline) {
            const parsedPolyline = JSON.parse(savedPolyline);
            setPolyline(parsedPolyline);
            if(parsedPolyline.length!=0){
               routerService.redirect = false
                const coords = {
                    lat:parsedPolyline[0].lat|| parsedPolyline[1].lat || parsedPolyline[0][0],
                    lng: parsedPolyline[0].lng || parsedPolyline[1].lng || parsedPolyline[0][1]
                }
                if(coords){
                    setMap(prevMap => prevMap.setView([coords.lat, coords.lng], 13));
                }
            }
        }

        getCategories();
        validateAutorouting({
            latitude: 52.517703,
            longitude: 13.353485
        });
        initGoforest();
        i18n.changeLanguage(routerService.lang);
    }, []);

    useEffect(() => {
       if(!savedRouteEditValues){
        console.log(markers);
        localStorage.setItem('routeEditValues', JSON.stringify(routeEditValues));
        localStorage.setItem('distance', JSON.stringify(distance));
        localStorage.setItem('images', JSON.stringify(images));
        localStorage.setItem('polyline', JSON.stringify(polyline));
        localStorage.setItem('markers', JSON.stringify(markers));
       }
    }, [routeEditValues, distance, images, polyline, markers])

    const getCategories = () => {
        if(routeEditValues.lang === routerService.lang){
            eventService.getCategories(routeEditValues.lang.toLocaleLowerCase()).then((results) => {
                setCategories(results);
                console.log(results)
                setRouteEditValues(prev =>(
                {
                    ...prev,
                    categoryName: results.find(r => r === "Laufstrecke" || r === "Running route")
                }));
            });
        }
        else if(routeEditValues.lang==='de' && routerService.lang==='en'){
            eventService.getCategories(routerService.lang.toLocaleLowerCase()).then((results) => {
                setCategories(results);
                setRouteEditValues(prev =>(
                {
                    ...prev,
                    categoryName: results.find(r => r === "Laufstrecke" || r === "Running route") //????
                }));
            });
        }
    }

    const validateAutorouting = async pos => {
        if (pos) {
            mapService.checkIfRouteExist({
                position: {
                    lat: pos?.coords?.latitude || pos?.latitude,
                    lng: pos?.coords?.longitude || pos?.longitude
                }
            }, {
                position: {
                    lat: pos?.coords?.latitude + 0.2 || pos?.latitude + 0.2,
                    lng: pos?.coords?.longitude + 0.2 || pos?.longitude + 0.2
                }
            }, "driving", authService.getToken()).then(res => {
                setHardAutoroutingDisable(!res);
                setAutorouting(res);
            }).catch(res => {
                setHardAutoroutingDisable(true);
                setAutorouting(false);
            })
        }
    }

    // UI map handling secion 
    function MapHandlerComponent() {
        const map = useMapEvents({
            click: (event) => {
                if(!isPolyline){
                    addMarker(event.latlng);
                    if (activeIcon.includes("marker") ) {
                        if (markers.length < 2 && !hardAutoroutingDisable) {
                            validateAutorouting({
                                position: {
                                    latitude: event.latlng.lat,
                                    longitude: event.latlng.lng
                                }
                            });
                        }
                        let lastRunMarkerIndex = -1;
                        markers.forEach((marker, index) => {
                            if (marker.name === "marker") {
                                lastRunMarkerIndex = index;
                            }
                        });
                        if ((autorouting && !hardAutoroutingDisable) && lastRunMarkerIndex > -1) {
                            mapService.getRouting(markers[lastRunMarkerIndex], { position: event.latlng }, routeEditValues.categoryName, authService.getToken()).then(res => {
                                    drawPolyline(null, res.coords);
                                    setDistance(distances => [...distances, ...res.distance]);
                                    setLastAction(prevAction => [...prevAction, {
                                    markerName: activeIcon,
                                    polyline: polyline.length,
                                    distance: distance.length
                                }]);
                            });
                        } else {
                            
                                drawPolyline(event.latlng);
                                calculateTotalDistance({ position: event.latlng });
                                setLastAction(prevAction => [...prevAction, {
                                markerName: activeIcon,
                                polyline: polyline.length,
                                distance: distance.length
                            }]);
                            
                        }
                        if (kmMarkering) onKmMarker(false);
                        setMessage({ type: 'notice', message: t('NOT_SAVED_MESSAGE') });
                    }  
                    else {
    
                        setLastAction(prevAction => [...prevAction, {
                            markerName: activeIcon,
                        }]);
                    }
    

                }
            },
            locationfound: (location) => {
            }
        });
        
        const handleMapLoad = () =>{
            setTimeout(() => {
                map.invalidateSize(true);
            }, 1000);
        }
        map.once('load', handleMapLoad)
        return null;
    }
    
    /**
     * 
     * @param {*} position, format is [lat, lng]
     * @param {*} icon 
     * @param {*} elevation, is there need to fetch elevation? Default is true
     */
    function addMarker(position, icon = null, elevation = true) {
        // TODO: extract fetchElevation from this method
        if (elevation) fetchElevation(position);
        setMarkers(prevMarkers => [
            ...prevMarkers,
            {
                icon: icon || iconMapping[activeIcon],
                name: activeIcon,
                position: position
            }
        ]);
        setRouteIsEdited(true);
    }
    function addKmMarker(position) {
        setKmMarkers(prevMarkers => [
            ...prevMarkers,
            {
                icon: iconMapping.km,
                name: activeIcon,
                index: prevMarkers.length + 1,
                position: position
            }
        ]);
        setRouteIsEdited(true);
    }
    async function fetchElevation(position) {
        if (!position) return;
        if (hardElevationDisable) return;

        let coords;
        if (typeof position[0] === "number") {
            coords = {
                lat: position[0],
                lng: position[1]
            }
        } else {
            coords = position;
        }
        let token = authService.getToken();
        mapService.getElevation(coords, token).then(res => {
            if (res && !res.message) {
                setHardElevationDisable(false);
                setAltitudes(prevAltitudes => [
                    ...prevAltitudes,
                    res || 0
                ]);
                return;
            }
            setHardElevationDisable(true)
        });

    }
    function drawPolyline(position, array = null) {
        if (array) {
            setPolyline(prevPoints => [
                ...prevPoints,
                ...array
            ]);
        } else {
            setPolyline(prevPoints => [
                ...prevPoints,
                position
            ]);
        }
    }
    function calculateTotalDistance(newPoint = null) {
        if (markers.length >= 1) {
            let prevMarker = markers[markers.length - 1];
            if (newPoint) {
                let dist = calculateDistanceBetween2Points(prevMarker, newPoint);
                setDistance(distances => [...distances, Number(dist).toFixed(3)]);
            }
        }
    }
    function calculateGpxRouteDistance(array) {
        for (let i = 1; i < array.length; i++) {
            let dist2points = calculateDistanceBetween2Points({
                position: {
                    lat: array[i - 1][0],
                    lng: array[i - 1][1]
                }
            }, {
                position: {
                    lat: array[i][0],
                    lng: array[i][1]
                }
            });
            setDistance(prevDistances => [...prevDistances, dist2points]);
        }
    }
    async function calcEveryKm(polyline) {
        const pointsAtEveryKm = [];

        let totalDistance = 0;
        for (let i = 1; i < polyline.length; i++) {
            totalDistance += Number(distance[i]);
            if (totalDistance >= 1) {
                let diff = totalDistance - 1;
                pointsAtEveryKm.push(polyline[i]);
                totalDistance = diff;
            }
        }
        return pointsAtEveryKm;
    }

    function calculateDistanceBetween2Points(point1, point2) {
        let from = turf.point([point1.lat || point1.position[0] || point1.position.lat, point1.lng || point1.position[1] || point1.position.lng]);
        let to = turf.point([point2.lat || point2.position[0] || point2.position.lat, point2.lng || point2.position[1] || point2.position.lng]);
        let options = { units: 'kilometers' }; // Assuming the distance unit is kilometers
        let dist = turf.distance(from, to, options);
        return dist;
    }
    function onKmMarker(toggle = true) {
        setKmMarkers([]);
        if (!kmMarkering || !toggle) {
            calcEveryKm(polyline).then(res => {
                for (let i = 0; i < res.length; i++) {
                    // setActiveIcon("km")
                    addKmMarker(res[i]);
                    setLastAction(prevAction => [...prevAction, {
                        markerName: "km",
                    }]);
                }
            });
        }
        if (toggle) setKmMarkering(prev => !prev);
    }
    function removeRoute() {
        mapService.deleteRoute(authService.getToken(), routeEditValues).then(() => {
            const routes = routeNames.filter(prev => prev !== routeEditValues.routeName);
            setRouteNames(routes);
            if (routes.length > 0) {
                initRoute(routes[routes.length - 1]);
            } else {
                resetAll();
                setDialogNewPlanVisible(true);
            }
        })
    }
    function resetAll() {
        setMarkers([]);
        setAltitudes([]);
        setPolyline([]);
        setImages([]);
        setDistance([0.000]);
        setLastAction([]);
        setKmMarkers([]);
        setRouteIsEdited(false);
        setDetailedRouteDisplay(false);
        setSearchField("");
        setSuggestions([]);
        setSuggestion(null);
        setMessage({ type: '', message: '' });
        setRouteEditValues(emptyRoute);
        getCategories();
    }
    function removeLastPoint() {
        setMarkers(prevMarkers => [
            ...markers.slice(0, prevMarkers.length - 1)
        ]);
        if (lastAction.length && lastAction[lastAction.length - 1].markerName.includes("marker")) {
            setAltitudes(prevAltitudes => [
                ...prevAltitudes.slice(0, lastAction[lastAction.length - 1].polyline)
            ]);
            setPolyline(prevPoints => [
                ...prevPoints.slice(0, lastAction[lastAction.length - 1].polyline)
            ]);
            setDistance(prevDistances => [
                ...prevDistances.slice(0, lastAction[lastAction.length - 1].distance)
            ]);
        }
        if (lastAction.length === 1) {
            setLastAction([]);
            setPolyline([]);
            setDistance([]);
            setAltitudes([]);
            setMarkers([])
            setRouteIsEdited(false);
        } else {
            setLastAction(prevAction => [
                ...lastAction.slice(0, prevAction.length - 1)
            ]);
        }
        if (lastAction.length === 0) {
            setRouteIsEdited(false);
        }
    }
    function onMapLoad(event) {
        // var layer = L.leafletGeotiff(url, options).addTo(map);
        setMap(event.target);
    }
    const markerRef = useRef(null)
    const clickMap =()=>{
        setIsPolilyne(false);
    }

    /**
     * 
     * @param {*} path 
     * @param {*} addStartFinishMarkers it's used when the user upload .gpx file. Then the start, finish markers should be drawn automatically.
     */
    function fillMap(path, addStartFinishMarkers = false) {
        let decodedPath = mapService.decodeRoute(path);
        // calculateGpxRouteDistance(decodedPath);
        if (addStartFinishMarkers) {
            const marker = app === Apps.Default ? getLeafletFinishIcon() : iconMapping.marker;
            addMarker(decodedPath[0], marker);
            addMarker(decodedPath[decodedPath.length - 1], marker);
        }
        drawPolyline(null, decodedPath);
        let bounds = L.latLngBounds(decodedPath);
        setMap(prevMap => prevMap.setView([bounds.getCenter().lat, bounds.getCenter().lng], 13));
        setMap(prevMap => prevMap.fitBounds(decodedPath));
        setLastAction(prevAction => [...prevAction, {
            markerName: activeIcon,
            polyline: polyline.length,
            distance: distance.length
        }]);
        fetchElevations(decodedPath);
    }

    async function fetchElevations(decodedPath) {
        let index = 0;
        if (hardElevationDisable) return;



        const interval = setInterval(() => {
            if (index < decodedPath.length) {
                let locations = "";
                const locationsPerRequestLimit = 100;
                for (let k = 0; k < locationsPerRequestLimit && index < decodedPath.length; k++) {
                    const latlng = decodedPath[index];
                    const coords = {
                        lat: typeof latlng.lat === "undefined" ? latlng[0] : latlng.lat,
                        lng: typeof latlng.lng === "undefined" ? latlng[1] : latlng.lng,
                    };
                    locations = `${locations}${coords.lat}_${coords.lng}`;
                    if (k < locationsPerRequestLimit - 1 && index < decodedPath.length - 1) {
                        locations = `${locations}|`
                    }
                    index++;
                }
                const token = authService.getToken();
                mapService.getElevations(locations, token).then(res => {
                    if (res.message) {
                        setHardElevationDisable(true);
                        clearInterval(interval);
                        return;
                    }
                    setAltitudes(prevAltitudes => [...prevAltitudes, ...res]);
                    setHardElevationDisable(false);
                });
                // index++;
            } else {
                clearInterval(interval); // Clear the interval when all coordinates have been processed
            }
        }, 300);
    }

    function openElevationDialog() {
        let lineData = {
            labels: altitudes.map((m, index) => index + 1),
            datasets: [
                {
                    label: '',
                    data: altitudes,
                    fill: true,
                    borderColor: app === Apps.Default ? "#3b5997" : "#027100",
                    tension: .4

                }
            ]
        };
        setElevationData(lineData);
        setDialogElevationChartVisible(true);
    }

    // Goforest
    const initGoforest = () => {
        if (app !== Apps.Goforest) return;
        if (!authService.isLoged()) {
            setImageUpload(goforestDefaultImgUpload);
            setImages(parseImagesForGalleria([goforestDefaultImgUpload]));
            setDialogNewPlanVisible(routerService.redirect);
            return;
        }
        mapService.getRoutes(authService.getToken()).then(routes => {
            if (!routes || routes.length === 0) {
                setDialogNewPlanVisible(routerService.redirect);
                setImageUpload(goforestDefaultImgUpload);
                return;
            }
            
            setRouteNames(routes.map(route => route.routeName));
            initRoute(routes[routes.length - 1].routeName);
        });
    }

    const initRoute = (value) => {
        resetAll();
        mapService.getRouteByIdOrName(authService.getToken(), value).then(route => {
            if (route && !route.message) {
                const decodedRouteData = JSON.parse(route.routeData);

                // Decode polyline path
                const encodedPath = decodedRouteData.routeInfos[0].path;
                if (!route.distanceUnit) {
                    route.distanceUnit = "km";
                }
                if (route.markers) {
                    route.markers
                        .filter(marker => marker !== null)
                        .forEach(newMarker => {
                            let markerIcon = iconMapping[newMarker.type];
                            addMarker([newMarker.lat, newMarker.lng], markerIcon, false);
                        });
                }
                let routeImage = route.images[route.images.length - 1];
                if(routeImage){
                    routeImage = routeImage.replace(/ /g, '%20')
                }
                setImageUpload(routeImage);
                setImages(parseImagesForGalleria(route.images));
                setRouteEditValues(() => ({
                    ...route,
                    saved: true
                }));
                setDistance(prev => [...prev, (route.distanceTotal / 1000).toFixed(3) + '']);
                fillMap(encodedPath);
            }
        })
    }
    const parseImagesForGalleria = (images) => {
        return images.map((image, index) => ({
            "itemImageSrc": image,
            "thumbnailImageSrc": image,
            "alt": "image " + index,
            "title": "Image " + index
        }));
    }

    // GPX upload section 
    const handleFileUpload = (event) => {
        if (!routeEditValues.routeName.length) {
            const message = t('ROUTENAME_EMPTY_ERROR_MESSAGE');
            setMessage({ type: 'error', message: message });
            return;
        }
        if(!navigator.onLine){
            const message = t('NO_INTERNET_MESSAGE');
            alert(message);
            return;
        }
        setMessage({ type: 'notice', message: t('NOT_SAVED_MESSAGE') });
        const file = event.target.files[0];
        if (!file) {
            // No file selected
            return;
        }
        console.log(routeEditValues.routeName)
        const fileReader = new FileReader();
        if(routeEditValues.routeName.length){
            fileReader.onload = (e) => {
                const gpxContent = e.target.result;
                const adminService = new AdminService();
                adminService.parseRoute(gpxContent, 1).then(res => {
                    if (res) {
                        const route = res[0].routeInfos[0].path;
                        fillMap(route, true);
                        let decodedPath = mapService.decodeRoute(route);
                        calculateGpxRouteDistance(decodedPath);
                        setDialogNewPlanVisible(false);
                    }
                });
            };
            fileReader.readAsText(file);
        }
    };
    const onInputClick = (event) => {
        return event.target.value = ''
    }

    // button handlers
    const handleSearchCity = () => {
        if (!routeEditValues.routeName.length) {
            const message = t('ROUTENAME_EMPTY_ERROR_MESSAGE');
            setMessage({ type: 'error', message: message });
        } else {
            let sug = suggestion || suggestions[0];
            if (sug) {
                map.setView([sug.payload.lat, sug.payload.lon], 13);
                setRouteNames(prev => [...prev, routeEditValues.routeName]);
                setDialogNewPlanVisible(false);
                const display_name = sug.payload.display_name.toLocaleUpperCase();
                const cc = sug.payload.address ? sug.payload.address.country_code : "";
                const autorouting = cc === "de" || display_name.includes("GERMANY") || display_name.includes("DEUTSCHLAND");
                setAutorouting(autorouting);
                setHardAutoroutingDisable(!autorouting)
                setMessage({ type: 'notice', message: t('NOT_SAVED_MESSAGE') });
            }
        }
    };

    const searchForCities = (event) => {
        setSearchField(event);
        mapService.searchByCityName(event).then((results) => {
            if (!results) return;
            setSuggestions(results.map(r => {
                return {
                    name: r.display_name,
                    payload: r
                }
            }));
        });
    }
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const onlyLetters = /^[A-Za-z]+$/;
        if (onlyLetters.test(inputValue) || inputValue === '') {
            setRouteEditValues((prevValues) => ({
                ...prevValues,
                routeName: inputValue
            }));
        }
    };
    const finishSearchCity = (data) => {
        map.setView([data.pointA.position.lat, data.pointA.position.lng], 13);
    };

    const handleDeleteAllImages = () => {
        const token = authService.getToken();
        const updatedRoute = getRoute();
        if(routeEditValues.saved){
            imageService.deleteAllRouteImages(token, updatedRoute).then((result) => {
                if (result) {
                    setImageUpload(goforestDefaultImgUpload);
                    updateImageGallery();
                }
            })
        }else{
            notSavedRoute.length=0
            setNotSavedRoute(notSavedRoute)
            notSavedRoute.push(goforestDefaultImgUpload)
            setNotSavedRoute(notSavedRoute);
            setImageUpload(goforestDefaultImgUpload);
            setImages(parseImagesForGalleria([goforestDefaultImgUpload]))
            updateImageGallery();
        }
    }
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                notSavedRoute.push(dataUrl);
                setNotSavedRoute(notSavedRoute);
                if (!authService.isLoged()) {
                    let path = app === Apps.Default ? "editRoute?" : "";
                    redirectToPageWithCallback("/login", `${path}redirect=false&lang=${routerService.lang}`, saveImage(dataUrl, selectedFile.name.trim()));
                } else {
                    saveImage(dataUrl, selectedFile.name.trim());
                }
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        imageService.saveImage(authService.getToken(), routeEditValues.routeName, { id: -1, imageLink: dataUrl, name: imageName }).then((result) => {
            if (result) {
                updateImageGallery();
            }
        });
    }
    const updateImageGallery = () => {
        if(routeEditValues.saved){
            const token = authService.getToken();
            mapService.getRouteByIdOrName(token, routeEditValues.routeName).then(details => {
                setImages(parseImagesForGalleria(details.images));
                setRouteEditValues(prev => ({
                    ...prev,
                    images: details.images
                }));
            });
        }else{
            setImages(parseImagesForGalleria(notSavedRoute));
            setRouteEditValues(prev=>({
                ...prev,
                images: notSavedRoute
            })); 
            console.log(notSavedRoute);
        }
    }
    // Save Route 
    const saveRouteWithLoginGuard = () => {
        if (!authService.isLoged()) {
            let path = app === Apps.Default ? "editRoute?" : "";
            redirectToPageWithCallback("/login", `${path}redirect=false&lang=${routerService.lang}`, saveActiveRoute);
        } else {
            saveActiveRoute();
        }
    }
    const saveActiveRoute = async () => {
        if (routeEditValues.routeName.length === 0) {
            setMessage({ type: 'error', message: t('ROUTENAME_EMPTY_ERROR_MESSAGE') });
            setDialogRouteEditVisible(true);
            return;
        }
        const updatedRoute = getRoute();
        setDialogRouteEditVisible(false);
        let result = await mapService.saveRoute(authService.getToken(), updatedRoute);
        if (result) {
            setMessage({ type: 'info', message: t('SAVE_SUCCESS') });
            setRouteEditValues((prev) => ({
                ...prev,
                saved: true
            }));
        } else setMessage({ type: 'error', message: t('ERROR_DEFAULT') });
    }
    const mapMarkersToObjects = () => {
        return markers.map(marker => ({
            type: marker.name,
            lat: marker.position.lat || marker.position[0],
            lng: marker.position.lng || marker.position[1],
        }));
    }
    const getRoute = () => {
        const updatedRoute = {
            ...routeEditValues,
            distanceTotal: Number(distance.reduce((prev, cur) => Number(prev) + Number(cur), 0)).toFixed(3) * 1000,
            markers: mapMarkersToObjects(),
            routeData: JSON.stringify({
                routeInfos: [{
                    laps: 1,
                    path: mapService.encodeRoute(polyline)
                }]
            }),
            routes: routeNames,
            startCity: routeEditValues.startCity || searchField?.payload?.name || searchField
        };
        delete updatedRoute.saved;
        return updatedRoute;
    }
    const redirectToPageWithCallback = (path, reverseUrl, callback) => {
        history.push({
            pathname: path,
            state: {
                reverseUrl,
                callback,
            },
        });
    }
    const getImage = (name, type) => {
        if (type === "roundabout") {
            return ringImage;
        }
        switch (name) {
            case "left": return leftImage;
            case "right": return rightImage;
            case "slight-right": return rightSlightImage;
            case "slight-left": return leftSlightImage;
            case "sharp-right": return rightSharpImage;
            case "sharp-left": return leftSharpImage;
            case "A": return aImage;
            case "B": return bImage;
            case "straight": return straightImage;
            default: break;
        }
    }
    const onSelectSuggestion = e => {
        setSuggestion(e.value);
        const address = e.value.payload.address || "";
        const startCity = address.city || "";
        const cc = address.country_code || "";
        setRouteEditValues(prev => ({
            ...prev,
            startCity: startCity,
            cc: cc.toLocaleUpperCase()
        }));
    }

    const onCoordsFound = (data) => {
        if (!routeEditValues.routeName.length) {
            const message = t('ROUTENAME_EMPTY_ERROR_MESSAGE');
            setMessage({ type: 'error', message: message });
            return;
        }
        setMessage({ type: 'notice', message: t('NOT_SAVED_MESSAGE') });
        if (data) {
            const token = authService.getToken();
            const address = data.pointA.payload.address || "";
            const startCity = address.city || "";
            const cc = address.country_code || "";
            setRouteEditValues(prev => ({
                ...prev,
                startCity: startCity,
                cc: cc.toLocaleUpperCase()
            }));
            mapService.getRouting(data.pointA, data.pointB, "walking", token).then((data) => {
                if (data && data.detailedRoute) {
                    const marker = app === Apps.Default ? getLeafletFinishIcon() : iconMapping.marker;
                    addMarker(data.coords[0], marker);
                    addMarker(data.coords[data.coords.length - 1], marker);
                    drawPolyline(null, data.coords);
                    setDistance(distances => [...distances, ...data.distance]);
                    setLastAction(prevAction => [...prevAction, {
                        markerName: activeIcon,
                        polyline: polyline.length,
                        distance: distance.length
                    }]);
                    if (kmMarkering) onKmMarker(false);
                    setDetailedRouteData(data.detailedRoute);
                    let bounds = L.latLngBounds(data.coords);
                    setMap(prevMap => prevMap.setView([bounds.getCenter().lat, bounds.getCenter().lng], 13));
                    setMap(prevMap => prevMap.fitBounds(data.coords));
                    if (!hardElevationDisable) fetchElevations(data.coords);
                    setDetailedRouteDisplay(true);
                    setDialogNewPlanVisible(false);
                    setMenuToggle(false);
                }
            });
        }
    }

    const openNewPlanWindow = () => {
        map.setView([52.517703, 13.353485], 13);
        resetAll();
        setDialogNewPlanVisible(true);
    }

    const handleMouseDown = (event) => {
        setIsPolilyne(true);
        setDraggingMarkerPosition(event.latlng) 
        // Disable map dragging
        map.dragging.disable();
        map.on('mousemove', handleMarkerDrag);
        map.on('mouseup', handleMouseUp);
    }
    const handleMouseUp = (event) => {
        // Enable map dragging
        map.dragging.enable();
        map.off('mousemove', handleMarkerDrag);
        map.off('mouseup', handleMouseUp);
        setDraggingMarkerPosition(null);
        setLastAction(prevAction => [...prevAction, {
            markerName: activeIcon,
            polyline: polyline.length,
            distance: distance.length
        }]);
    }
    let lastPolylineUpdateTime = new Date().getTime();
    const handleMarkerDrag = (event) => {
            setDraggingMarkerPosition(event.latlng)
            const currentMarkerPos = event.latlng;
            const token = authService.getToken();
            const pointA = {
                position: {
                    lat: polyline[0][0] || polyline[0].lat,
                    lng: polyline[0][1] || polyline[0].lng
                } 
            };
            const pointB = {
                position: {
                    lat: polyline[polyline.length - 1][0] || polyline[polyline.length - 1].lat,
                    lng: polyline[polyline.length - 1][1] || polyline[polyline.length - 1].lng
                }
            }
            const positionMarker = {
                lat: currentMarkerPos.lat || currentMarkerPos[0],
                lng: currentMarkerPos.lng || currentMarkerPos[1]
            }
            let markerPos = { position: positionMarker }
            if (new Date().getTime() - lastPolylineUpdateTime >= 500) {
                lastPolylineUpdateTime = new Date().getTime();
                mapService.getRouting(pointA, markerPos, 'walking', token).then((routeAMarker) => {
                    if (routeAMarker && routeAMarker.detailedRoute) {
                        mapService.getRouting(markerPos, pointB, 'walking', token).then((routeBMarker) => {
                            if (routeBMarker && routeBMarker.detailedRoute) {
                                const route = routeAMarker.coords.concat(routeBMarker.coords);
                                const totalDistance = routeAMarker.distance.concat(routeBMarker.distance);
                                setRouteIsEdited(true);
                                setDistance(totalDistance);
                                setPolyline(route)
                            }
                        });
                    }
                });
            }
    }
    const handleTouchStart = (event) => {}
    const handleTouchEnd = (event) => {}

    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            onClick={onInputClick}
            accept=".png,.jpg,.jpeg"
        />
    );

    const hiddenInputFileUpload = (
        <input
            type="file"
            ref={fileUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            onClick={onInputClick}
            accept=".gpx"
        />
    )

    const dialogDefaultNewPlan = (
        <Dialog className='dialog-container' header={t('NEW_ROUTE')} visible={dialogNewPlanVisible} onHide={() => setDialogNewPlanVisible(false)} style={{ width: '600px' }} modal>
            <div className="group-block w-100">
                <h3>{t('INSERT_ROUTE')}</h3>
                <div>{t('STARTING_LOCATION')}</div>
                <AutoComplete minLength={2} delay={1000} field="name" value={searchField} suggestions={suggestions} completeMethod={e => searchForCities(e?.query)} onSelect={onSelectSuggestion} onChange={e => setSearchField(e.target.value)} className='w-100' />

                <div className='mt-20'><strong>{t('notice')}:</strong></div>
                <p>
                    {t('NEW_DIALOG_SPOOORTS_MESSAGE')}
                </p>
            </div>
            <div className="flex g-10 mt-20">
                <Button style={style} label={t('START_RECORDING').toLocaleUpperCase()} onClick={handleSearchCity}></Button>
                <Button style={style} label={t('CLOSE')} className='p-button-outlined' onClick={() => setDialogNewPlanVisible(false)}></Button>
            </div>
            <hr />
            <div className='group-block w-100'>
                <h3>{t('IMPORT_ROUTE')}</h3>
                <div className='flex g-10 align-center'>
                    <div className='w-25'>{t('ROUND_COUNT')}</div>
                    <Dropdown className='w-75 border-custom' value={rounds} options={Array.from(Array(100).keys(), index => index + 1)} onChange={e => setRounts(e.value)} placeholder={rounds}></Dropdown>
                </div>
            </div>
            <br />
            {/* <div className="flex g-10"> */}
                {/* <Button style={style} label="kml Datei-Import" className='p-button-outlined btn-import'></Button> */}
                {/* {hiddenInputFileUpload} */}
                {/* <input className={navigator.userAgent.includes("Chrome") ? 'custom-file-input' : 'custom-file-input-safari'} type="file" ref={null} onChange={handleFileUpload} accept=".gpx" /> */}
                {/* <Button label={t('IMPORT_GPX')} className="custom-file-input" onClick={() => fileUploadInputRef.current.click()}></Button> */}
            {/* </div> */}
        </Dialog>
    );

    const dialogGoforestNewPlan = (
        <Dialog className='dialog-container' header={t('NEW_ROUTE')} visible={dialogNewPlanVisible} onHide={() => setDialogNewPlanVisible(false)} style={{ width: '700px' }} modal>
            <div className="p-flex-group w-100">
                <div className="group-block w-49">
                    <label>{t('category')}</label>
                    <Dropdown className="p-button-outlined w-95" options={categories} 
                    placeholder={routeEditValues.categoryName} 
                    onChange={e => setRouteEditValues((prevValues) => ({ ...prevValues, categoryName: e.value }))}></Dropdown>
                </div>
                <div className="group-block w-49">
                    <label>{t('ROUTE_NAME')}</label>
                    <InputText
                        className="p-button-outlined w-100"
                        placeholder={routeEditValues.routeName}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <hr />
            <div className="p-flex-group w-100">
                <div className="group-block w-100">
                    <h3>{t('INSERT_ROUTE')}</h3>

                    <p>
                        {t('NEW_DIALOG_MESSAGE')}
                    </p>
                </div>
            </div>
            <br />
            <div className="p-flex-group w-100">
                <div className="group-block w-80 mr-15">
                    <div>{t('STARTING_LOCATION')}</div>
                    <AutoComplete minLength={2} delay={1000} field="name" value={searchField} suggestions={suggestions} completeMethod={e => searchForCities(e?.query)} onSelect={onSelectSuggestion} onChange={e => setSearchField(e.target.value)} className='w-100' />
                </div>
                <div className="group-block w-20 jcfe">
                    <Button style={{ "backgroundColor": style.color, "color": "white", borderColor: style.color }} label={t('GO_SIMPLE')} onClick={handleSearchCity}></Button>
                </div>
            </div>
            <br />
            {autorouting && !hardAutoroutingDisable ?
                <>
                    <div className="line-block">
                        <InputTwoPointsComponents finishSearchCity={finishSearchCity} onCoordsFound={onCoordsFound} />
                    </div>
                </> : null}
            <div className="mobile-err-block">
                {message.message.length ?
                    (<>
                        <div className={message.type === 'error' ? "error-block" : "default-msg-block"}>
                            <span onClick={() => setMessage({ type: '', message: '' })}>x</span>
                            {message.type === 'error' ? <><strong>{t('ERROR_VALUE')}</strong> {' ' + message.message}</> : null}
                            {message.type === 'info' ? <><strong>Info</strong> {' ' + message.message}</> : null}
                            {message.type === 'notice' ? <><strong>{t('notice')}</strong> {' ' + message.message}</> : null}
                        </div>
                    </>) : null}
            </div>
            {/* <br /> */}
            {/* <hr /> */}
            {/* <div className="flex g-10"> */}
                {/* <Button  style={style} label="kml Datei-Import" className='p-button-outlined btn-import'></Button> */}
                {/* <div className="group-block"> */}
                    {/* <h3>{t('IMPORT_ROUTE')}</h3> */}
                    {/* {hiddenInputFileUpload} */}
                    {/* <Button label={t('IMPORT_GPX')} className="custom-file-input" onClick={() => fileUploadInputRef.current.click()}></Button> */}
                    {/* <input className={navigator.userAgent.includes("Chrome") ? 'custom-file-input' : 'custom-file-input-safari'} type="file" ref={null} onChange={handleFileUpload} accept=".gpx" /> */}
                {/* </div> */}
            {/* </div> */}
        </Dialog>
    );

    const elevationChart = (
        <Dialog className='dialog-container' header={t('ELEVATION_PROFILE')} visible={dialogElevationChartVisible} onHide={() => setDialogElevationChartVisible(false)} style={{ width: '600px' }} modal>
            <Chart type="line" data={elevationData} />
        </Dialog>
    )

    const defaultConfigurationMenu = (
        <div className={`menu ${menuToggle ? "dialog-block" : 'w-20'}`}>
            <h3 className='header'>
                {routeEditValues.routeName}
            </h3>
            <Dropdown placeholder='Spendenlauf' className='border-custom'></Dropdown>
            <Dropdown placeholder='Radstrecke' className='border-custom'></Dropdown>
            <Dropdown placeholder='2022' className='border-custom'></Dropdown>
            <div className="line-block">
                <div>{Number(distance.reduce((prev, cur) => Number(prev) + Number(cur), 0)).toFixed(3)}</div>
                <Dropdown placeholder={routeEditValues.distanceUnit} options={units} onChange={e => setRouteEditValues(prevValues => ({ ...prevValues, distanceUnit: e.value }))} className='w-100 border-custom'></Dropdown>
            </div>
            <Button style={style} className="p-button-outlined" disabled={!routeIsEdited} label={t('DELETE_LAST_POINT')} onClick={removeLastPoint.bind(this)}></Button>
            <hr />
            {!hardAutoroutingDisable ?
                <>
                    <div className='line-block'>
                        <Checkbox checked={autorouting && !hardAutoroutingDisable} onChange={() => setAutorouting(prevState => !prevState)} style={autorouting ? { backgroundColor: appConfig.color, borderColor: appConfig.color } : {}}></Checkbox>
                        <div>Autorouting</div>
                    </div>
                </> : null}
            <div className='line-block'>
                <Checkbox onChange={() => onKmMarker(true)} checked={kmMarkering} style={kmMarkering ? { backgroundColor: appConfig.color, borderColor: appConfig.color } : {}}></Checkbox>
                <div>KM-Marker</div>
            </div>
            {message.message.length ?
                (<>
                    <div className={message.type === 'error' ? "error-block" : "default-msg-block"}>
                        <span onClick={() => setMessage({ type: '', message: '' })}>x</span>
                        {message.type === 'error' ? <><strong>{t('ERROR_VALUE')}</strong> {' ' + message.message}</> : null}
                        {message.type === 'info' ? <><strong>Info</strong> {' ' + message.message}</> : null}
                        {message.type === 'notice' ? <><strong>{t('notice')}</strong> {' ' + message.message}</> : null}
                    </div>
                </>) : null}
            <Button style={style} label={t('save').toLocaleUpperCase()}></Button>
            <Button style={style} className="p-button-outlined min-width" label={t('DELETE')} onClick={removeRoute.bind(this)}></Button>
            <Button style={style} className="p-button-outlined min-width" label={t('ELEVATION_PROFILE')} onClick={openElevationDialog} disabled={hardElevationDisable}></Button>
            <div className="line-block fs-12 jcc cursor" >
                &copy; 2006 - {new Date().getFullYear()} (IT)2 Interactive GmbH - {t('IMPRINT_SIMPLE')}
            </div>
        </div>
    );

    const goforestConfigurationMenu = (
        <div className={`menu ${menuToggle ? "dialog-block" : 'w-20'}`}>
            <div style={{ display: "flex" }}>
                <img src={goforestLogo} alt="goforest-logo" className="image-logo" />
            </div>
            <div>
                <div className="image-container" style={{ backgroundImage: "url(" + imageUpload + ")" }} onClick={() => setImagectrlDialog(authService.isLoged())}>
                </div>
            </div>
            <h3 className='header'>
                {routeEditValues.routeName}
            </h3>
            {
                routeNames.length > 0 ?
                    <div className="flex-row g-10">
                        <Dropdown placeholder={routeEditValues.routeName} options={routeNames} className='border-custom w-80' value={routeEditValues} onChange={e => initRoute(e.value)}></Dropdown>
                        <Button style={{ color: appConfig.color, minWidth: "40px !important" }} className="p-button-outlined" icon="pi pi-pencil" onClick={() => setDialogRouteEditVisible(true)}></Button>
                    </div> : null
            }

            <div className="line-block">
                <div>{Number(distance.reduce((prev, cur) => Number(prev) + Number(cur), 0)).toFixed(3)}</div>
                <Dropdown value={routeEditValues.distanceUnit} placeholder={routeEditValues.distanceUnit} options={units} onChange={e => setRouteEditValues(prevValues => ({ ...prevValues, distanceUnit: e.value }))} className='w-100 border-custom'></Dropdown>
            </div>
            <div>
                <Button style={style} className="p-button-outlined w-100" label={t('DELETE_LAST_POINT')} disabled={!routeIsEdited} onClick={removeLastPoint.bind(this)}></Button>
            </div>
            <div className="markers-group">
                <Tooltip target=".tooltip-message" />
                <div className={`tooltip-message ${activeIcon === "marker" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('TRACKING_ICON')} onClick={() => {setActiveIcon("marker"); setIsPolilyne(false)}}><img src={areaIcon} alt="area" /></div>
                <div className={`tooltip-message ${activeIcon === "info" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('INFO_ICON')} onClick={() =>{ setActiveIcon("info");setIsPolilyne(false)}}><img src={infoIcon} alt="info" /></div>
                <div className={`tooltip-message ${activeIcon === "parking" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('PARK_ICON')} onClick={() => {setActiveIcon("parking");setIsPolilyne(false)}}><img src={parkingIcon} alt="parking" /></div>
                <div className={`tooltip-message ${activeIcon === "shower" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('SHOWER_ICON')} onClick={() => {setActiveIcon("shower");setIsPolilyne(false)}}><img src={showerIcon} alt="shower" /></div>
                <div className={`tooltip-message ${activeIcon === "coffee" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('COFFEE_ICON')} onClick={() => {setActiveIcon("coffee");setIsPolilyne(false)}}><img src={caffeeIcon} alt="coffee" /></div>
                <div className={`tooltip-message ${activeIcon === "hotel" ? "active" : null}`} data-pr-position="top" data-pr-tooltip={t('HOTEL_ICON')} onClick={() => {setActiveIcon("hotel");setIsPolilyne(false)}}><img src={hotelLogo} alt="hotel" /></div>
            </div>
            <div className='flex-row'>
                {!hardAutoroutingDisable ?
                    <>
                        <div className='line-block autorouting'>
                            <Checkbox checked={autorouting && !hardAutoroutingDisable} onChange={() => setAutorouting(prevState => !prevState)} className={autorouting ? "color-overwrite-green" : ""}></Checkbox>
                            <div>Autorouting</div>
                        </div>
                    </> : null}
                <div className="line-block km-markering">
                    <Checkbox onChange={() => onKmMarker(true)} checked={kmMarkering} className={kmMarkering ? "color-overwrite-green" : ""}></Checkbox>
                    <div>KM-Marker</div>
                </div>
            </div>
            {message.message.length ?
                (<>
                    <div className={message.type === 'error' ? "error-block" : "default-msg-block"}>
                        <span onClick={() => setMessage({ type: '', message: '' })}>x</span>
                        {message.type === 'error' ? <><strong>{t('ERROR_VALUE')}</strong> {' ' + message.message}</> : null}
                        {message.type === 'info' ? <><strong>Info</strong> {' ' + message.message}</> : null}
                        {message.type === 'notice' ? <><strong>{t('notice')}</strong> {' ' + message.message}</> : null}
                    </div>
                </>) : null}
            <div className="line-block btn-group">
                <Button style={style} className="p-button-outlined w-50 min-width" disabled={!routeIsEdited} label={t('save')} onClick={e => saveRouteWithLoginGuard()}></Button>
                <Button style={style} className="p-button-outlined w-50 min-width" label={t('ELEVATION_PROFILE')} onClick={openElevationDialog} disabled={hardElevationDisable}></Button>
            </div>
            <div className="line-block btn-group">
                <Button style={style} className="p-button-outlined w-50 min-width" disabled={!routeEditValues.saved} label={t('DELETE')} onClick={removeRoute.bind(this)}></Button>
                <Button style={style} className="p-button-outlined w-50 min-width" label={t('NEW_PLAN')} onClick={openNewPlanWindow}></Button>
            </div>
            { !navigator.platform.includes('Mac')?(
            <div className="line-block jcc logos">
                    <i className="pi pi-android tooltip-message" data-pr-position="top" data-pr-tooltip="Android Playstore" onClick={() => window.open("")}></i> 
                    <i className="pi pi-apple tooltip-message" data-pr-position="top" data-pr-tooltip="Apple Appstore" onClick={() => window.open("https://apps.apple.com/de/app/goforest-gps/id1223827266?l=en-GB","_top")}></i>
                    <i className="pi pi-instagram tooltip-message" data-pr-position="top" data-pr-tooltip="GOForest Instagram" onClick={() => window.open("https://www.instagram.com/gofo.rest/","_top")}></i>
            </div>
            ):''}
            <div className="line-block fs-12 jcc cursor imprint" onClick={() => window.open("https://spooorts.de/legals/goforest.jsf")}>
                &copy; 2006 - {new Date().getFullYear()} (IT)2 Interactive GmbH - {t('IMPRINT_SIMPLE')}
            </div>
            {hiddenInputImageUpload}
        </div>
    )

    const dialogRouteEdit = (
        <Dialog className='dialog-container' header={t('EDIT_ROUTE')} visible={dialogRouteEditVisible} onHide={() => setDialogRouteEditVisible(false)} style={{ width: '600px' }} modal>
            <div className="p-flex-group w-100">
                <div className="group-block w-49">
                    <label>{t('category')}</label>
                    <Dropdown className="p-button-outlined w-95" placeholder={routeEditValues.categoryName} options={categories} onChange={e => setRouteEditValues((prevValues) => ({ ...prevValues, categoryName: e.target.value }))}></Dropdown>
                </div>
                <div className="group-block w-49">
                    <label>{t('ROUTE_NAME')}</label>
                    <InputText
                        className="p-button-outlined w-100"
                        placeholder={routeEditValues.routeName}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <hr />
            <div className="p-flex-group w-100">
                <div className="group-block w-100">
                    <label>{t('description')}</label>
                    <InputTextarea className="p-button-outlined w-100 h-100-fixed" placeholder={routeEditValues.contents} onChange={e => setRouteEditValues((prevValues) => ({ ...prevValues, contents: e.target.value }))}></InputTextarea>
                </div>
            </div>
            <hr />
            <div className="line-block g-5">
                <Button style={style} className="p-button-outlined w-25 mr-15" label={t('save')} onClick={e => setDialogRouteEditVisible(false)}></Button>
                <Button style={style} className="p-button-outlined w-25" label={t('close')} onClick={e => setDialogRouteEditVisible(false)}></Button>
            </div>
        </Dialog>
    );

    const dialogConfigurationMenu = (
        <Dialog className='dialog-container' visible={menuToggle} onHide={() => setMenuToggle(false)} style={{ width: '600px' }} modal>
            {app === Apps.Default ? defaultConfigurationMenu : goforestConfigurationMenu}
        </Dialog>
    )

    return (
        <div className="main-wrapper">
            <div className="main-block">
                <div className='leaflet-edit-route-container'>
                    <ImagectrlDialog images={images} style={style} visible={imagectrlDialog} upload={() => imageUploadInputRef.current.click()} delete={() => handleDeleteAllImages()} close={() => setImagectrlDialog(false)} />
                    {app === Apps.Default ? defaultConfigurationMenu : goforestConfigurationMenu}
                    <div id="map" className="map-block" style={{ width: "-webkit-fill-available" }}>
                        <MapContainer center={[52.517703, 13.353485]} zoom={13} style={{ height: "100vh" }} scrollWheelZoom={false} whenReady={onMapLoad} >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                eventHandlers={{click:clickMap}}
                            />
                            {markers.map((marker, index) => {
                                return (
                                    <Marker key={index} icon={marker.icon} position={marker.position}>
                                        <Popup>
                                            {t('ROUND_COUNT')} {rounds}
                                        </Popup>
                                    </Marker>
                                )
                            })}
                            {kmMarkers.map((marker, index) => {
                                const style = "display: flex; justify-content:center; align-items: center; height: 25px; width: 25px; background-color: black; border-radius: 50%;"
                                const icon = new L.DivIcon({
                                    className: 'my-div-icon',
                                    html: `<div style='${style}'><span style='color: white'>${marker.index}</span></div>`
                                })
                                return (<Marker key={marker.index} icon={icon} position={marker.position}></Marker>)
                            })}
                            <Polyline pathOptions={{ color: app === Apps.Default ? "#3b5997" : "#027100", weight: 4 }} positions={polyline} 
                            eventHandlers={{
                                mousedown: handleMouseDown, // For mouse press
                                touchstart: handleTouchStart, // For touch press
                                touchend: handleTouchEnd
                            }} />
                            {draggingMarkerPosition ? (
                                <Marker draggable={true} ref={markerRef} eventHandlers={{ drag: handleMarkerDrag }} icon={iconMapping.circle} position={draggingMarkerPosition}></Marker>
                                ): null}
                            <MapHandlerComponent />
                        </MapContainer>
                    </div>
                    {!menuToggle ? <><Button style={style} icon="pi pi-bars" className="menu-toggle-button" onClick={() => setMenuToggle(true)}></Button> </> : null}
                    {dialogConfigurationMenu}
                    {elevationChart}
                    {app === Apps.Default ? dialogDefaultNewPlan : dialogGoforestNewPlan}
                    {dialogRouteEdit}
                    {/* {detailedRouteDisplay ? <DetailedRouteComponent detailedRoute={detailedRouteData} /> : null} */}
                    {detailedRouteDisplay ?
                        <>
                            <div className="detailed-route-component">
                                <div className="content-wrapper">
                                    <div className="close-btn">
                                        <span onClick={() => setDetailedRouteDisplay(false)}>
                                            x
                                        </span>
                                    </div>
                                    {detailedRouteData.map(route => {
                                        return (
                                            <div className="row-data">
                                                <div className="sign" style={{ backgroundImage: `url(${getImage(route.sign, route.type)})` }}></div>
                                                <div className="text">{route?.name}</div>
                                                <div className="distance">{route?.distance}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="component-background"></div>
                            </div>
                        </> : null}

                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(LeafletEditComponent, comparisonFn);
