import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { useLocation } from 'react-router-dom';

const Coupons = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        },
        buttonSucc: {
            backgroundColor: "#294f94",
            borderColor: "#294f94",
        },
        buttonDanger: {
            backgroundColor: "#fff",
            borderColor: 'rgba(0, 0, 0, 0.87)',
            color: 'rgba(0, 0, 0, 0.87)'
        },
    }
    let emptyGroup = {
        recipient:'',
        value:0,
        currency:'EUR',
        email:'',
        code:Math.floor(10000 + Math.random() * 90000),
        description:'Not redeemed'
    }
    let currency=[
        { label: 'EUR', value: 'EUR' },
        { label: 'USD', value: 'USD' },
    ]

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);
    let [recipientGroups, setRecipientGroups] = useState([]);
    const [recipientGroup, setRecipientGroup] = useState(emptyGroup);
    const [searchField, setSearchField] = useState("");
    const [coupons, setCoupons]=useState([]);
    const location = useLocation();


    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])
    useEffect(() => {
        const handleWindowCheck = () => {
            setTimeout(() => {
                window.windowCheck();
            }, 100);
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    const save = () => {
        emptyGroup.edit = (
            <Button type="button" icon="pi pi-pencil" onClick={() => setEventDialog(true)}></Button>
        );
        emptyGroup.remove = (
            <Button type="button" icon="pi pi-trash" onClick={() => null}></Button>
        );
        let _recipientGroups = [...recipientGroups];
        _recipientGroups.push(recipientGroup);
        setEventDialog(false);
        setRecipientGroups(_recipientGroups);
        setRecipientGroup(emptyGroup);
        setEventDialog(false);
        window.windowCheck();
    };
    const search=()=>{}

    const reset = () => {
        const { recipient, value, currency, email, code, description } = emptyGroup;
        setRecipientGroup({ recipient, value, currency, email, code, description: null});
        setEventDialog(false);
        window.windowCheck();
    };

    const footer = (
        // <>
        //     <Button type="button" label={t('createCoupon')} className="uppercase" onClick={save} />
        // </>
        <div className='buttons'>
            <Button style={styles.buttonSucc} label={t('save')} onClick={save} />
            <Button style={styles.buttonDanger} label={t('close')} onClick={reset} />
        </div>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('couponsNoticeContent')}</p>
            {coupons.length!==0 &&(
                <div class="table-header-inner-container">
                    <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined mr-1" />
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined" />
                </div>
            )}
            {coupons.length==0 &&(
                <div class="table-header-inner-container">
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined" 
                     tooltip={t('createCoupon')} tooltipOptions={{ position: 'top' }} />
            </div>
            )}
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil"  className="mr-3" onClick={() => null}
                 tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                 tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div>
            <div className='card' >
                <DataTable value={coupons} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="recipient" header={t('recipient')} sortable></Column>
                    <Column field="email" header='eMail' sortable></Column>
                    <Column field="value" header={t('value')} sortable></Column>
                    <Column field="code" header='Code' sortable></Column>
                    <Column field="description" header='Status' sortable></Column>
                    <Column field="edit" body={editTemplate}></Column>
                </DataTable>
            </div>
            <Dialog visible={!!eventDialog} style={{ 'maxWidth': '750px' }} header={t('edit_coupon')} footer={footer} modal closable onHide={() => setEventDialog(false)}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="recipient">{t('voucher_recipient')}</label>
                            <InputText id="recipient" value={recipientGroup.recipient} onChange={(e) => setRecipientGroup({ ...recipientGroup, ...{ recipient: e.target.value } })} required autoFocus />
                        </div>
                        <hr />
                        <div className='p-flex-group'>
                            <div className='group-block w-49'>
                                <label htmlFor='value'>{t('value')}</label>
                                <InputNumber  id='value' value={recipientGroup.value} onChange={(e) => setRecipientGroup({ ...recipientGroup, ...{value: e.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='currency'>{t('currency')}</label>
                                <Dropdown  id="currency" value={recipientGroup.currency} options={currency} 
                                onChange={(e) => setRecipientGroup({ ...recipientGroup, ...{ currency: e.target.value } })} required autoFocus
                                placeholder="EUR"/>
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                            <div className='group-block w-49'>
                                <label htmlFor='email'>eMail</label>
                                <InputText  id='email' value={recipientGroup.email} onChange={(e) => setRecipientGroup({ ...recipientGroup, ...{ email: e.target.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='code'>Code</label>
                                <InputNumber  id='code'  onChange={(e) => setRecipientGroup({ ...recipientGroup, ...{ code: e.value } })} disabled autoFocus/>
                            </div>
                        </div>
                        <hr/>
                    </div>
            </Dialog>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Coupons, comparisonFn);
