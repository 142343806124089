import L from 'leaflet';
import appConfig from '../../../assets/data/config.json';

export const goforestDefaultImgUpload = require('../../../assets/profile/goforestDefault.jpg');
export const runIcon = require('../../../assets/marker/laufen.png');
export const runIconGreen = require('../../../assets/marker/mapping/goforest-pack/laufen-logo.png');
export const hotelIconGreen = require('../../../assets/marker/mapping/goforest-pack/hotel.png');
export const finishIcon = require('../../../assets/marker/ziel.png');
export const finishIconGreen = require('../../../assets/marker/mapping/goforest-pack/ziel.png');
export const mapInfoIcon = require('../../../assets/marker/info.png');
export const mapInfoIconGreen = require('../../../assets/marker/mapping/goforest-pack/info.png');
export const mapParkingIcon = require('../../../assets/marker/parking.png');
export const mapParkingIconGreen = require('../../../assets/marker/mapping/goforest-pack/parking.png');
export const mapShowerIcon = require('../../../assets/marker/shower.png');
export const mapShowerIconGreen = require('../../../assets/marker/mapping/goforest-pack/shower.png');
export const mapCaffeeIcon = require('../../../assets/marker/coffee.png');
export const mapCaffeeIconGreen = require('../../../assets/marker/mapping/goforest-pack/coffee.png');
export const areaIcon = require('../../../assets/marker/mapping/area-logo.png');
export const areaIconGreen = require('../../../assets/marker/mapping/goforest-pack/area-logo.png');
export const infoIcon = require('../../../assets/marker/mapping/info-logo.png');
export const infoIconGreen = require('../../../assets/marker/mapping/goforest-pack/info-logo.png');
export const parkingIcon = require('../../../assets/marker/mapping/parking-logo.png');
export const parkingIconGreen = require('../../../assets/marker/mapping/goforest-pack/shower-logo.png');
export const showerIcon = require('../../../assets/marker/mapping/shower-logo.png');
export const showerIconGreen = require('../../../assets/marker/mapping/goforest-pack/shower-logo.png');
export const caffeeIcon = require('../../../assets/marker/mapping/caffee-logo.png');
export const caffeeIconGreen = require('../../../assets/marker/mapping/goforest-pack/caffee-logo.png');
export const goforestLogo = require('../../../assets/marker/goforest-logo.png');
export const goforestLogoWhite = require('../../../assets/marker/goforest-logo-white.png');
export const kmLogo = require('../../../assets/marker/km.png');
export const kmLogoGreen = require('../../../assets/marker/mapping/goforest-pack/km.png');
export const hotelLogo = require('../../../assets/marker/mapping/hotel-logo.png');
export const hotelLogoGreen = require('../../../assets/marker/mapping/goforest-pack/hotel-logo.png');
export const circleMarker = require('../../../assets/marker/circle-marker2.png');

const leafletRunIcon = L.icon({
    iconUrl: runIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletRunIconGreen = L.icon({
    iconUrl: runIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletMarkerIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletRunIconGreen;
    } else {
        return leafletRunIcon;
    }
}

const leafletInfoIcon = L.icon({
    iconUrl: mapInfoIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletInfoIconGreen = L.icon({
    iconUrl: mapInfoIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletCircleMarker=()=>{
    return leafletCircleMarker;
}
const leafletCircleMarker= L.icon({
    iconUrl: circleMarker,
    iconSize: [15, 15],
    iconAnchor: [15,15]
});
export const getLeafletInfoIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletInfoIconGreen;
    } else {
        return leafletInfoIcon;
    }
}

const leafletParkingIcon = L.icon({
    iconUrl: mapParkingIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletParkingIconGreen = L.icon({
    iconUrl: mapParkingIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletParkingIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletParkingIconGreen;
    } else {
        return leafletParkingIcon;
    }
}

const leafletShowerIcon = L.icon({
    iconUrl: mapShowerIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletShowerIconGreen = L.icon({
    iconUrl: mapShowerIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletShowerIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletShowerIconGreen;
    } else {
        return leafletShowerIcon;
    }
}

const leafletCaffeeIcon = L.icon({
    iconUrl: mapCaffeeIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletCaffeeIconGreen = L.icon({
    iconUrl: mapCaffeeIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletCoffeeIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletCaffeeIconGreen;
    } else {
        return leafletCaffeeIcon;
    }
}

const leafletKmIcon = L.icon({
    iconUrl: kmLogo,
    iconSize: [32, 32],
    iconAnchor: [16, 16]
});
const leafletKmIconGreen = L.icon({
    iconUrl: kmLogo,
    iconSize: [32, 32],
    iconAnchor: [16, 16]
});
export const getLeafletKmIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletKmIconGreen;
    } else {
        return leafletKmIcon;
    }
}

const leafletFinishIcon = L.icon({
    iconUrl: finishIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
const leafletFinishIconGreen = L.icon({
    iconUrl: finishIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletFinishIcon = () => {
    if (appConfig.iconStyle.includes("green")) {
        return leafletFinishIconGreen;
    } else {
        return leafletFinishIcon;
    }
}

// TODO: hotel blue pic
// const leafletHotelIcon = L.icon({
//     iconUrl: hotelIconGreen,
//     iconSize: [32, 32],
//     iconAnchor: [16, 16],
// });
const leafletHotelIconGreen = L.icon({
    iconUrl: hotelIconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
export const getLeafletHotelIcon = () => {
    return leafletHotelIconGreen;
    // if (appConfig.iconStyle.includes("green")) {
    //     return leafletHotelIconGreen;
    // } else {
    //     return leafletHotelIconGreen;
    // }
}