import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import { useLocation } from 'react-router-dom';

import selogo from './assets/icons/se2in_logo_fullwhite.png';
import spooortsLogo from './assets/icons/spooorts_white.svg';
import packageJson from '../package.json';

const AppMenu = (props) => {

const location = useLocation();
const [activeLogo, setActiveLogo]=useState(spooortsLogo)

  useEffect(() => {
    const appName = packageJson.name;
    const queryParams = new URLSearchParams(location.search);
    let targetPram = queryParams.get('target');
    if(targetPram ==='se2' || appName =='Creator'){
        setActiveLogo(selogo)
    }else{
        setActiveLogo(spooortsLogo)
    }
  }, [location]);

    return (
        <div className="layout-sidebar" onClick={props.onMenuClick}>
            <Link to="/" className="logo">
                <img id="app-logo" className="logo-image" src={activeLogo} alt="spooorts logo" />
            </Link>

            <div className="layout-menu-container">
                <AppSubmenu items={props.model} menuMode={props.menuMode} parentMenuItemActive menuActive={props.active} mobileMenuActive={props.mobileMenuActive} root onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick} />
            </div>
        </div>
    );
}

export default AppMenu;
