import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';
import { useLocation } from 'react-router-dom';

const ContactLetters = () => {
    const formatDate = (date) => {
        const currentdate = new Date (date)
        const day = String(currentdate.getDate()).padStart(2, '0');
        const month = String(currentdate.getMonth() + 1).padStart(2, '0')
        const year = currentdate.getFullYear();
        const hours = currentdate.getHours();
        const minutes = currentdate.getMinutes();
        const seconds = currentdate.getSeconds();
        const fullTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return `${day}/${month}/${year} ${fullTime}`
    };
    const location = useLocation();
    const [trainingPlans, setTrainingPlans] = useState([]);
    const history = useHistory()
    const [newsletterDialogVisible, setNewsletterDialogVisible]=useState(false);
    const [newsGroup, setNewsGroup]=useState(emptyNewsGroup);
    const [newsGroups, setNewsGroups]=useState([]);
    const [searchField, setSearchField]=useState('');
    const [dialogClosed, setDialogClosed]=useState(false);
    let emptyNewsGroup={
        title:'',
        description:'',
        date: formatDate(new Date())
    }
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            // textTransform: "uppercase"
        }
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const openNewsDialog=()=>{
        setNewsletterDialogVisible(true)
    }
    const saveNews=()=>{
        setNewsletterDialogVisible(false);
        let _newsGroups = [...newsGroups];
        _newsGroups.push(newsGroup);
        setNewsGroups(_newsGroups);
        setNewsGroup(emptyNewsGroup);
        setDialogClosed(true);
    }
    const search =()=>{}
    const closeDialog=()=>{
        setNewsletterDialogVisible(false);
       setDialogClosed(true)
    }

    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" className='mr-3' onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-send" onClick={() => null}
                tooltip={t('send_to_all_contacts')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{": " + t('NEWS_LETTERS_NOTE_MSG')}</p>
            {newsGroups.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={openNewsDialog} className="p-button-outlined" 
                    tooltip={t('add_news')} tooltipOptions={{position:'top'}}/>
                </div>
            )}
            {newsGroups.length==0 &&(
                <div class="flex-center">
                   <Button icon="pi pi-plus" onClick={openNewsDialog} className="p-button-outlined"
                   tooltip={t('add_news')} tooltipOptions={{position:'right'}} /> 
                </div>
            )}
        </div>
    );

    const footer = (
        <div style={styles.footer} className='p-flex-group mt-30'>
            <Button type="button" style={styles.button} label={t('save')} onClick={()=>saveNews()} />
        </div>
    );
    const newsletterDialog=(
        <Dialog visible={!!newsletterDialogVisible} style={{ 'maxWidth': '750px' }} header='Newsletter' footer={footer} modal closable onHide={() =>closeDialog()}>
            <div className="p-fluid">
                <div className='field'>
                    <label htmlFor='title'>{t('title')}</label>
                    <InputText  id='title' value={newsGroup?.title} onChange={(e) => setNewsGroup({ ...newsGroup, ...{ title: e.target.value } })} required autoFocus/>
                </div>
                <div className="field">
                    <label htmlFor="description">{t('description')}</label>
                    <Editor style={{ height: '220px' }} id="description"  value={newsGroup?.description} onChange={(e) => setNewsGroup({ ...newsGroup, ...{ description: e.target.value } })}  ></Editor>
                </div>
            </div>
        </Dialog>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={newsGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="date" header={t('date')} sortable></Column>
                    <Column field="title" header={t('title')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {newsletterDialog}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContactLetters, comparisonFn);
