import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import AuthService from '../../../service/auth.service';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import appConfig from '../../../assets/data/config.json';

export const Register = (props) => {

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const[darkMode, setDarkMode]=useState(false);
    const history = useHistory();

    const styles = {
        backgroundColor: appConfig.color,
        width300: {
            width: "300px"
        },
        widthFull: {
            width: "100%"
        }
    }

    const authService = new AuthService();
    useEffect(()=>{
        const savedDarkMode = localStorage.getItem('darkMode') === 'true';
        if(!savedDarkMode){
            setDarkMode(true)  
        }
        let body = document.querySelector('body');
        if (!savedDarkMode) {
            body.classList.add('darkMode');
        } else {
            body.classList.remove('darkMode');
        }
    })
    const submit = () => {
        authService.register(name, password).then((token) => {
            let reverseUrl = props.location.state ? props.location.state.reverseUrl : "/";
            if (props.location.state && props.location.state.callback) {
                props.location.state.callback();
            }
            setTimeout(() => {
                history.replace(reverseUrl);
            }, 1000);
        });
    }

    return (
        <>
        <div className='linear-bg'></div>
        <div className={`login-body ${appConfig.appName === 'Admin spooorts' ? '' : 'login-bg'}`}>
            <div className="login-wrapper">
                <div className="login-panel" style={styles.widthFull}>
                    <div className="login-form" style={styles.widthFull}>
                        <h2 style={{ width: styles.width300.width, color: 'white' }}>{t('REGISTER_SIMPLE')}</h2>
                        <InputText style={styles.width300} placeholder="Email" onChange={(e) => setName(e.target.value)} />
                        <Password style={styles.width300} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        <div class="login-button-group">
                            <Button style={{ width: styles.width300.width, backgroundColor: styles.backgroundColor, borderColor: styles.backgroundColor }} label={t('REGISTER_SIMPLE')} type="button" onClick={submit}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
