import { createStore } from 'redux';

export const filterDashboard = () => {
    return {
        type: "FILTER"
    };
};
export const add = () => {
    return {
        type: "ADD"
    };
};

export const updateProfile = () => {
    return {
        type: "UPDATE_PROFILE"
    };
}

const counter = (state = 0, action) => {
    switch (action.type) {
        case "FILTER": return 0;
        case "ADD": return 1;
        case "UPDATE_PROFILE": return 2;
    }
};

export let storeDashboard = createStore(counter);