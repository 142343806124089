import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ShopService from '../../service/shop.service';
import AuthService from '../../service/auth.service';
import { Checkbox } from 'primereact/checkbox';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ShopDelivery from './shop-delivery';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';

const ShopProductDialog=()=>{
    const [activeDialogTabIndex, setActiveDialogTabIndex] = useState(0);
    const [newProductDialogVisible, setNewProductDialogVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const fileInputRef = useRef(null);
    const imageUploadInputRef = useRef(null);
    const [imageUpload, setImageUpload]=useState('')
    const [isImage, setIsImage]=useState(false);
    const [discountsDialog, setDiscountsDialog]=useState(false);
    const [discountGroups, setDiscountGroups] = useState([]);
    const [discountGroup, setDiscountGroup] = useState(emptyGroup);
    const [searchField, setSearchField]=useState('');
    let [categories1, setCategories1]=useState([])
    let [categories2, setCategories2]=useState([])
    let [categories3, setCategories3]=useState([])
    let [categories4, setCategories4]=useState([])
    let [categories5, setCategories5]=useState([])
    const [newProduct, setNewProduct] = useState({
        lang: "de",
        title: "",
        contents: "",
        price: "0.0",
        curr: "",
        text1: "",
        text2: "",
        text3: "",
        text4: "",
        text5: ""
    });
    const styles = {
        table: {
            padding: "0"
        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }
    const data = {
        currencies: [
            "EUR",
            "USD"
        ], 
        languages: [
            "de",
            "en"
        ]
    };
    let einheit1Options=[
        { label: 'EUR', value: 'EUR' },
        { label: 'USD', value: 'USD' },
        { label: '%', value: '%' },
    ]
    let emptyGroup={
        designCampaign: '',
        amount:0,
        unit:'',
        from:null,
        until: null,
        note:''
    }

    const shopService = new ShopService();
    const authService = new AuthService();

    useEffect(() => {
        let token = authService.getToken();
        if(token){
            shopService.getProducts(token).then(res => {
            });
        }
    }, []);
    useEffect(()=>{
        if(activeDialogTabIndex == 1 || activeDialogTabIndex == 2) window.windowCheck();
        console.log( activeDialogTabIndex == 1)
    }, [newProductDialogVisible, activeDialogTabIndex])

    const handleShopImport = (event) => {
        let token = authService.getToken();
        shopService.handleShopImport(event, token);
    }

    const addNewProduct = () => {
        setNewProductDialogVisible(true);
    }
    const setDeliveryMode = (event) => {
    }
    function resetNewProduct () {
        setNewProduct({
            lang: "de",
            title: "",
            contents: "",
            price: "0.0",
            curr: "",
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: ""
        });
    }

    const saveNewProduct = () => {
        let token = authService.getToken();
        if(newProduct.price.length && newProduct.title.length && newProduct.contents.length){
            shopService.saveProducts(newProduct, token).then(res => {
                setProducts(prev => [
                    ...prev,
                    newProduct
                ]);
                resetNewProduct();
            });
            setNewProductDialogVisible(false);
        }else{
            alert("Die Produktbezeichnung, Preis und Währung müssen immer gefüllt sein.")
        }
    }
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                saveImage(dataUrl, selectedFile.name.trim());
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        setImageUpload(dataUrl)
        setIsImage(true)
    }
    const save =()=>{
        setDiscountsDialog(false);
        let _discountGroups = [...discountGroups];
        _discountGroups.push(discountGroup);
        setDiscountGroups(_discountGroups);
        setDiscountGroup(emptyGroup);
    }
    const close=()=>{
        setDiscountsDialog(false);
    }
    const formatDate = (date) => {
        const currentdate = new Date (date)
        const day = String(currentdate.getDate()).padStart(2, '0');
        const month = String(currentdate.getMonth() + 1).padStart(2, '0')
        const year = currentdate.getFullYear();
        return `${day}/${month}/${year}`
    };
    const add=()=>{
        setCategories1(prev => {
            if (!prev.some(cat => cat.value === newProduct.text1)) {
                 return [...prev, newProduct.text1];
            }
            return prev;
        })
        setCategories2(prev => {
            if (!prev.some(cat => cat.value === newProduct.text2)) {
                 return [...prev, newProduct.text2];
            }
            return prev;
        })
        setCategories3(prev => {
            if (!prev.some(cat => cat.value === newProduct.text3)) {
                 return [...prev, newProduct.text3];
            }
            return prev;
        })
        setCategories4(prev => {
            if (!prev.some(cat => cat.value === newProduct.text4)) {
                 return [...prev, newProduct.text4];
            }
            return prev;
        })
        setCategories5(prev => {
            if (!prev.some(cat => cat.value === newProduct.text5)) {
                 return [...prev, newProduct.text2];
            }
            return prev;
        })
    }
    const remove=()=>{}
    const edit=()=>{}
    const search=()=>{}
    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept=".png,.jpg,.jpeg"
        />
    );
    const openModal2=()=>{
        setDiscountsDialog(true)
    }

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <div className='flex g-5'>
                <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                <Button icon="pi pi-plus" onClick={addNewProduct} className="p-button-outlined" />
                <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
            </div>
        </div>
    );
    const header2 = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            {discountGroups.length!==0 &&(
                <div className='flex g-5'>
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={openModal2} className="p-button-outlined"
                    tooltip={t('addNewProduct')} tooltipOptions={{position:'top'}}  />
                    <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
                </div>
            )}
            {discountGroups.length==0 &&(
                <div className='flex g-5'>
                    <Button icon="pi pi-plus" onClick={openModal2} className="p-button-outlined"
                    tooltip={t('addNewProduct')} tooltipOptions={{position:'top'}}  />
                    <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
                </div>
            )}
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    // const newProductDialogFooter = (
    //     <div className="flex g-10 buttons">
    //         <Button type="button" label={t('save')} onClick={() => saveNewProduct()} />
    //         <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => { setActiveDialogTabIndex(0); setNewProductDialogVisible(false)}} />
    //     </div>
    // );
    const discountsFooter = (
        <div className="flex g-10 buttons">
            <Button type="button" label={t('save')} onClick={() => save()} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => close()} />
        </div>
    );
    const hiddenInput = (
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleShopImport}
            accept=".csv,.xlsx"
        />
    );
    return(
        // <Dialog visible={newProductDialogVisible} onHide={() => {setActiveDialogTabIndex(0);setNewProductDialogVisible(false)}} style={{ maxWidth: '850px', minWidth:'300px' }} position="top" modal>
            <TabView activeIndex={activeDialogTabIndex} onTabChange={(e) => setActiveDialogTabIndex(e.index)}>
                <TabPanel header={t('product')}>
                    <div class="p-flex-group flex-align">
                        <div className="group-block w-65">
                            <label>{t('product')}</label>
                            <InputText onChange={e => setNewProduct(prev => ({
                                ...prev,
                                title: e.target.value
                            }))}/>
                        </div>
                        <div className="group-block w-33">
                            <label>{t('language')}</label>
                            <Dropdown placeholder="DE" options={data.languages} onChange={e => setNewProduct(prev => ({
                                ...prev, 
                                lang: e.value
                            }))}/>
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group align-center">
                        <div className="group-block w-50">
                            <Button icon="pi pi-plus" className="w-100 p-button-outlined" label={t('addPhoto')} onClick={() => imageUploadInputRef.current.click()} />
                        </div>
                        {!isImage &&(
                            <div className="group-block w-20 " style={styles.icon}>
                            <i class="pi pi-shopping-cart"></i>
                        </div>
                        )}
                        {isImage &&(
                        <div className="group-block w-20" style={styles.icon}>
                            <div class="smallimg">
                                <Image src={imageUpload} width='35px' height='35px'></Image> 
                            </div>
                        </div>
                        )}
                        <div class="p-flex-group w-30 checkbox">
                            <div className="group-block">
                                <Checkbox checked={false}></Checkbox>
                            </div>
                            <div className="group-block w-85 ml-10" style={{ justifyContent: "center" }}>
                                <label>{t('showProductInPhoto')}</label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group flex-align">
                        <div className="group-block w-65">
                            <label>{t('price')}</label>
                            <InputText onChange={e => setNewProduct(prev => ({
                                ...prev,
                                price: e.target.value
                            }))} />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('currency')}</label>
                            <Dropdown options={data.currencies} placeholder={newProduct.curr} onChange={e => setNewProduct(prev => ({
                                ...prev, 
                                curr: e.value
                            }))}/>
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group">
                        <div className="group-block w-100">
                            <label>{t('description')}</label>
                            <InputTextarea className="h-100-fixed" onChange={e => setNewProduct(prev => ({
                                ...prev,
                                contents: e.target.value
                            }))}></InputTextarea>
                        </div>
                    </div>
                    <br/>
                    {/* {newProductDialogFooter} */}
                    {hiddenInputImageUpload}
                </TabPanel>

                <TabPanel header={t('shippingTypes')}>
                    <BrowserRouter>
                        <ShopDelivery setSelected={setDeliveryMode} hasGroup={true}/>
                    </BrowserRouter>
                    <br />
                    {/* {newProductDialogFooter} */}
                </TabPanel>

                <TabPanel header={t('discounts')}>
                    <DataTable value={discountGroups} responsiveLayout="scroll" header={header2} paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="designCampaign" header={t('title')} sortable></Column>
                        <Column field="amount" header={t('amount')} sortable></Column>
                        <Column field="unit" header={t('unit')} sortable></Column>
                        <Column field="from" header={t('from')} sortable></Column>
                        <Column field="until" header={t('until')} sortable></Column>
                        <Column field='edit' body={editTemplate}></Column>
                    </DataTable>
                    <br />
                    {hiddenInput}
                    {/* {newProductDialogFooter} */}
                    <Dialog visible={!!discountsDialog} style={{ 'maxWidth': '750px' }} header={t('addDiscountToProduct')} footer={discountsFooter} modal closable onHide={() => setDiscountsDialog(false)}>
                    <div className="p-fluid">
                        <div className='p-flex-group flex-align'>
                            <div className='group-block w-49'>
                                <label htmlFor='designCampaign'>{t('designCampaign')}</label>
                                <InputText  id='designCampaign' value={discountGroup?.designCampaign} onChange={(e) => setDiscountGroup({ ...discountGroup, ...{ designCampaign: e.target.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-49 checkbox'>
                                <div className="group-block ml-10">
                                    <Checkbox id='applyAllProducts' checked={false}></Checkbox>
                                </div>
                                <div className="group-block w-49 ml-10" style={{ justifyContent: "center" }}>
                                    <label htmlFor='applyAllProducts'>{t('applyAllProducts')}</label>
                                </div>  
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                            <div className='group-block w-49'>
                                <label htmlFor='amount'>{t('discount')}</label>
                                <InputNumber  id='amount' value={discountGroup?.amount} onChange={(e) => setDiscountGroup({ ...discountGroup, ...{ amount: e.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='unit'>{t('unit')}</label>
                                <Dropdown id='unit' value={discountGroup?.unit} options={einheit1Options} 
                                onChange={(e) => setDiscountGroup({ ...discountGroup, ...{ unit: e.target.value } })} required autoFocus
                                placeholder='EUR'/>
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                            <div className='group-block w-49'>
                                <label htmlFor='from'>{t('discountFrom')}</label>
                                <Calendar id='from' value={discountGroup?.from} onChange={(e) => setDiscountGroup({ ...discountGroup, until: formatDate(e.target.value)})} showIcon />
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='until'>{t('until')}</label>
                                <Calendar id='until' value={discountGroup?.until} onChange={(e) => setDiscountGroup({ ...discountGroup, until: formatDate(e.target.value) })}showIcon />
                            </div>
                        </div>
                        <hr/>
                        <div className="field">
                            <label htmlFor="note">{t('note')}</label>
                            <InputTextarea id="note"  value={discountGroup?.note} onChange={(e) => setDiscountGroup({ ...discountGroup, ...{ note: e.target.value } })} />
                        </div>
                        <hr/>
                    </div>
                    </Dialog>
                </TabPanel>
                <TabPanel header={t('categories')}>
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={categories1}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('first_level_categories')} onChange={e => setNewProduct(prev => ({
                                ...prev,
                                text1: e.target.value
                            }))}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" onClick={()=>{add()}}
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" onClick={()=>{remove()}}
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" onClick={()=>{edit()}}
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr/>
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={categories2}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('second_level_categories')} onChange={e => setNewProduct(prev => ({
                                ...prev,
                                text2: e.target.value
                            }))}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" onClick={()=>{add()}}
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" onClick={()=>{remove()}}
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" onClick={()=>{edit()}}
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={categories3}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('third_level_categories')} onChange={e => setNewProduct(prev => ({
                                ...prev,
                                text3: e.target.value
                            }))}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" onClick={()=>{add()}}
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" onClick={()=>{remove()}}
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" onClick={()=>{edit()}}
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={categories4}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('forth_level_categories')} onChange={e => setNewProduct(prev => ({
                                ...prev,
                                text4: e.target.value
                            }))}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" onClick={()=>{add()}}
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" onClick={()=>{remove()}}
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" onClick={()=>{edit()}}
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={categories5}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('fifth_level_categories')} onChange={e => setNewProduct(prev => ({
                                ...prev,
                                text5: e.target.value
                            }))}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" onClick={()=>{add()}}
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" onClick={()=>{remove()}}
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" onClick={()=>{edit()}}
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <br/>
                </TabPanel>
            </TabView>
        // </Dialog>   
    );

}
const comparisonFn = function (prevProps, nextProps) {
    // return prevProps.location.pathname === nextProps.location.pathname;
    return true
};

export default React.memo(ShopProductDialog, comparisonFn);