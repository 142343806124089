import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthService from '../../service/auth.service';
import * as uuid from 'uuid';
import { storeContact } from '../../StoreContact';
import ContactService from '../../service/contact.service';
import { useLocation } from 'react-router-dom';

const ContactCategories = () => {
    const location = useLocation();
    const [shippingModes, setShippingModes] = useState([]);
    const [tableShippings, setTableShippings] = useState([]);
    const [dialogDeliveryForm, setDialogDeliveryForm] = useState(null);

    const [dialogEvent, setDialogEvent] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [searchField, setSearchField] = useState("");
    const[dialogClosed, setDialogClosed]=useState(false);

    // 0 - save, 1 - update
    const [actionDialog, setActionDialog] = useState(0);
    const currencies = [
        { label: "EUR", value: "EUR" },
        { label: "USD", value: "USD" }
    ];
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const contactService = new ContactService();
    const authService = new AuthService();

    useEffect(() => {
        setIsMobile(window.innerWidth < 800);
        fetchCategories();
        storeContact.subscribe(() => {
            switch (storeContact.getState()) {
                case 1: addContactCategory(); break;
                default: break;
            }
        })
    }, [])
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const fetchCategories = () => {
        let token = authService.getToken();
        if(token){
            contactService.getContactCategories(token).then(modes => {
                modes.forEach(mode => {
                    mode.edit = (
                        <Button type="button" icon="pi pi-pencil" onClick={() => editMode(mode)}></Button>
                    );
                    mode.remove = (
                        <Button type="button" icon="pi pi-trash" onClick={() => removeMode(mode)}></Button>
                    )
                });
                setShippingModes(modes);
                setTableShippings(modes);
            });
        }
    }

    const editMode = (mode) => {
        setDialogDeliveryForm(mode);
        setDialogEvent(true);
        setActionDialog(1);
    }
    const removeMode = (mode) => {
        let token = authService.getToken();
        contactService.removeContactCat(mode.uuid, token);
        let filteredModes = shippingModes.filter((f) => f.uuid !== mode.uuid);
        setShippingModes(filteredModes);
        setTableShippings(filteredModes);
    }
    const search = () => {
        let arr = shippingModes.filter(s => s.contactGroupName.includes(searchField));
        setTableShippings(arr);
    }
    const closeDialog=()=>{
        setDialogEvent(false);
        setDialogClosed(true)
    }
    const saveShipping = () => {
        let token = authService.getToken();
        let newCategory = { ...dialogDeliveryForm }; // Create a new object here
        if (actionDialog === 0) {
            newCategory.uuid = uuid.v4();
            let modesArr = shippingModes.concat(newCategory); // Use concat to create a new array
            setShippingModes(modesArr);
            setTableShippings(modesArr);
            setDialogEvent(false);
            contactService.saveContactCat(newCategory, token);
            setDialogClosed(true)
        } else if (actionDialog === 1) {
            setDialogEvent(false);
            let arr = shippingModes.map(a => {
                if (a.uuid === dialogDeliveryForm.uuid) {
                    return {
                        ...newCategory,
                        uuid: dialogDeliveryForm.uuid,
                        edit: dialogDeliveryForm.edit,
                        remove: dialogDeliveryForm.remove
                    };
                }
                return a;
            });
            setDialogClosed(true)
            setShippingModes(arr);
            arr = tableShippings.map(a => {
                if (a.uuid === dialogDeliveryForm.uuid) {
                    return {
                        ...newCategory,
                        uuid: dialogDeliveryForm.uuid,
                        edit: dialogDeliveryForm.edit,
                        remove: dialogDeliveryForm.remove
                    };
                }
                return a;
            });
            setTableShippings(arr);
            contactService.updateContactCat(newCategory, token);
            setDialogClosed(true)
        }
    }

    const addContactCategory = () => {
        // if (!authService.isLoged()) { history.push("/login"); return; }

        let newCategory = {
            lang: "DE",
            uuid: "",
            isDel: false,
            contactGroupName: "",
            contents: "",
            lid: -1
        };
        setDialogDeliveryForm(newCategory);
        setActionDialog(0);
        setDialogEvent(true);
    }

    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const dialogFooter = (
        <div style={styles.footer} className='mt-20 p-flex-group g-10 buttons'>
            <Button type="button" label={t('save')} onClick={saveShipping} />
            <Button type="button" className='p-button-outlined' onClick={() => closeDialog()} label={t('close')} />
        </div>
    );

    const dialog = (
        <Dialog className='dialog-container' visible={dialogEvent} header={t('CONTACT_CATEGORY')} onHide={() => closeDialog()}>
            <div class="p-flex-group align-end">
                <div className="group-block w-100">
                    <label>{t('CATEGORY_NAME')}</label>
                    <InputText placeholder={dialogDeliveryForm?.contactGroupName} onChange={(e) => setDialogDeliveryForm({ ...dialogDeliveryForm, contactGroupName: e.target.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="p-flex-group">
                <div className="group-block w-100 pt-30">
                    <label>{t('note')}</label>
                    <InputTextarea className="h-100-fixed" placeholder={dialogDeliveryForm?.contents} onChange={(e) => setDialogDeliveryForm({ ...dialogDeliveryForm, contents: e.target.value })}></InputTextarea>
                </div>
            </div>
            {dialogFooter}
        </Dialog>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('CONTACT_CATEGORY_MSG')}</p>
            {tableShippings.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={addContactCategory} className="p-button-outlined"
                    tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {tableShippings.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={addContactCategory} className="p-button-outlined" 
                    tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'right'}}/>
                </div>
            )}
        </div>
    );

    return (
        <div>
            {dialog}
            <div className='card' style={styles.table}>
                <DataTable value={tableShippings} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="contactGroupName" header={t('category')} sortable></Column>
                    <Column field="contents" header={t('description')} sortable></Column>
                    <Column field="edit" body={editTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContactCategories, comparisonFn);
