import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import EventService from '../../../service/EventService';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SalesService from '../../../service/sales.service';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { storeDashboard } from '../../../StoreDashboard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../service/auth.service';
import { Tooltip } from 'primereact/tooltip';
import { useLocation } from 'react-router-dom';
import { windowCheck } from '../../../repeat-header';
import { resize } from '../../../repeat-header';

const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const Dashboard = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [lineOptions, setLineOptions] = useState(null);
    const [lineData, setLineData] = useState([]);
    const [barOptions, setBarOptions] = useState(null)
    const [pieOptions, setPieOptions] = useState(null)
    const [polarOptions, setPolarOptions] = useState(null)
    const [radarOptions, setRadarOptions] = useState(null)
    const [filterDialogFlag, setFilterDialogFlag] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [untilDate, setUntilDate] = useState(null);
    const [isMobile, setIsMobile] = useState(null);
    const [sales, setSales] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [chartHeader, setChartHeader]=useState('totalSales');
    const location = useLocation();
    let mode;
    useEffect(() => {
        mode = localStorage.getItem('darkMode');
        let body = document.querySelector('body');
        if(mode == null){
            body.classList.remove('darkMode');
            localStorage.setItem('darkMode', true);
            mode = "true";
        }
        console.log('mode', mode);
    })

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: mode==="true"?"#294f94":"#ffffff",
                        borderColor:mode==="true"?"#ebedef":"#ffffff"
                        
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: mode==="true"?"#294f94":"#4285f4"
                    },
                    grid: {
                         color: mode==="true"?"#ebedef":"#ffffff",
                         borderColor:mode==="true"?"#ebedef":"#ffffff"
                    }
                },
                y: {
                    ticks: {
                        color: mode==="true"?"#294f94":"#4285f4"
                    },
                    grid: {
                       color: mode==="true"?"#ebedef":"#ffffff",
                       borderColor:mode==="true"?"#ebedef":"#ffffff"
                    }
                },
            }
        };

        const barOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: mode==="true"?"#294f94":"#4285f4"
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: mode==="true"?"#294f94":"#4285f4"
                    },
                    grid: {
                        color: mode==="true"?"#ebedef":"#ffffff"
                    }
                },
                y: {
                    ticks: {
                        color: mode==="true"?"#294f94":"#4285f4"
                    },
                    grid: {
                       color: mode==="true"?"#ebedef":"#ffffff"
                    }
                },
            }
        };

        const pieOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: mode==="true"?'#495057':'#607D8B'
                    }
                }
            }
        };

        const polarOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: mode==="true"?'#495057':'#607D8B'
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: mode==="true"?"#ebedef":"#ffffff"
                    }
                }
            }
        };

        const radarOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: mode==="true"?'#495057':'#607D8B'
                    }
                }
            },
            scales: {
                r: {
                    grid: { 
                        color: mode==="true"?"#ebedef":"#ffffff",
                        borderColor: mode==="true"?"#ebedef":"#ffffff"
                    }
                }
            }
        };

        setLineOptions(lineOptions)
        setBarOptions(barOptions)
        setPieOptions(pieOptions)
        setPolarOptions(polarOptions)
        setRadarOptions(radarOptions)
    }

    const salesService = new SalesService();
    const authService = new AuthService();
    useEffect(() => {
        setIsMobile(window.innerWidth < 800);
        let token = authService.getToken();
        // if(!authService.isLoged()){
        //     history.push("/login");
        //     return;
        // }
        if(token){
        salesService.getSales(token).then(sales => {
            console.log(sales);
            sales = sales.sort((a, b) => a.lastModified - b.lastModified);

            let chartData = sales.map(s => {
                return {
                    date: s.lastModified,
                    lastModified: s.lastModified,
                    price: s.price
                }
            });
            chartData.forEach(f => {
                const date = new Date(f.date);
                const month = monthNames[date.getMonth()];
                const year = date.getFullYear();
                f.date = `${month} ${year}`;
            });
            console.log('chartData', chartData);

            let lineData = {
                labels: chartData.sort((a, b) => a.lastModified - b.lastModified).map(s => s.date),
                datasets: [
                    {
                        label: t('totalSales'),
                        data: chartData.map(s => s.price),
                        fill: false,
                        borderColor: mode==="true"?'#294f94':'#4285f4',
                        backgroundColor: mode==="true"?"#ebedef":"#ffffff",
                        tension: 1

                    }
                ]
            };
            sales.forEach(s => {
                const date = new Date(s.lastModified);

                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();

                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                s.date = `${day}.${month}.${year} ${hours}:${minutes}`;
            });
            setLineData(lineData);
            setSales(sales);
            setTableData(sales);
        })
        }
        storeDashboard.subscribe(() => {
            console.log('sub works');
            switch (storeDashboard.getState()) {
                case 0: setFilterDialogFlag(true); break;
            };
        });
        applyLightTheme();
    }, []);

    useEffect(() => {
        const handleWindowCheck = () => {
           windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);


    

    const filterTableDates = () => {
        let fromTime = new Date(fromDate).getTime();
        let untilTime = new Date(untilDate).getTime();
        setTableData(sales.filter(sale => sale.lastModified >= fromTime && sale.lastModified <= untilTime));
    }

    const filterChartDates = () => {
        let fromTime = new Date(fromDate).getTime();
        let untilTime = new Date(untilDate).getTime();
        let chartData = sales.filter(sale => sale.lastModified >= fromTime && sale.lastModified <= untilTime).map(s => {
            return {
                date: s.lastModified,
                lastModified: s.lastModified,
                price: s.price
            }
        });
        chartData.forEach(f => {
            const date = new Date(f.date);
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            f.date = `${month} ${year}`;
        });
        let lineData = {
            labels: chartData.sort((a, b) => a.lastModified - b.lastModified).map(s => s.date),
            datasets: [
                {
                    label: t('totalSales'),
                    data: chartData.map(s => s.price),
                    fill: false,
                    borderColor: mode==="true"?'#294f94':'#4285f4',
                    backgroundColor: mode==="true"?"#ebedef":"#ffffff",
                    tension: 1

                }
            ]
        };
        setLineData(lineData);
    }

    const filterDialog = (
        <Dialog header={t('FILTER_DATE')} visible={filterDialogFlag} onHide={() => setFilterDialogFlag(false)} style={{ minWidth: '350px', maxWidth:'400px' }} modal>
            <div className="flex calendar-flex">
                <label htmlFor='von' className='mb-1'>{t('DATE_OF')}</label>
                <Calendar id='von' value={fromDate} onChange={(e) => setFromDate(e.value)} showIcon />
            </div>
            <br />
            <div className="flex calendar-flex">
                <label htmlFor='bis' className='mb-1'>{t('DATE_TO')}</label>
                <Calendar id='bis' value={untilDate} onChange={(e) => setUntilDate(e.value)} showIcon />
            </div>
            <br />
            <div className='p-flex-group mt-30'>
                <Button onClick={() => {
                    filterTableDates();
                    filterChartDates();
                    setFilterDialogFlag(false);
                }}>Ok</Button>
            </div>
        </Dialog>
    );

    const footer = (
        <div className='footer-images'>
            <img className='logoPdf' alt="logo pdf"></img>
            <img className='logoCsv' alt="logo cvs"></img>
            <img className='logoXml' alt="logo xml"></img>
        </div>
    )

    return (
        <div className='flex jcsb responsive responsive-dashboard'>
            <div className="card w-100" style={{ height: "min-content" }}>
                <div className='flex g-10 buttons-row-container'>
                    <Button icon="pi pi-calendar-plus" className='p-button-outlined' onClick={(() => history.push('/editRegistrations'))}
                        tooltip={t('REGISTRATION_INSERT')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-shopping-cart" className='p-button-outlined'  onClick={(() => history.push('/shopSales'))}
                        tooltip={t('enterProductSale')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-user-plus" className='p-button-outlined' onClick={(() => history.push('/editContacts'))}
                        tooltip={t('CONTACT_INSERT')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-plus" className='p-button-outlined' onClick={(() => history.push('/editEvent'))} 
                        tooltip={t('EVENT_INSERT')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-euro" className='p-button-outlined' onClick={()=>setChartHeader('totalSales')}
                        tooltip={t('totalSales')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-shopping-cart" className='p-button-outlined' onClick={()=>setChartHeader('REVENUE_TOTAL')}
                        tooltip={t('REVENUE_TOTAL')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-ticket" className='p-button-outlined' onClick={()=>setChartHeader('REGISTRATION_SALES')}
                        tooltip={t('REGISTRATION_SALES')} tooltipOptions={{ position: 'top' }}></Button>
                    <Button icon="pi pi-filter" className='p-button-outlined' onClick={() => setFilterDialogFlag(true)}
                        tooltip={t('FILTER_DATE')} tooltipOptions={{ position: 'top' }}></Button>
                </div>
                <div className='mt-20'>
                    <DataTable value={tableData} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column sortField="lastModified" field="date" header={t('date')} sortable></Column>
                        <Column field="subject" header={t('usage')} sortable></Column>
                        <Column field="price" header={t('price')} sortable></Column>
                        <Column field="currency" header={t('currency')} sortable></Column>
                    </DataTable>
                    {footer}
                </div>
            </div>
            <div className="card w-100 chart-bar">
                <h3 className='chart-header'>{t(chartHeader)}</h3>
                <Chart type="bar" data={lineData} options={lineOptions} />
            </div>
            {filterDialog}
        
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
