import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import TrainingService from '../../service/TrainingService';
import { t } from 'i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useHistory } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import ShopService from '../../service/shop.service';

const ShopDeliveryNew = () => {
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [deliveryModes, setDeliveryModes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const history = useHistory()
    const styles = {
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {

        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        }
    }

    const trainingService = new TrainingService();
    const shopService = new ShopService();

    useEffect(() => {
        trainingService.getTrainingPlans().then(plans => setTrainingPlans(plans));

    }, [])

    useEffect(() => {
    });

    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate');
    }

    const addNewProduct = () => {

    }

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined mr-2 mb-2" />
        </div>
    );

    const footer = (
        <div style={styles.footer} className='buttons'>
            <Button type="button"  label={t('save')} onClick={addNewProduct} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={addNewProduct} />
        </div>
    );

    return (
        <div className="card">
        <div class="p-flex-group">
            <div className="group-block w-40">
                <label>{t('language')}</label>
                <Dropdown placeholder="1" />
            </div>
        </div>
        <hr/>
        <div class="p-flex-group">
            <div className="group-block w-49">
                <label>{t('shippingTypes')}</label>
                <InputText />
            </div>
            <div className="group-block w-49">
                <Dropdown placeholder="1" />
            </div>
        </div>
        <hr/>
        <div class="p-flex-group">
            <div className="group-block w-49">
                <label>{t('price')}</label>
                <InputText />
            </div>
            <div className="group-block w-49">
                <label>{t('currency')}</label>
                <Dropdown placeholder="1" />
            </div>
        </div>
        <hr/>
        <div className="p-flex-group">
            <div className="group-block w-100 pt-30">
                <label>{t('description')}</label>
                <InputTextarea className="h-100-fixed"></InputTextarea>
            </div>
        </div>
        {footer}
    </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopDeliveryNew, comparisonFn);
