import DomainService from "./domain";

export default class MapService {

    domainService = new DomainService();

    getElevation(position, token) {
        return new Promise((resolve, reject) => {
            return fetch(this.domainService.getActiveDomain() + `route/user/elevation/${position.lat}_${position.lng}`)
            .then(res => res.json())
            .then(res => resolve(res.results[0].elevation))
            .catch(err => reject(err));
        });
    }

    getElevations(locations, token) {
        return new Promise((resolve, reject) => {
            return fetch(this.domainService.getActiveDomain() + `route/user/elevations/${locations}`)
            // return fetch("http://localhost:8080/" + `user/elevations/${locations}`)
                .then(res => res.json())
                .then(res => {
                    if(res.status !== "OK"){
                        reject("Server Internal Error while fetching elevations");
                        return;
                    }
                    resolve(res.results.map(point => point.elevation))
                })
                .catch(err => reject(err));
        });
    }

    async getRouting(pointA, pointB, mode = "driving", token) {
        return new Promise(async (resolve, reject) => {
            mode = this.parseMode(mode);
            let autoRoutingRequest = {
                mode: mode,
                latPointA: pointA.position.lat || pointA.position[0],
                lngPointA: pointA.position.lng || pointA.position[1],
                latPointB: pointB.position.lat || pointB.position[0],
                lngPointB: pointB.position.lng || pointB.position[1]
            };
            try {
                const response = await fetch(this.domainService.getActiveDomain() + `route/user/autorouting`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(autoRoutingRequest)
                });
                const res = await response.json();
                if (res && res.routes[0] && res.routes[0].legs[0] && res.routes[0].legs[0].steps) {
                    let detailedRoute = [];
                    let coordinates = res.routes[0].legs[0].steps.map((step, index) => {
                        const geometry = step.geometry;
                        const decodedCoordinates = this.decodePolyline(geometry);
                        if (typeof step.maneuver.modifier === "undefined") {
                            step.maneuver.modifier = "";
                        }

                        detailedRoute.push({
                            name: step.name,
                            type: step.maneuver.type,
                            sign: step.maneuver.modifier.split(" ").join("-"),
                            distance: Math.floor(step.distance) + " m"
                        });

                        return decodedCoordinates;
                    });
                    detailedRoute[0].sign = "A";
                    detailedRoute[0].type = "";
                    detailedRoute[detailedRoute.length - 1].sign = "B";
                    detailedRoute[detailedRoute.length - 1].type = "";

                    let concatenatedCoordinates = coordinates.reduce((acc, cur) => acc.concat(cur), []);
                    let formattedCoordinates = concatenatedCoordinates.map(coords => { return { lat: coords[0], lng: coords[1] } })
                    let extendedArray = [...res.routes[0].legs[0].annotation.distance]; // Create a copy of array 'a'
                    while (extendedArray.length < formattedCoordinates.length) {
                        const randomIndex = Math.floor(Math.random() * (extendedArray.length + 1)); // Generate a random index
                        extendedArray.splice(randomIndex, 0, 0); // Insert 0 at the random index
                    }
                    extendedArray = extendedArray.map(value => Number(value / 1000).toFixed(3))
                    resolve({ coords: formattedCoordinates, distance: extendedArray, detailedRoute: detailedRoute });
                }
                resolve(null)
            } catch (err) {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    reject(null);
                }
            }
        });
    }

    checkIfRouteExist(pointA, pointB, mode = "driving", token) {
        return new Promise((resolve, reject) => {
            mode = this.parseMode(mode);
            let autoRoutingRequest = {
                mode: mode,
                latPointA: pointA.position.lat,
                lngPointA: pointA.position.lng,
                latPointB: pointB.position.lat,
                lngPointB: pointB.position.lng
            };
            try {
                fetch(this.domainService.getActiveDomain() + `route/user/autorouting`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(autoRoutingRequest)
                }).then(res => {
                    if (!res.ok) {
                        resolve(false);
                    }
                    resolve(true);
                }).catch(res => {
                    resolve(false);
                });

            } catch (err) {
                resolve(false);
            }
        });
    }

    decodePolyline(encoded) {
        let index = 0;
        let lat = 0;
        let lng = 0;
        const coordinates = [];
        while (index < encoded.length) {
            let shift = 0;
            let result = 0;
            let byte;
            do {
                byte = encoded.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);
            const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                byte = encoded.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            lng += dlng;
            coordinates.push([lat / 1e5, lng / 1e5]);
        }
        return coordinates;
    }

    searchByCityName(address) {
        let url = "https://nominatim.openstreetmap.org/";
        let query = "";
        let data = address.split(',');
        if (data.length > 1) {
            query = `search.php?street=${data[1]}&city=${data[0]}&polygon_geojson=1&format=jsonv2`;
        } else {
            query = `search?q=${address}&format=json&addressdetails=1`;
        }
        return fetch(url + query)
            .then(res => res.json())
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }

    deleteRoute(token, route) {
        return new Promise((resolve, reject) => {
            fetch(this.domainService.getActiveDomain() + `route/user/${token}/route/delete`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(route)
            })
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject();
                console.log("error while deleting the route: " + err);
            });
        })
    }

    saveRoute(token, route) {
        return fetch(this.domainService.getActiveDomain() + `route/user/${token}/route/save`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(route)
        }).then(response => {
            if (response.ok) {
                return true;
            } else {
                console.log('Image not uploaded due to some error!');
                return false;
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return false;
            }
        });
    }

    getRoutes(token) {
        return fetch(this.domainService.getActiveDomain() + `route/user/${token}/routes/full-payload`).then(res => res.json()).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    getRouteByIdOrName(token, value) {
        return fetch(this.domainService.getActiveDomain() + `route/user/${token}/route?name=${value}`).then(res => res.json()).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    parseMode(category) {
        if (category.includes("Wandern") || category.includes("Schneeschuhroute") || category.includes("Laufstrecke") || category.includes("Walking")) {
            return "foot";
        } else if (category.includes("Rennradstrecke") || category.includes("Mountainbikestrecke")) {
            return "bike"
        } else {
            return "driving";
        }
    }

    getElevationUnsafe(position) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const timeoutId = setTimeout(() => {
            abortController.abort(); // Abort the request after 2 seconds
        }, 1000);
        const firstUrl = `https://api.open-elevation.com/api/v1/lookup?locations=${position.lat || position[0]},${position.lng || position[1]}`;
        const secondUrl = `https://api.open-meteo.com/v1/elevation?latitude=${position.lat || position[0]}&longitude=${position.lng || position[1]}`;
        return fetch(firstUrl, { signal })
            .then(res => {
                clearTimeout(timeoutId);
                return res.json()
            })
            .then(res => res.results[0].elevation)
            .catch(err => {
                clearTimeout(timeoutId); // Clear the timeout in case of any error
                if (err.name == "AbortError") {
                    return fetch(secondUrl).then(res => res.json()).then(res => res.elevation[0]).catch(err => {
                        if (err.status === 400 || err.status === 401) {
                            alert(err.error.message);
                            return null;
                        }
                    })
                } else {
                    console.log("Elevation Data fetch error: ", err);
                    throw err;
                }
            })
    }

    decodeRoute(encoded) {
        let index = 0;
        let lat = 0;
        let lng = 0;
        const coordinates = [];
        while (index < encoded.length) {
            let shift = 0;
            let result = 0;
            let byte;
            do {
                byte = encoded.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);
            const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                byte = encoded.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
            lng += dlng;
            coordinates.push([lat / 1e5, lng / 1e5]);
        }
        return coordinates;
    }

    encodeRoute(route) {
        let encoded = '';
        let prevLat = 0;
        let prevLng = 0;

        for (const coord of route) {
            const lat = Math.round((coord.lat || coord[0]) * 1e5);
            const lng = Math.round((coord.lng || coord[1]) * 1e5);

            const dLat = lat - prevLat;
            const dLng = lng - prevLng;

            prevLat = lat;
            prevLng = lng;

            encoded += this.encodeValue(dLat) + this.encodeValue(dLng);
        }

        return encoded;
    }

    encodeValue(value) {
        let encoded = '';
        value = value < 0 ? ~(value << 1) : value << 1;

        while (value >= 0x20) {
            encoded += String.fromCharCode((0x20 | (value & 0x1f)) + 63);
            value >>= 5;
        }

        encoded += String.fromCharCode(value + 63);
        return encoded;
    }
}