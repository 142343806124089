import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import trainingGroups from '../pages/training/training-groups';

const fallbackLng = ['de'];
const availableLanguages = ['en', 'de'];

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.
    lng: 'de',
    detection: {
      checkWhitelist: true, // options for language detection
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },


    resources: {
      en: {
        translation: {
          we: "We",
          events: "Events",
          ourEvents: "Our Events",
          registrations: "Registrations",
          tickets: "Tickets",
          ealyBirdRebate: "Early Bird Discount",
          eventMap: "Event Map",
          results: "Results",
          about: "About us",
          coupon: "Coupon",
          coupons: "Vouchers",
          websiteAndApp: "Our App",
          settings: "Settings",
          contacts: "Contacts",
          templates: "Templates",
          general: "General",
          question: "Frage",
          questions: "Questions",
          trainingDates: "Training dates",
          trainingGroups: "Training groups",
          trainingPersonal: "Personal training",
          trainingPlan: "Training plan",
          ourProducts: "Our products",
          sales: "Sales",
          discount: "Discount",
          discounts: "Discounts",
          shippingType:"Shipping type",
          shippingCountry: "Shipping country",
          shippingTypes: "Shipping types",
          ourContacts: "Our contacts",
          categories: "Categories",
          appUser: "App User",
          news: "News",
          addTrainingGroup: "Add training group",
          trainingGroup: "Training group",
          description: "Description",
          note: "Note",
          save: "Save",
          close: "Close",
          participant: "Participant",
          trainer: "Trainer",
          beginner: "Beginner",
          advanced: "Advanced",
          professional: "Professional",
          planName:"Plan name",
          backToList: "Back to the list",
          unit: "Unit",
          specificUnit: "Specific unit",  
          enduranceUnit: "Endurance unit",
          powerUnit: "Power unit",
          week: "Week",
          day: "Day",
          training: "Training",
          units: "Units",
          specificTraining: "Specific training",
          enduranceTraining: "Endurance training",
          powerTraining: "Power training",
          sportType: "Sport type",
          trainingType: "Training type",
          duration: "Duration",
          selectPlease: "Select please",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds",
          crossduathlon: "Cross-duathlon",
          duathlon: "Duathlon",
          inliner: "Inliner",
          running: "Running",
          mtb: "Mountain bike",
          cycling: "Racing bike",
          swimming: "Swimming",
          triathlon: "Triathlon",
          ski: "Skiing",
          walking: "Hiking / Walking",
          crossrunning: "Crossrunning",
          obstaclerunning: "Obstacle running",
          womenrunnung: "Womens run",
          wheelchair: "Wheelchair",
          enduranceTrainingGA1: "Endurance training GA1",
          enduranceTrainingGA2: "Endurance training GA2",
          drivingGame: "Driving game",
          intervals: "Intervals",
          competition: "Competition",
          tempoduration: "Tempo duration",
          tech: "Technology",
          coordination: "Coordination",
          strengthAndStabilisation: "Strength and stabilization",
          distance: "Distance",
          muscleGroup: "Muscle group",
          product: "Product",
          price: "Price",
          currency: "Currency",
          language: "Language",
          addNewProduct: "Add new product",
          notice: "Notice",
          productsNoticeContent: ": Here you can create products for your online shop, which can also be offered if you register as a participant.",
          salesNoticeContent: ": Here you can see all product sales. You can also export your sales here and enter a product sale directly.",
          discountNoticeContent: ": Here you can enter discounts for your products. In each of your products you can customize the discounts specifically for the product",
          shippingNoticeContent: ": Shipping countries If you would like to offer products with your participant registrations, you can set the shipping methods here",
          couponsNoticeContent: ": Here you can see all the vouchers that have been purchased and whether they have already been redeemed. You can also create a new voucher here.",
          eventNoticeContent:": Here you can see your future events, which can also be seen in the app.",
          editRegistrationsNoticeContent:': Here you can enter, upload or edit your participant registrations.',
          organizerMapsNoticeContent:': Here you can enter your competition routes for GPS tracking.',
          organizerResultsNoticeContent:': Here you can upload or edit your results.',
          trainingGroupsNoticeContent:': Here you can create and manage a training group or a course group.',
          templateNoticeContent:': You can choose a template below or upload your own. The area in the middle must be bright so that the font can be read, dimensions 4088 x 6320 pixels',
          date: "Date",
          usage: "Usage",
          enterProductSale: "Enter product sale",
          title: "Title",
          amount: "Amount",
          from: "From",
          until: "Until",
          addDiscountToProduct: "Add discount for products",
          newShippingType: "New shipping type",
          shopProducts: "Shop products",
          addPhoto: "Upload the product photo",
          addNewsPhoto:'Upload photo',
          showProductInPhoto: "Show product in shop",
          productSale: "Product sale",
          recipient: "Recipient",
          enterProduct: "Enter product",
          number: "Number",
          firstName: "Name",
          surname: "Surname",
          totalSales: "Total sales",
          uploadLogo: "Upload logo",
          uploadPhoto: "Upload photo",
          uploadSponsorLogo: "Upload sponsor logos",
          organizerName: "Organizer name",
          street: "Street",
          houseNum: "House num.",
          index: "Zip",
          area: "Location",
          country: "Country",
          region: "State",
          value: "Value",
          createCoupon: "Create coupon",
          linkToOurApp: "Link to our app",
          copyPaste: "Copy & Paste",
          allDay: "All day",
          copyLink: "Copy link",
          customizeDesign: "Customize app design",
          sponsorsInsteadOfAdvertising: "Sponsors instead of advertising",
          websiteMessage: "Upgrade to the premium version now and show your sponsors instead of the ads.",
          moreInfo: "More info",
          category: "Category",
          defaultCategory: "Default Category",
          defaultSport: "Default sport type",
          activatedPaymentOptions: "Activated payment options",
          creditCard: "Credit card",
          sofortPayment: "Sofort payment",
          another: "Another",
          priceOnInvoice: "Price on invoices",
          vat: "VAT amount in percent",
          noteOnInvoice: "Notes on the invoice (three lines with a maximum of 57 characters)",
          priceUsedGrossPrice: "Prices used are gross prices",
          questionPageText: ": Here you can adapt the templates, e.g. adapt the design of your vouchers or certificates. In each of your events you can customize the templates specifically for the event.",
          enterQuestion: "Enter question",
          designCampaign: "Discount campaign name",
          discountFrom: "Discount promotion from",
          applyAllProducts: "Applies to all products",
          ERROR_VALUE: "Error",
          ROUTENAME_EMPTY_ERROR_MESSAGE: "Please provide a route name",
          NEW_DIALOG_MESSAGE: "You can re-capture a route by entering the starting location and clicking 'Go'. Then capture the waypoints along the route from the start to the destination by clicking on the map.",
          NEW_DIALOG_SPOOORTS_MESSAGE: "You can re-record a route by entering the starting location and clicking on 'Start recording'. Then record the waypoints along the route from the start to the destination by clicking on the map. If you can click on a marker that has already been set again You enter the number of rounds.",
          INSERT_ROUTE: "Insert route",
          STARTING_LOCATION: "Starting location",
          FINISH_LOCATION: "Destination",
          IMPORT_ROUTE: "Import route",
          ROUND_COUNT: "Number of rounds",
          ROUTE_NAME: "Route name",
          DELETE: "Delete",
          ELEVATION_PROFILE: "Elevation Profile",
          DELETE_LAST_POINT: "Delete last point",
          EDIT_ROUTE: "Edit route",
          CLOSE: "Close",
          START_RECORDING: "Start recording",
          TRACKING_ICON: "Tracking",
          INFO_ICON: "Info",
          PARK_ICON: "Parking",
          SHOWER_ICON: "Shower",
          COFFEE_ICON: "Food",
          HOTEL_ICON: "Hotel",
          NEW_PLAN: "New Route",
          GO_SIMPLE: "Go",
          SAVE_SUCCESS: "Successfully saved",
          NOT_SAVED_MESSAGE: "The route is not yet saved",
          ERROR_DEFAULT: "Please try again later",
          IMPORT_GPX: "+ GPX file import",
          IMPRINT_SIMPLE: "Imprint",
          NEW_ROUTE: "New route",
          DELETE_ALL_PHOTOS: "Delete all photos",
          NO_INTERNET_MESSAGE: "Please connect to Wi-Fi for full functionality",
          edit_coupon:'Edit coupon',
          answer_option:'Answer option',
          required_question:'The question must be answered',
          optional_question:'The answers are not predetermined',
          online_registration_question:'Ask questions about online registration',
          questions_about_registration:'Questions about registration',
          our_logo:'Our Logo',
          photos:'Photos',
          sponsors:'Sponsors',
          PAYMENT_SIMPLE:'Payment',
          shop_categories:'Product Categories',
          product_sales:'Product sales',
          deliveries:'Deliveries',
          edit_shipping_method:'Edit shipping method',
          products:'Products',
          designation: 'Designation',
          oredered_from_supp:'Ordered from the supplier',
          delivery_received: 'Delivery received',
          notes:'Notes',
          german: 'German',
          english:'English',
          evaluation:'Evaluation',
          event_registration:'Process registrations',
          activate_participant_registration:'Activate participant registration',
          unlock_spectator_ticketing:'Unlock spectator ticketing',
          private_event: 'Private event',
          date_unconfirmed:'Date unconfirmed',
          event_cancelled:'Event cancelled',
          organizer_page:'Organizer page',
          type:'Type',
          date_time:'Date/Time',
          participant_price:'Participant price',
          distance_km:'Distance in km',
          more_info_about_event:'More information about this event',
          more_info:'More information',
          more_info_about_entire_event: 'More information about the entire event',
          tender:'Tender',
          route:'Route',
          edit_event:'Edit event',
          award_ceremony_at:'Award ceremony at',
          late_registrations:'Late registrations until',
          completely_flat:'completely flat',
          route_profile:'Route profile',
          mostly_flat:'mostly flat',
          hilly:'hilly',
          mountainous: 'mountainous',
          underground:'Underground',
          asphalt_only:'Asphalt only',
          forest_dirt:'Forest/Dirt Roads',
          mixed:'Mixed',
          distinction: 'Distinction',
          medal: 'Medal',
          certificate: 'Certificate',
          gift:'Gift',
          winner: 'Winner',
          cup: 'Cup',
          non_cash:'Non cash prize',
          cash:'Cash',
          along_the_route:'along the route',
          free_at_the_finish:'free at the finish',
          warm_food:'warm food',
          cake:'cake',
          other:'Other',
          shower:'Shower',
          changing_rooms:'Changing rooms', 
          wc:'Toilets',
          contact:'Contact',
          enter_next_event:'Enter your next event',
          general_event_dates:'General event dates',
          add_sport_type:'Add sport type',
          voucher_recipient:'Voucher recipient',
          IBAN:'Iban (your bank details to which your payouts should be credited)',
          upload_own_template:'Upload your own template',
          apply_changes:'Apply changes',
          new_event:'Add a new event',
          attributes:'Attributes',
          addDiscount:'Add Early Bird discount',
          add_cotact: 'Add contact',
          edit_warehouse:'Edit warehouse',
          add_warehouse:'Add new warehouse',
          add_delivery: 'Add Delivery',
          new_contact:'Enter a new contact',
          new_message:'New Message',
          send_as_mail:'Send as mail',
          send_to_mobile:"Send to mobile",
          add_news:'Adding news',
          training_dates:'Training dates',
          add_product:'Add a product',
          enter_shippingMethod:'Enter shipping method',
          send_to_all_contacts:'Send to all contacts',
          REGISTRATION_INSERT:'Enter registration',
          CONTACT_INSERT:'Enter contact',
          EVENT_INSERT: 'Enter event',
          REVENUE_TOTAL: 'Revenue from product sales',
          REGISTRATION_SALES: 'Revenue from registrations',
          FILTER_DATE:'Filter by date',
          DATE_OF:'Date of',
          DATE_TO:'Date to',
          WEBSITE_NOTE_MSG: "Here you can design your website and set your URL. If you already have a website, you can easily integrate your spooorts pages on your website.",
          WEBSITE_PREMIUM_ADS_MSG: "Upgrade to Premium now and display your sponsors instead of ads.",
          BTN_UPGRADE: "Upgrade Now",
          WEBSITE_OWN_URL: "Own website url",
          WEBSITE_PREMIUM_URL_MSG: "Upgrade to Premium now and choose your own short URL.",
          BTN_ORDER: "Order",
          WEBSITE_OBSERVE: "View website",
          WEBSITE_DESIGN: "Website design",
          WEBSITE_INTEGRATE: "Integrate your website",
          WEBSITE_URL_COPY: "Copy url",
          WEIGHT_FORMAT: "Unit of weight",
          NOTE_MAX_STRING: "Notes on invoice (three lines of 57 characters maximum)",
          EDIT_TEMPLATE: "Edit template",
          NOTE_SALES_MSG: ": Here you can enter discounts for your events. In each of your events, you can adjust the discounts specifically for the event.",
          DISTANCE_FORMAT: "Unit of length",
          NUMBER_FORMAT: "Format",
          DATE_FORMAT: "Date",
          SETTINGS_QUESTION_NOTICE:': Here you can enter the questions you would like to ask the participant when registering online. In each of your events, you can customize the questions specifically for the event.',
          PHONE_NUMBER: "Phone number",
          TRAINING_PLAN_ADD: "Add training plan",
          ALL_CATEGORIES: ' All categories',
          first_level_categories:'Categories on 1st level',
          second_level_categories:'Categories on 2nd level',
          third_level_categories:'Categories on 3rd level',
          forth_level_categories:'Categories on 4th level',
          fifth_level_categories:'Categories on 5th level',
          PRODUCT_NOTE_MSG: "Here you can manage your product categories.",
          LOCKED_SIMPLE: "Locked",
          NOTE_WAREHOUSE_DELIVERY_MSG: ": Here you can manage the deliveries of your stocks.",
          WAREHOUSE_SIMPLE: "Warehouse",
          ORDERED_AT: "Ordered at",
          DELIVERY_DATE: "Delivery date",
          CONTACT_OUR: "Here you can manage your athletes, coaches, customers, suppliers and other contacts.",
          SALUTATION_SIMPLE: "Salutation",
          DATE_OF_BIRTH: "Birthday",
          MOBILE:'Mobile',
          CONTACT_CATEGORY: "Contact Category",
          CATEGORY_NAME: "Category Name",
          CONTACT_CATEGORY_MSG: ": Here you can assign your contacts to categories, such as customer, supplier, athlete or coach",
          ADD_CATEGORY: "Add Category",
          MESSAGE_SIMPLE: "Message",
          LINK_SIMPLE: "Regarding",
          NEWS_MESSAGES_NOTE_MSG: "Here you can send messages by email or, if you have your own app, send them directly to your contacts' phones",
          NEWS_LETTERS_NOTE_MSG: "Here you can create a new newsletter and send it by email",
          NEWS_NOTE_MSG: "Here you can enter and manage news that appears on your website and in your app.",
          OUR_LOGO:"Our Logo",
          OUR_PHOTOS:"Our Photos",
          ORGANIZER_DATA: 'Oganizer Data',
          PRODUCT_CATEGORY: "Categories",
          WAREHOUSE_STATUS: "Stock",
          WAREHOUSE_DELIVERY: "Delivery",
          NEW_MESSAGES_SIMPLE: "News",
          NEWS_LETTER: "Newsletter",
          NEWS_SIMPLE: "News",
          settings: "Settings",
          legal:'Legal',
          legal_contact:'Contact',
          write_mail:'Write mail',
          dark_mode:'Dark Mode',
          emptyMessage: 'No data',
          emptyMessageCustomers:'No customers found.',
          emptyMessageProducts:'No products found.',
          askProceed: 'Are you sure you want to proceed?',
          customer:"Customer"
        }
      },
      de: {
        translation: {
          we: "Wir",
          events: "Events",
          ourEvents: "Unsere Events",
          registrations: "Anmeldungen",
          tickets: "Zuschauertickets",
          ealyBirdRebate: "Frühbucherrabatt",
          eventMap: "Maps",
          results: "Ergebnisse",
          about: "Über uns",
          coupons: "Gutscheine",
          websiteAndApp: "Website und App",
          settings: "Einstellungen",
          contacts: "Kontakte",
          templates: "Vorlagen",
          general: "Allgemein",
          question: "Frage",
          questions: "Fragen",
          trainingDates: "Unsere Termine",
          trainingGroups: "Trainingsgruppen",
          trainingPersonal: "Personal Training",
          trainingPlan: "Trainingspläne",
          ourProducts: "Unsere Produkte",
          sales: "Verkäufe",
          discount: "Rabatt",
          discounts: "Rabatte",
          shippingCountry: "Shipping country",
          shippingType:"Versandart",
          shippingTypes: "Versandarten",
          ourContacts: "Unsere Kontakte",
          categories: "Kontaktarten",
          appUser: "App Benutzer",
          news: "News",
          addTrainingGroup: "Trainingsgruppe hinzufügen",
          trainingGroup: "Trainingsgruppe",
          description: "Beschreibung",
          note: "Notiz",
          save: "Speichern",
          close: "Schließen",
          coupon: "Gutschein",
          participant: "Teilnehmer",
          trainer: "Trainer",
          category: "Kategorie",
          beginner: "Einsteiger",
          advanced: "Fortgeschritten",
          professional: "Profi",
          planName:"Planname",
          backToList: "Zurück zur Liste",
          unit: "Einheit",
          specificUnit: "Spezifische Einheit",
          enduranceUnit: "Ausdauer Einheit",
          powerUnit: "Kraft Einheit",
          week: "Woche",
          day: "Tag",
          training: "Training",
          units: "Einheiten",
          specificTraining: "Spezifisches Training",
          enduranceTraining: "Ausdauertraining",
          powerTraining: "Krafttraining",
          sportType: "Sportart",
          trainingType: "Trainingstyp",
          duration: "Dauer",
          selectPlease: "Bitte auswählen",
          hours: "Stunden",
          minutes: "Minuten",
          seconds: "Sekunden",
          crossduathlon: "Crossduathlon",
          duathlon: "Duathlon",
          inliner: "Inliner",
          running: "Laufen",
          mtb: "Mountainbike",
          cycling: "Rennrad",
          swimming: "Schwimmen",
          triathlon: "Triathlon",
          ski: "Skilanglauf",
          walking: "Wandern / Walking",
          crossrunning: "Crosslauf",
          obstaclerunning: "Hindernislauf",
          womenrunnung: "Frauenlauf",
          wheelchair: "Rollstuhl",
          enduranceTrainingGA1: "Ausdauertraining GA1",
          enduranceTrainingGA2: "Ausdauertraining GA2",
          drivingGame: "Fahrtspiel",
          intervals: "Intervalle",
          competition: "Wettkampf",
          tempoduration: "Tempodauer",
          tech: "Technik",
          coordination: "Koordination",
          strengthAndStabilisation: "Kraft- und Stabilisation",
          distance: "Distanz",
          muscleGroup: "Muskelgruppe",
          product: "Produkt",
          price: "Preis",
          currency: "Währung",
          language: "Sprache",
          addNewProduct: "Neues Produkt hinzufügen",
          notice: "Hinweis",
          productsNoticeContent: ": Hier könnt Ihr Produkte für Euren Online Shop anlegen, die auch bei einer Teilnehmer Anmeldung angeboten werden können.",
          salesNoticeContent: ": Hier seht Ihr alle Produktverkäufe. Ihr könnt hier Eure Verkäufe exportieren auch direkt einen Produktverkauf eingeben.",
          discountNoticeContent: ": Hier könnt Ihr Rabatte für Eure Produkte eingeben. In jedem Eurer Produkt könnt Ihr die Rabatte speziell für das Produkt anpassen.",
          shippingNoticeContent: ": Hier könnt Ihr Eure Versandarten verwalten.",
          couponsNoticeContent: ": Hier sehr Ihr alle Gutscheine, die gekauft wurden und ob sie schon eingelöst wurden. Ihr könnt hier auch einen neuen Gutschein erstellen.",
          eventNoticeContent:": Hier seht Ihr Eure zukünftigen Events, die auch in der App zu sehen sind.",
          editRegistrationsNoticeContent:': Hier könnt Ihr Eure Teilnehmer Anmeldungen eingeben, hochladen oder bearbeiten.',
          organizerMapsNoticeContent:': Hier könnt Ihr Eure Wettkampfstrecken für das GPS Tracking eingeben.',
          organizerResultsNoticeContent: ': Hier könnt Ihr Eure Ergebnisse hochladen oder bearbeiten.',
          trainingGroupsNoticeContent:': Hier kannst Du eine Trainingsgruppe oder eine Kursgruppe erstellen und verwalten.',
          templateNoticeContent:': Du kannst eine Vorlage unten auswählen oder Deine eigene Vorlage hochladen. Der Bereich in der Mitte muss hell sein damit die Schrift zu lesen ist, Abmessungen 4088 x 6320 Pixel',
          date: "Datum",
          usage: "Verwendungszweck",
          enterProductSale: "Produktverkauf eingeben",
          title: "Titel",
          amount: "Betrag",
          from: "Von",
          until: "Bis",
          allDay: "den ganzen Tag",
          addDiscountToProduct: "Rabatt hinzufügen für Produkte",
          newShippingType: "Neue Versandart eingeben",
          shopProducts: "Shop Produkte",
          addPhoto: "Produkt Foto hochladen",
          addNewsPhoto:'Foto hochladen',
          showProductInPhoto: "Produkt im Shop anzeigen",
          productSale: "Produktverkauf",
          recipient: "Empfänger",
          enterProduct: "Produkt eingeben",
          number: "Anzahl",
          firstName: "Vorname",
          surname: "Nachname",
          totalSales: "Gesamtumsätze",
          uploadLogo: "Logo hochladen",
          uploadPhoto: "Fotos hochladen",
          uploadSponsorLogo: "Sponsorenlogos hochladen",
          organizerName: "Veranstaltername",
          street: "Straße",
          houseNum: "Hausnr.",
          index: "Plz",
          area: "Ort",
          country: "Land",
          region: "Bundesland",
          value: "Wert",
          createCoupon: "Gutschein erstellen",
          linkToOurApp: "Link zu unserer App",
          copyPaste: "Copy & Paste",
          copyLink: "Link kopieren",
          customizeDesign: "App design anpassen",
          sponsorsInsteadOfAdvertising: "Sponsoren statt Werbung",
          websiteMessage: "Jetzt auf die Premium Version upgraden und Deine Sponsoren statt der Werbung einblenden.",
          moreInfo: "Mehr Infos",
          defaultCategory: "Default Kategorie",
          defaultSport: "Default Sportart",
          activatedPaymentOptions: "Aktivierte Bezahlmöglichkeiten",
          creditCard: "Kredikarte",
          sofortPayment: "Sofortüberweisung",
          another: "Anderer",
          priceOnInvoice: "Preise auf Rechnung",
          vat: "Mehrwertsteuerbetrag in Prozent",
          noteOnInvoice: "Hinweise auf Rechnung (drei Zeilen a 57 Zeichen maximal)",
          priceUsedGrossPrice: "Verwendete Preise sind Bruttopreise",
          questionPageText: ": Hier könnt Ihr die Vorlagen anpassen, z.B. das Design Eurer Gutscheine oder Urkunden anpassen. In jedem Eurer Events könnt Ihr die Vorlagen speziell für den Event anpassen.",
          enterQuestion: "Frage eingeben",
          designCampaign: "Rabattaktion Bezeichnung",
          discountFrom: "Rabattaktion von",
          applyAllProducts: "Gilt für alle Produkte",
          REGISTRATION_INSERT: "Anmeldung eingeben",
          PRODUCT_SALE_INSERT: "Produktverkauf eingeben",
          CONTACT_INSERT: "Kontakt eingeben", 
          EVENT_INSERT: "Event eingeben",
          TOTAL_SALES: "Gesamtumsätze",
          REVENUE_TOTAL: "Umsätze aus Produktverkauf",
          REGISTRATION_SALES: "Umsätze aus Anmeldungen",
          FILTER_DATE: "Nach Datum filtern",
          WEBSITE_NOTE_MSG: "Hier könnt Ihr eure Website designen und eure Url festlegen. Wenn ihr schon eine Website habt könnt ihr eure spooorts Seiten auch einfach auf eurer Website integrieren.",
          WEBSITE_OWN_URL: "Eigene Website Url",
          WEBSITE_PREMIUM_ADS_MSG: "Jetzt auf Premium upgraden und Deine Sponsoren statt der Werbung einblenden.",
          WEBSITE_PREMIUM_URL_MSG: "Jetzt auf Premium upgraden und Deine eigene kurze Url auswählen.",
          BTN_ORDER: "Bestellen",
          BTN_UPGRADE: "Jetzt Upgraden",
          WEBSITE_OBSERVE: "Website ansehen",
          WEBSITE_DESIGN: "Website designen",
          WEBSITE_INTEGRATE: "Website integrieren",
          WEBSITE_URL_COPY: "Url kopieren",
          PAYMENT_SIMPLE: "Bezahlung",
          DATE_FORMAT: "Datumsformat",
          NUMBER_FORMAT: "Zahlenformat",
          DISTANCE_FORMAT: "Längeneinheit",
          WEIGHT_FORMAT: "Gewichtseinheit",
          NOTE_MAX_STRING: "Hinweise auf Rechnung (drei Zeilen a 57 Zeichen maximal)",
          EDIT_TEMPLATE: "Template bearbeiten",
          NOTE_SALES_MSG: ": Hier könnt Ihr Rabatte für Eure Events eingeben. In jedem Eurer Events könnt Ihr die Rabatte speziell für den Event anpassen.",
          TRAINING_PLAN_ADD: "Traningsplan hinzufügen",
          PRODUCT_CATEGORY: "Produktkategorien",
          PRODUCT_NOTE_MSG: "Hier könnt Ihr Eure Produkt Kategorien verwalten.",
          ADD_CATEGORY: "Kategorie hinzufügen",
          DELETE_CATEGORY:'Kategorie löschen',
          ALL_CATEGORIES: "Alle Kategorien",
          CATEGORY_BY: "Kategorie auf",
          EBENE_SIMPLE: "Ebene",
          LOCKED_SIMPLE: "Gesperrt",
          NOTE_WAREHOUSE_MSG: "Hier könnt Ihr Eure Lagerbestände verwalten.",
          WAREHOUSE_SIMPLE: "Lager",
          WAREHOUSE_STATUS: "Lagerbestand",
          WAREHOUSE_DELIVERY: "Lieferung",
          ORDERED_AT: "Bestellt am",
          DELIVERY_DATE: "Lieferdatum",
          NOTE_WAREHOUSE_DELIVERY_MSG: ": Hier könnt Ihr die Lieferungen Eurer Lagerbestände verwalten.",
          MESSAGE_SIMPLE: "Nachricht",
          NEW_MESSAGES_SIMPLE: "Nachrichten",
          NEWS_LETTER: "Newsletter",
          NEWS_SIMPLE: "News",
          NEWS_MESSAGES_NOTE_MSG: "Hier könnt Ihr Nachrichten per Mail versenden oder wenn Ihr eine eigene App habt auch direkt auf die Handys Eurer Kontakte senden",
          NEWS_LETTERS_NOTE_MSG: "Hier könnt Ihr einen neuen Newsletter erstellen und per Mail versenden",
          NEWS_NOTE_MSG: "Hier könnt Ihr News eingeben und verwalten, die auf eurer Website und in eurer App erscheinen.",
          CONTACT_OUR: "Hier könnt Ihr Eure Sportler, Trainer, Kunden, Lieferanten und andere Kontakte verwalten.",
          CONTACT_CATEGORY_MSG: ": Hier könnt Ihr Eure Kontakte Kategorien, wie z.B. Kunde, Lieferant, Sportler oder Trainer zuordnen",
          SALUTATION_SIMPLE: "Anrede",
          DATE_OF_BIRTH: "Geburtstag",
          PHONE_NUMBER: "Telefon",
          CATEGORY_NAME: "Kategorie Name",
          LINK_SIMPLE: "Betreff",
          LOGIN1_SIMPLE: "Anmeldung",
          LOGIN2_SIMPLE: "Anmelden",
          REGISTER_SIMPLE: "Neu Registrieren",
          FORGOT_PASSWORD: "Passwort vergessen",
          FORGOT_QSTN_MSG: "Bist Du neu hier?",
          CONTACT_CATEGORY: "Kontakt Kategorien",
          ERROR_VALUE: "Fehler",
          ROUTENAME_EMPTY_ERROR_MESSAGE: "Bitte eine Routenbezeichnung angeben",
          NEW_DIALOG_MESSAGE: "Du kannst eine Route neu erfassen durch Eingabe des Startorts und Klicken auf 'Los'.Dann entlang der Strecke vom Start zum Ziel die Wegpunkte durch Klicken auf die Karte erfassen.",
          NEW_DIALOG_SPOOORTS_MESSAGE: "Du kannst eine Route neu erfassen durch Eingabe des Startorts und Klicken auf 'Aufzeichnung starten'.Dann entlang der Strecke vom Start zum Ziel die Wegpunkte durch Klicken auf die Karte erfassen.Wenn Du wieder auf einen schon gesetzten Marker klickst kannst Du die Anzahl der Runden eingeben.",
          INSERT_ROUTE: "Route Eingeben",
          STARTING_LOCATION: "Startort",
          FINISH_LOCATION: "Zielort",
          IMPORT_ROUTE: "Route importieren",
          ROUND_COUNT: "Anzahl Runde",
          ROUTE_NAME: "Routen Bezeichnung",
          DELETE: "Löschen",
          ELEVATION_PROFILE: "Höhenprofil",
          DELETE_LAST_POINT: "Letzten Punkt löschen",
          EDIT_ROUTE: "Route bearbeiten",
          CLOSE: "Schliesen",
          START_RECORDING: "Aufzeichnung Starten",
          TRACKING_ICON: "Tracking",
          INFO_ICON: "Info",
          PARK_ICON: "Parken",
          SHOWER_ICON: "Dusche",
          COFFEE_ICON: "Verpflegung",
          HOTEL_ICON: "Hotel",
          NEW_PLAN: "Neu Planen",
          GO_SIMPLE: "Los",
          SAVE_SUCCESS: "Speichern erfolgreich",
          NOT_SAVED_MESSAGE: "Die Route ist noch nicht gespeichert",
          ERROR_DEFAULT: "Bitte versuche es später noch einmal",
          IMPORT_GPX: "+ Gpx Datei-Import",
          IMPRINT_SIMPLE: "Impressum",
          NEW_ROUTE: "Neue Strecke",
          DELETE_ALL_PHOTOS: "Alle Fotos löschen",
          // NO_INTERNET_MESSAGE: "Für den vollen Funktionsumfang stellen Sie bitte eine WLAN-Verbindung her"
          NO_INTERNET_MESSAGE:"Please connect to Wi-Fi for full functionality",
          edit_coupon:'Gutschein bearbeiten',
          answer_option:'Antwortmöglichkeit',
          required_question:'Die Frage muss beantwortet werden',
          optional_question:'Die Antworten werden nicht vorgegeben',
          online_registration_question:'Fragen zur Online Anmeldung stellen',
          questions_about_registration:'Fragen zur Anmeldung',
          our_logo:'Unser Logo',
          photos:'Fotos',
          sponsors:'Sponsoren',
          shop_categories:'Produkt Kategorien',
          product_sales:'Productverkäufe',
          deliveries:'Lieferungen',
          edit_shipping_method:'Versandart bearbeiten',
          products:'Produkte',
          designation: 'Bezeichnung',
          oredered_from_supp:'Beim Lieferanten bestellt',
          delivery_received: 'Lieferung erhalten',
          notes:'Anmerkungen',
          german: 'Deutsch',
          english:'Englisch',
          evaluation: 'Wertung',
          event_registration:'Anmeldungen bearbeiten',
          activate_participant_registration:'Teilnehmeranmeldung freischalten',
          unlock_spectator_ticketing:'Zuschauerticketing freischalten',
          private_event: 'Privater event',
          date_unconfirmed:'Termin unbestätight',
          event_cancelled:'Event abgesagt',
          organizer_page:'Veranstalterseite',
          type:'Typ',
          date_time:'Datum/Uhrzeit',
          participant_price:'Teilnehmer Preis',
          distance_km:'Strecke in km',
          more_info_about_event:'Weitere Infos zu diesem Event',
          more_info:'Weitere Infos',
          more_info_about_entire_event:'Weitere Infos zur Gesamtveranstaltung',
          tender:'Ausschreibung',
          route:'Strecke',
          edit_event:'Event bearbeiten',
          award_ceremony_at:'Siegerehrung um',
          late_registrations: 'Nachmeldung bis',
          completely_flat:'komplett flach',
          route_profile:'Streckenprofil',
          mostly_flat:'größtenteils flach',
          hilly:'hügelig',
          mountainous: 'bergig',
          underground:'Untergrund',
          asphalt_only:'Nur Asphalt',
          forest_dirt:'Wald/Feldwege',
          mixed:'Gemischt',
          distinction: 'Auszeichnung',
          medal: 'Medaille',
          certificate: 'Urkunde',
          gift:'Geschenk',
          winner: 'Sieger',
          cup:'Pokal', 
          non_cash:'Sachpreis',
          cash:'Geldpreis',
          along_the_route:'an der Strecke',
          free_at_the_finish:'im Ziel kostenlos',
          warm_food:'warmes Essen',
          cake:'Kuchen',
          other:'Sonstiges',
          shower:'Duschen',
          changing_rooms:'Umkleiden',
          wc:'WCs',
          contact:'Ansprechpartner',
          enter_next_event:'Nächsten Termin eintragen',
          general_event_dates:'Allgeimeine Eventdaten',
          add_sport_type:'Sportart hinzufügen',
          voucher_recipient:'Gutschein Empfänger',
          IBAN:'Iban (Eure Bankverbindung, der Eure Auszahlungen gutgeschrieben werden soll)',
          upload_own_template:'Eigene Vorlage hochladen',
          apply_changes:'Änderungen übernehmen',
          new_event:'Neuen Event eintragen',
          attributes:'Attribute',
          addDiscount:'Frühbucherrabatt hinzufügen',
          add_cotact: 'Kontakt hinzufügen',
          edit_warehouse:'Lager bearbeiten',
          add_warehouse:'Neues Lager hinzufügen',
          add_delivery: 'Lieferung hinzufügen',
          new_contact:'Neuen Kontakt eingeben',
          new_message:'Neue Nachricht',
          send_as_mail:'Als Mail versenden',
          send_to_mobile:"Auf's Handy senden",
          add_news:'News hizufügen',
          training_dates:'Trainingstermine',
          add_product:'Product eintragen',
          enter_shippingMethod:'Versandart eingeben',
          send_to_all_contacts:'An alle Kontakte versenden',
          DATE_OF: 'Datum von',
          DATE_TO:'Datum bis',
          SETTINGS_QUESTION_NOTICE:': Hier könnt Ihr die Fragen eingeben, die Ihr dem Teilnehmer bei einer Online Anmeldung stellen möchtet. In jedem Eurer Events könnt Ihr die Fragen speziell für den Event anpassen.',
          first_level_categories:'Kategorien auf 1.Ebene',
          second_level_categories:'Kategorien auf 2.Ebene',
          third_level_categories:'Kategorien auf 3.Ebene',
          forth_level_categories:'Kategorien auf 4.Ebene',
          fifth_level_categories:'Kategorien auf 5.Ebene',
          MOBILE:'Mobil',
          OUR_LOGO:'Unser Logo',
          OUR_PHOTOS:'Unsere Fotos',
          ORGANIZER_DATA:'Veranstalterdaten',
          legal:'Rechtliches',
          legal_contact:'Kontakt',
          write_mail:'Mail verfassen',
          dark_mode:'Dunkler Modus',
          emptyMessage: 'Keine Daten',
          emptyMessageCustomers:'Keine Kunden gefunden.',
          emptyMessageProducts:'Keine Produkte gefunden.',
          askProceed: 'Möchten Sie wirklich fortfahren?',
          customer:"Kunde"

        }
      }
    }
  });

export default i18n;