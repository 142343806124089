import axios from 'axios';
import * as XLSX from 'xlsx';
import AuthService from './auth.service';
import DomainService from './domain';

const NUMBER_OF_COLUMNS = 37;

export default class ContactService {

    authService = new AuthService();
    domainService = new DomainService();

    getContactCategories(token) {
        return axios.get(this.domainService.getActiveDomain() + `admin/user/${token}/contacts/categories`).then(res => res.data).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        })
    }

    handleContactImport(event) {
        const file = event.target.files[0];
        console.log(file);
        if (!file) {
            // No file selected
            return;
        }
        let that = this;
        if (file.name.includes("csv")) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const fileContent = e.target.result;
                if (!fileContent || !fileContent.split('\n')[0] || !fileContent.split('\n')[0].split(';').length == NUMBER_OF_COLUMNS) return "The number of columns is not correct";
                this.sendCsvFile(file.name, fileContent);
                return "ok";
            };
            fileReader.readAsText(file);
        }else if(file.name.includes("xlsx")){
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
                var workbook = XLSX.read(data);
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(sheet, {header: 1});
                const keyNames = jsonData[1];
                let result = [];
                if(jsonData[1].length != NUMBER_OF_COLUMNS) return "The number of columns is not correct";

                for (let i = 2; i < jsonData.length; i++) {
                    const obj = {};
                    const values = jsonData[i];
                    if (values.length > 0) {
                        for (let j = 0; j < keyNames.length; j++) {
                            obj[keyNames[j]] = values[j] || null;
                        }
                        result.push(obj);
                    }
                }

                that.sendContact(result);
                return "ok";
                /* DO SOMETHING WITH workbook HERE */
            };
            reader.readAsArrayBuffer(file);
        }
    }

    sendContact(jsonData) {
        fetch(this.domainService.getActiveDomain() + `contacts/upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
            .then(data => {
                if (!data.ok) {
                    data.text().then(err => {
                        alert(err);
                    });
                }
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }

    sendCsvFile(fileName, fileContent) {
        const formData = new FormData();
        formData.append('file', new Blob([fileContent], { type: 'text/csv' }));
        formData.append('fileName', fileName);

        fetch(this.domainService.getActiveDomain() + `contacts/upload/file`, {
            method: 'POST',
            body: formData,
        })
            .then(data => {
                if(!data.ok){
                    data.text().then(err => {
                        alert(err);
                    });
                }
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }

    saveContactCat(contactCatDto, token) {
        fetch(this.domainService.getActiveDomain() + `admin/user/${token}/contact/category/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(contactCatDto)
        }).then((res) => {
            console.log('ok')
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    updateContactCat(contactCatDto, token) {
        fetch(this.domainService.getActiveDomain() + `admin/user/${token}/contact/category/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(contactCatDto)
        }).then((res) => {
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    removeContactCat(id, token) {
        return axios.post(this.domainService.getActiveDomain() + `admin/user/${token}/contact/category/${id}/delete`).then(res => res.data).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        })
    }
}
