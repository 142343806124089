import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import ShopService from '../../service/shop.service';
import countries from '../../assets/static/countries.json';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import AuthService from '../../service/auth.service';
import * as uuid from 'uuid';
import { storeShop } from '../../stores/shop.store';
import { useLocation } from 'react-router-dom';

const ShopDelivery = (props) => {
    const location = useLocation();
    const [shippingModes, setShippingModes] = useState([]);
    const [tableShippings, setTableShippings] = useState([]);
    const [dialogDeliveryForm, setDialogDeliveryForm] = useState(null);

    const [dialogEvent, setDialogEvent] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [searchField, setSearchField] = useState("");
    const [dialogClosed, setDialogClosed]=useState(false);

    // 0 - save, 1 - update
    const [actionDialog, setActionDialog] = useState(0);
    const currencies = [
        { label: "EUR", value: "EUR" },
        { label: "USD", value: "USD" }
    ];
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const shopService = new ShopService();
    const authService = new AuthService();

    let newDelivery = {
        lang: "DE",
        shippingPrice: 0.0,
        uuid: "",
        isoCc: "DE",
        shippingTitle: "",
        shippingPriceCurrency: "EUR",
        shippingDescription: "",
        lid: -1
    }

    useEffect(() => {
        setIsMobile(window.innerWidth < 800);
        setDialogDeliveryForm(newDelivery);
        // fetchShippings();
        storeShop.subscribe(() => {
            switch(storeShop.getState()){
                case 0: search(); break;
                case 1: addShipping(); break;
                default: break;
            }
        })
    }, [])
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const fetchShippings = () => {
        let token = authService.getToken();
        shopService.getDeliveryModes(token).then(modes => {
            modes.forEach(mode => {
                mode.country = countries.find(country => country.key == mode.isoCc).name;
                mode.edit = (
                    <Button type="button" icon="pi pi-pencil" onClick={() => editMode(mode)}></Button>
                );
                mode.remove = (
                    <Button type="button" icon="pi pi-trash" onClick={() => removeMode(mode)}></Button>
                )
            });
            setShippingModes(modes);
            setTableShippings(modes);
        });
    }

    const editMode = (mode) => {
        setDialogDeliveryForm(mode);
        setDialogEvent(true);
        setActionDialog(1);
    }
    const removeMode = (mode) => {
        let token = authService.getToken();
        shopService.removeShipping(mode.uuid, token);
        let modes = shippingModes.filter(f => {
            return f.uuid != mode.uuid
        });
        setShippingModes(modes);
        setTableShippings(modes);
    }
    const search = () => {
        let arr = shippingModes.filter(s => s.shippingTitle.includes(searchField));
        setTableShippings(arr);
    }
    const closeDialog=()=>{
        setDialogEvent(false);
        window.windowCheck();
    }
    const saveShipping = () => {
        let token = authService.getToken();
        if(actionDialog == 0){
            newDelivery.uuid =  uuid.v4();
            newDelivery.edit = (
                <Button type="button" icon="pi pi-pencil" onClick={() => editMode(newDelivery)}></Button>
            );
            newDelivery.remove = (
                <Button type="button" icon="pi pi-trash" onClick={() => removeMode(newDelivery)}></Button>
            );
            let modesArr = shippingModes;
            modesArr.push(newDelivery);
            setShippingModes(modesArr);
            setTableShippings(modesArr);
            setDialogEvent(false);
            shopService.saveShipping(newDelivery, token);
        }else if(actionDialog == 1){
            setDialogEvent(false);
            let arr = shippingModes;
            arr.forEach(a => {
                if (a.uuid == dialogDeliveryForm.uuid){
                    a = newDelivery;
                    a.uuid = dialogDeliveryForm.uuid;
                    a.edit = dialogDeliveryForm.edit;
                    a.remove = dialogDeliveryForm.remove;
                }
            });
            setShippingModes(arr);
            arr = tableShippings;
            for(let i = 0; i < arr.length; i++){
                if (arr[i].uuid == dialogDeliveryForm.uuid){
                    arr[i] = newDelivery;
                    arr[i].uuid = dialogDeliveryForm.uuid;
                    arr[i].edit = dialogDeliveryForm.edit;
                    arr[i].remove = dialogDeliveryForm.remove;
                }
            }
            setTableShippings(arr);
            newDelivery = dialogDeliveryForm.uuid;
            shopService.updateShipping(newDelivery, token);
        }
        setDialogClosed(true)
    }

    const addShipping = () => {
        // if(!authService.isLoged()) { history.push("/login"); return; }

        newDelivery = {
            lang: "DE",
            shippingPrice: 0.0,
            uuid: "",
            isoCc: "DE",
            shippingTitle: "",
            shippingPriceCurrency: "EUR",
            shippingDescription: "",
            lid: -1
        };
        setDialogDeliveryForm(newDelivery);
        setActionDialog(0); 
        setDialogEvent(true);
    }
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil"  className="mr-3" onClick={() => null}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}></Button>
        </div>
    )
    const dialogFooter = (
        <div style={styles.footer} className='mt-20 p-flex-group g-10 buttons'>
            <Button type="button" label={t('save')} onClick={saveShipping} />
            <Button type="button" className='p-button-outlined' onClick={() => closeDialog()} label={t('close')} />
        </div>
    );

    const dialog = (
        <Dialog className='dialog-container' visible={dialogEvent} header={t('edit_shipping_method')} onHide={() => closeDialog()}>
            <div class="p-flex-group align-end">
                <div className="group-block w-49">
                    <label>{t('shippingTypes')}</label>
                    <InputText placeholder={dialogDeliveryForm?.shippingTitle} onChange={(e) => newDelivery.shippingTitle = e.target.value} />
                </div>
                <div className="group-block w-49">
                    <Dropdown placeholder={dialogDeliveryForm?.isoCc} onChange={(e) => newDelivery.isoCc = e.target.value} />
                </div>
            </div>
            <hr />
            <div class="p-flex-group">
                <div className="group-block w-49">
                    <label>{t('price')}</label>
                    <InputText placeholder={dialogDeliveryForm?.shippingPrice} onChange={(e) => newDelivery.shippingPrice = e.target.value} />
                </div>
                <div className="group-block w-49">
                    <label>{t('currency')}</label>
                    <Dropdown options={currencies} value={dialogDeliveryForm?.shippingPriceCurrency} onChange={(e) => newDelivery.shippingPriceCurrency = e.value} />
                </div>
            </div>
            <hr />
            <div className="p-flex-group">
                <div className="group-block w-100 pt-30">
                    <label>{t('description')}</label>
                    <InputTextarea className="h-100-fixed" placeholder={dialogDeliveryForm?.shippingDescription} onChange={(e) => newDelivery.shippingDescription = e.target.value}></InputTextarea>
                </div>
            </div>
            {dialogFooter}
        </Dialog>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('shippingNoticeContent')}</p>
            {tableShippings.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={addShipping} className="p-button-outlined"
                    tooltip={t('newShippingType')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {tableShippings.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={addShipping} className="p-button-outlined" 
                    tooltip={t('newShippingType')} tooltipOptions={{position:'right'}} />
                </div>
            )}
        </div>
    );

    return (
        <div>
            {dialog}
            <div className='card' style={styles.table}>
                <DataTable value={tableShippings} responsiveLayout="scroll" header={header} paginator rows={10} selectionMode="single" rowSelection onSelectionChange={e => props.setSelected(e.value)} emptyMessage={t('emptyMessage')}>
                    <Column field="shippingTitle" header={t('shippingType')} sortable></Column>
                    <Column field="shippingPrice" header={t('price')} sortable></Column>
                    <Column field="shippingPriceCurrency" header={t('currency')} sortable></Column>
                    <Column field="country" header={t('shippingCountry')} sortable></Column>
                    <Column field="edit"></Column>
                </DataTable>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopDelivery, comparisonFn);
