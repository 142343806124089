import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import ShopProductDialog from './shop-product-dialog';
import { useLocation } from 'react-router-dom';

const ShopWarehouseDelivery = () => {
    const [trainingPlans, setTrainingPlans] = useState([]);
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }
    const status=[
        t('oredered_from_supp'),
        t('delivery_received')

    ];
    let emptyDeliveriesGroup={
        designation:'',
        status:'',
        unit:'',
        until:''
    }
    let emptyWarehouseGroup = {
        warehouse:'',
        description:''
    }
    const location = useLocation();
    const [activeDialogTabIndex, setActiveDialogTabIndex] = useState(0);
    const [newProductDialogVisible, setNewProductDialogVisible] = useState(false);
    const [deliveriesGroups, setDeliveriesGroups] = useState([]);
    const [deliveriesGroup, setDeliveriesGroup] = useState(emptyDeliveriesGroup)
    const [openProductDialog, setOpenProductDialog]=useState(false);
    const [editWarehouseDialogVisible, setEditWarehouseDialogVisible]=useState(false);
    const [warehouseGroups, setWarehouseGroups] = useState([]);
    const [warehouseGroup, setWarehouseGroup] = useState(emptyWarehouseGroup);
    const [searchField, setSearchField]=useState('');
    const[dialogClosed, setDialogClosed]=useState(false);
    const [products, setProducts]=useState([]);

    useEffect(()=>{
        if(newProductDialogVisible ){
            if(activeDialogTabIndex == 1) window.windowCheck();
            console.log( activeDialogTabIndex == 1)
        }
    }, [newProductDialogVisible, activeDialogTabIndex])

    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const saveDeliveries=()=>{
        setNewProductDialogVisible(false);
        let _deliveriesGroups = [...deliveriesGroups];
        _deliveriesGroups.push(deliveriesGroup);
        setDeliveriesGroups(_deliveriesGroups);
        setDeliveriesGroup(emptyDeliveriesGroup);
        setDialogClosed(true)
    }
    const closeDeliveries =()=>{
        setNewProductDialogVisible(false);
        setDialogClosed(true)
    }
    const formatDate = (date) => {
        const currentdate = new Date (date)
        const day = String(currentdate.getDate()).padStart(2, '0');
        const month = String(currentdate.getMonth() + 1).padStart(2, '0')
        const year = currentdate.getFullYear();
        return `${day}/${month}/${year}`
    };
    const openShopProductDialog=()=>{
        setOpenProductDialog(true)
    }
    const saveWarehouse =()=>{
        setEditWarehouseDialogVisible(false);
        setActiveDialogTabIndex(1)
        let _warehouseGroups = [...warehouseGroups];
        _warehouseGroups.push(warehouseGroup);
        setWarehouseGroups(_warehouseGroups);
        setWarehouseGroup(emptyWarehouseGroup);
        setDialogClosed(true)
    }
    const closeWarehouse=()=>{
        setActiveDialogTabIndex(1)
        setEditWarehouseDialogVisible(false);
        setDialogClosed(true)
    }
    const openeWarehouse=()=>{
        setEditWarehouseDialogVisible(true);
    }
    const saveNewProduct = () => {}
    const search =()=>{}

    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('NOTE_WAREHOUSE_DELIVERY_MSG')}</p>
            {deliveriesGroups.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={() => {setNewProductDialogVisible(true)}}  className="p-button-outlined" 
                        tooltip={t('add_delivery')} tooltipOptions={{position:'right'}}/>
                </div>
            )}
            {deliveriesGroups.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={() => {setNewProductDialogVisible(true)}}  className="p-button-outlined"
                     tooltip={t('add_delivery')} tooltipOptions={{position:'right'}}/>
                </div>
            )}
        </div>
    );
    const productHeader=(
        <div className="table-header-container">
            {products.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={openeWarehouse} className="p-button-outlined"
                    tooltip={t('add_warehouse')} tooltipOptions={{position:'top'}} />
                    <Button icon="pi pi-shopping-bag" onClick={openShopProductDialog} className="p-button-outlined"
                    tooltip={t('addNewProduct')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {products.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={openeWarehouse} className="p-button-outlined" 
                    tooltip={t('add_warehouse')} tooltipOptions={{position:'top'}}/>
                    <Button icon="pi pi-shopping-bag" onClick={openShopProductDialog} className="p-button-outlined"
                    tooltip={t('addNewProduct')} tooltipOptions={{position:'top'}}  />
                </div>
            )}
        </div>
    )

    const footer = (
        <div style={styles.footer}>
            <Button type="button" style={styles.button} label={t('addDiscountToProduct')} onClick={null} />
        </div>
    );
    const deliveriesFooter=(
        <div className="flex g-10 buttons">
            <Button type="button" label={t('save')} onClick={() => saveDeliveries()} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => closeDeliveries()} />
        </div>
    );
    const footerEditWarehouse=(
        <div className="flex g-10 buttons">
            <Button type="button" label={t('save')} onClick={() => saveWarehouse()} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => closeWarehouse()} />
        </div>
    );
    const productDialog=(
        <Dialog visible={openProductDialog} header={t('WAREHOUSE_SIMPLE')} onHide={() => {setOpenProductDialog(false)}} style={{ maxWidth: '850px'}} position="top" modal>
            <ShopProductDialog/>
            <div className="flex g-10 buttons">
                <Button type="button" label={t('save')} onClick={() => saveNewProduct()} />
                <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => { setActiveDialogTabIndex(0); setNewProductDialogVisible(false)}} />
            </div>
        </Dialog>
    )
    const dialog=(
        <Dialog visible={newProductDialogVisible} header={t('WAREHOUSE_SIMPLE')} onHide={() => {setActiveDialogTabIndex(0);setNewProductDialogVisible(false)}} style={{ maxWidth: '850px' }} position="center" modal>
            <TabView activeIndex={activeDialogTabIndex} onTabChange={(e) => setActiveDialogTabIndex(e.index)}>
                <TabPanel header={t('deliveries')}>
                    <div className='p-fluid'>
                        <div className='field'>
                            <label htmlFor='designation'>{t('designation')}</label>
                            <InputText id='designation' value={deliveriesGroup?.designation} onChange={(e) => setDeliveriesGroup({ ...deliveriesGroup, ...{ designation: e.target.value } })} required autoFocus/>
                        </div>
                        <hr/>
                        <div class="p-flex-group">
                            <div className='group-block w-49'>
                                <label htmlFor='unit'>{t('ORDERED_AT')}</label>
                                <Calendar id='unit' value={deliveriesGroup?.unit} onChange={(e) => setDeliveriesGroup({ ...deliveriesGroup, unit: formatDate(e.target.value) })} showIcon />
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='until'>{t('DELIVERY_DATE')}</label>
                                <Calendar id='until' value={deliveriesGroup?.until} onChange={(e) => setDeliveriesGroup({ ...deliveriesGroup, until: formatDate(e.target.value) })} showIcon />
                            </div>
                        </div>
                        <hr/>
                        <div className='field'>
                            <label htmlFor='status'>Status</label>
                            <Dropdown id='status' placeholder={t('oredered_from_supp')} options={status} onChange={e => setDeliveriesGroup(prev => ({
                                    ...prev, 
                                    status: e.value
                                }))} />
                        </div>
                        <hr/>
                        <div className='field'>
                            <label htmlFor="notes">{t('notes')}</label>
                            <InputTextarea id="notes"  alue={deliveriesGroup?.notes} onChange={(e) => setDeliveriesGroup({ ...deliveriesGroup, ...{ notes: e.target.value } })} />
                        </div>
                        <hr/>
                        <div className="flex g-10 buttons">
                            <Button type="button" label={t('save')} onClick={() => saveDeliveries()} />
                            <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => closeDeliveries()} />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header={t('products')}>
                    <div className='card' style={styles.table}>
                        <DataTable value={null} responsiveLayout="scroll" header={productHeader} paginator rows={10}  emptyMessage={t('emptyMessage')}>
                            <Column field="title" header={t('product')} sortable></Column>
                            <Column field="price" header={t('price')} sortable></Column>
                            <Column field="curr" header={t('currency')} sortable></Column>
                            <Column field="lang" header={t('language')} sortable></Column>
                            <Column field='edit' body={editTemplate}></Column>
                        </DataTable>
                    </div>
                    {productDialog}
                </TabPanel>
            </TabView>
        </Dialog>
    );
    const addWarehouseDialog = (
        <Dialog visible={!!editWarehouseDialogVisible} style={{ 'maxWidth': '750px' }} header={t('WAREHOUSE_SIMPLE')} footer={footerEditWarehouse} modal closable onHide={() =>{setActiveDialogTabIndex(1); setEditWarehouseDialogVisible(false)}}>
            <div className="p-fluid">
                <div className='field'>
                    <label htmlFor='warehouse'>{t('WAREHOUSE_SIMPLE')}</label>
                    <InputText  id='warehouse' value={warehouseGroup?.warehouse} onChange={(e) => setWarehouseGroup({ ...warehouseGroup, ...{ warehouse: e.target.value } })} required autoFocus/>
                </div>
                <div className="field mt-10">
                    <label htmlFor="description">{t('description')}</label>
                    <InputTextarea id="description"  value={warehouseGroup?.description} onChange={(e) => setWarehouseGroup({ ...warehouseGroup, ...{ description: e.target.value } })} />
                </div>
            </div>
        </Dialog>
    );

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={deliveriesGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="designation" header={t('title')} sortable></Column>
                    <Column field="status" header="Status" sortable></Column>
                    <Column field="unit" header={t('ORDERED_AT')} sortable></Column>
                    <Column field="until" header={t('DELIVERY_DATE')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialog}
            {addWarehouseDialog }
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopWarehouseDelivery, comparisonFn);
