import axios from 'axios';
import DomainService from './domain';

export default class EventService {
    domainService = new DomainService();

    getEvents() {
        return axios.get('assets/demo/data/events.json').then(res => res.data.data);
    }

    getCategories(lang){
        return new Promise((resolve, reject) => {
            fetch(this.domainService.getActiveDomain() + `route/categories/${lang}`)
            .then(res => res.json())
            .then(results => {
                if(results.error){
                    resolve([]);
                }
                resolve(results);
            })
            .catch(err => {
                resolve([]);
            })
        });
    }
}
