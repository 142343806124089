import axios from 'axios';

export default class TrainingService {


    getTrainingGroups() {
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.groups);
    }

    getTrainingPlans() {
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.plans);
    }

    getPlanLevels(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.levels);
    }

    getPlanWeeks(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.week);
    }

    getPlanDays(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.day);
    }

    getDurationUnits(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.durationUnits)
    }

    getSportTypes(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.sportTypes)
    }

    getTrainingTypes(){
        return axios.get('assets/demo/data/trainings.json').then(res => res.data.trainingPlan.trainingTypes);
    }
}
