import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';

const TrainingPlan = (props) => {
    
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [searchField, setSearchField]=useState('');
    const location = useLocation();
    const [dialogClosed, setDialogClosed]=useState(false)
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        }
    }

    const trainingService = new TrainingService();

    useEffect(() => {
        trainingService.getTrainingPlans().then(plans => setTrainingPlans(plans));
    }, [])

    useEffect(() => {
        const handleWindowCheck = () => {
            setTimeout(() => {
                window.windowCheck();
            }, 100);
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);
    
    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate');
    }
    const search=()=>{}

    const footer = (
        <>
            <Button type="button" label={t('TRAINING_PLAN_ADD')} className="uppercase" onClick={planCreateRedirect} />
        </>
    );

    const header = (
        <>
        {trainingPlans.length!==0 &&(
            <div className="table-header-container flex-center">
                <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                <Button icon="pi pi-search" onClick={search} className="p-button-outlined"/>
                <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined"
                tooltip={t('addTrainingGroup')} tooltipOptions={{position:'top'}} />
            </div>
        )}
        {trainingPlans.length==0 &&(
            <div className="table-header-container flex-center">
                <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined"
                tooltip={t('addTrainingGroup')} tooltipOptions={{position:'top'}} />
            </div>
        )}
        </>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={trainingPlans} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="trainer" header={t('trainer')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TrainingPlan, comparisonFn);
