import axios from 'axios';
import * as XLSX from 'xlsx';
import AuthService from './auth.service';
import DomainService from './domain';
const NUMBER_OF_COLUMNS = 9;

export default class ShopService {
    authService = new AuthService();
    domainService = new DomainService();

    getDeliveryModes(token) {
        return axios.get(this.domainService.getActiveDomain() + `admin/user/${token}/shippings`).then(res => res.data).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        })
    }
    
    handleShopImport(event, token) {
        const file = event.target.files[0];
        if (!file) {
            // No file selected
            return;
        }
        let that = this;
        if (file.name.includes("csv")) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const fileContent = e.target.result;
                if (!fileContent || !fileContent.split('\n')[0] || !fileContent.split('\n')[0].split(';').length == NUMBER_OF_COLUMNS) return "The number of columns is not correct";
                this.saveCsvShops(file.name, fileContent, token);
                return "ok";
            };
            fileReader.readAsText(file);
        } else if (file.name.includes("xlsx")) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
                var workbook = XLSX.read(data);
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const keyNames = jsonData[1];
                let result = [];
                if (jsonData[1].length != NUMBER_OF_COLUMNS) return "The number of columns is not correct";

                for (let i = 2; i < jsonData.length; i++) {
                    const obj = {};
                    const values = jsonData[i];
                    if (values.length > 0) {
                        for (let j = 0; j < keyNames.length; j++) {
                            obj[keyNames[j]] = values[j] || null;
                        }
                        result.push(obj);
                    }
                }

                that.saveProducts(result, token);
                return "ok";
                /* DO SOMETHING WITH workbook HERE */
            };
            reader.readAsArrayBuffer(file);
        }
    }

    saveCsvShops(fileName, fileContent, token) {
        const formData = new FormData();
        formData.append('file', new Blob([fileContent], { type: 'text/csv' }));
        formData.append('fileName', fileName);

        fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shop/products/upload/csv"`, {
            method: 'POST',
            body: formData,
        })
            .then(data => {
                if (!data.ok) {
                    data.text().then(err => {
                        alert(err);
                    });
                }
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }

    saveProducts(shopDto, token = null) {
        return fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shop/products/save`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify([shopDto]),
        })
            .then(data => {
                if (!data.ok) {
                    data.text().then(err => {
                        alert(err);
                    });
                }
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }


    saveShipping(shippingDto, token){
        return fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shipping/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(shippingDto)
        }).then((res) => {
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    updateShipping(shippingDto, token){
        fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shipping/update`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(shippingDto)
        }).then((res) => {
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    removeShipping(id, token){
        return axios.post(this.domainService.getActiveDomain() + `admin/user/${token}/shipping/delete/${id}`).then(res => res.data).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        })
    } 

    getProducts(token){
        return fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shop/products`).then(res => res.json()).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    getProductsByLid(lid) {
        return fetch(this.domainService.getActiveDomain() + `admin/user/${lid}/shop/products/bylid`).then(res => res.json()).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    getShopCategories(token) {
        return fetch(this.domainService.getActiveDomain() + `admin/user/${token}/shop/categories`).then(res => res.json()).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
            }
            return null;
        });
    }
}
