import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar as PRCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import EventService from '../../service/EventService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import ShopDiscountsNew from './shop-discounts-new';
import { useLocation } from 'react-router-dom';

const ShopDiscounts = () => {
    const location = useLocation();
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [discountGroups, setDiscountGroups]=useState([]);
    const [discountsNewDialogVisible, setDiscountsNewDialogVisible]=useState(false);
    const [searchField, setSearchField]=useState('');
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const trainingService = new TrainingService();

    useEffect(() => {
        trainingService.getTrainingPlans().then(plans => setTrainingPlans(plans));
    }, [])

    useEffect(() => {
    });
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    const planCreateRedirect = () => {
        // history.push('/shopDiscountCreate');
        setDiscountsNewDialogVisible(true)
    }
    const close =()=>{
        setDiscountsNewDialogVisible(false) ;
        window.windowCheck();
    }

    const addNewDiscount = () => {}
    const search=()=>{}

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('discountNoticeContent')}</p>
            {discountGroups.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined" 
                    tooltip={t('addDiscountToProduct')} tooltipOptions={{position:'right'}}/>
                </div>
            )}
            {discountGroups.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined"
                    tooltip={t('addDiscountToProduct')} tooltipOptions={{position:'right'}} />
                </div>
            )}
        </div>
    );

    const footer = (
        // <div style={styles.footer}>
        //     <Button type="button" style={styles.button} label={t('addDiscountToProduct')}  onClick={planCreateRedirect} />
        // </div>
        <div style={styles.footer} className='buttons mt-5'>
            <Button type="button"  label={t('save')} onClick={null} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={close} />
        </div>
    );

    const dialogDiscountsNew =(
        <Dialog visible={discountsNewDialogVisible}  header={t('discounts')} onHide={() => close()} style={{ maxWidth: '850px' }} position="center" modal>
            <ShopDiscountsNew/>
            {footer}
        </Dialog>
    )
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={discountGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="title" header={t('title')} sortable></Column>
                    <Column field="amount" header={t('amount')} sortable></Column>
                    <Column field="unit" header={t('unit')} sortable></Column>
                    <Column field="from" header={t('from')} sortable></Column>
                    <Column field="until" header={t('until')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialogDiscountsNew}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopDiscounts, comparisonFn);
