import axios from 'axios';
import DomainService from './domain';
const domainService = new DomainService();

export default class AuthService {
    token;

    login(name, password) {
        return fetch('https://spooorts.de/'+ `login/n?email=${name}&password=${password}`, {
        // return fetch(domainService.getActiveDomain() + `login/n?email=${name}&password=${password}`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        })
        .then(res => res.json())
        .then(res => {
            localStorage.setItem("token", res.authToken);
            return res.authToken;
        })
        .catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        });
    }

    updateProfile(profile, token){
        return fetch(domainService.getActiveDomain() + `sport/user/${token}/profile/save`, {
            method: "POST",
            body: JSON.stringify(profile),
            headers: {
                "Content-Type": 'application/json',
            }
        })
            .then(res => res.json())
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return null;
                }
            });
    }

    register(name, password){
        return fetch(domainService.getActiveDomain() + `/login/n/regist?password=${password}&password2=${password}&email=${name}`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        })
            .then(res => res.json())
            .then(res => {
                localStorage.setItem("token", res.authToken);
                return res.authToken;
            })
            .catch(err => {
                if(err.status === 400 || err.status === 401){
            alert(err.error.message);
            return null;
        }
            });
    }

    requestProfile(token) {
        return axios.get('https://spooorts.de/' + `ba2/user/${token}`).then(res => {
        // return axios.get(domainService.getActiveDomain() + `ba2/user/${token}`).then(res => {
            localStorage.setItem("profile", JSON.stringify(res.data));
        }).catch(err => {
            if (err.status === 400 || err.status === 401) {
                alert(err.error.message);
                return null;
            }
        })
    }

    isLoged(){
        return !!localStorage.getItem("token");
    }

    getToken(){
        if(!this.token){
            this.token = localStorage.getItem("token");
        }
        return this.token;
    }

    async getPrivateProfile() {
        return fetch('https://spooorts.de/' + `ba2/user/${this.getToken()}`)
        // return fetch(domainService.getActiveDomain() + `ba2/user/${this.getToken()}`)
            .then(res => res.json())
            .then(res => {
                localStorage.setItem("profile", JSON.stringify(res));
                return res;
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                    return this.getProfile();
                }
            });
    }

    getProfile(){
        return JSON.parse(localStorage.getItem("profile"));
    }

    getEmptyProfile(){
        return {
            "id": -1,
            "forName": "",
            "surName": "",
            "city": "",
            "profilePicLink": "",
            "country": "",
            "anrede": "",
            "birthday": -1,
            "status": "",
            "zip": "",
            "area": "",
            "themeName": "",
            "clubName": "",
            "phoneType": "",
            "homeStreet": "",
            "homeStreetNo": "",
            "title": "",
            "contents": "",
            "facebookLnk": "",
            "instaLnk": "",
            "youtubeLnk": "",
            "whatsappLnk": "",
            "twitterLnk": "",
            "linkedinLnk": "",
            "xingLnk": "",
            "socialLnk": "",
            "logoImg": "",
            "premium": false,
            "lidOrg": -1,
            "url": null
        }
    }
}
