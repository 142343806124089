import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';

const SettingsQuestions = () => {

    const history = useHistory()
    const styles = {
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {

        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        },
        buttonSucc: {
            backgroundColor: "#294f94",
            borderColor: "#294f94",
        },
        buttonDanger: {
            backgroundColor: "#fff",
            borderColor: 'rgba(0, 0, 0, 0.87)',
            color: 'rgba(0, 0, 0, 0.87)'
        },
    }
    let emptyGroup={
        question:'',
        answer_option:''
    }

    const [eventDialog, setEventDialog] = useState(false);
    const [questionGroups, setQuestionGroups] = useState([]);
    const [questionGroup, setQuestionGroup] = useState(emptyGroup)
    const [questionWithTextGroups, setQuestionWithTextGroups] = useState([]);
    const [checkedRequired, setCheckedRequired] = useState(false);
    const [checkedOptional, setCheckedOptional] = useState(false);
    const [searchField, setSearchField] = useState("");
    const location = useLocation();

    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate'); 
    }

    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    const save =()=>{
        let _questionGroups = [...questionGroups];
        _questionGroups.push(questionGroup);
        setEventDialog(false);
        setQuestionGroups(_questionGroups);
        setQuestionGroup(emptyGroup);
        setEventDialog(false)
        window.windowCheck();
    }
    const reset =()=>{
        const { question, answer_option} = emptyGroup;
        setQuestionGroup({ question, answer_option:null});
        setEventDialog(false);
        window.windowCheck();
    }
    const add =()=>{
        let _questionGroups = [...questionWithTextGroups];
        _questionGroups.push(questionGroup);
        setQuestionWithTextGroups(_questionGroups);
        setQuestionGroup(emptyGroup);
        window.windowCheck();
    }
    const remove =()=>{
        const { question, answer_option} = emptyGroup;
        setQuestionGroup({ question:null, answer_option:null});
        // setQuestionWithTextGroups(questionWithTextGroups.)
        setQuestionWithTextGroups(prevItems => {
            const newItems = [...prevItems];
            newItems.pop();
            return newItems;
        });
        window.windowCheck();
    }
    const optionalChange=(e)=>{
        setCheckedOptional(e.checked);
        if (e.checked) {
            setQuestionGroup({ ...questionGroup, ...{ answer_option: ''} })
        }
    }
    const requiredChange=(e)=>{
        setCheckedRequired(e.checked);
    }
    const search=()=>{}
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('SETTINGS_QUESTION_NOTICE')}</p>
            {questionGroups.length!==0 &&(
                <div class="table-header-inner-container mt-10">
                    <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined mr-1" />
                    <Button icon="pi pi-plus" className='p-button-outlined' onClick={() => setEventDialog(true)}
                    tooltip={t('enterQuestion')} tooltipOptions={{ position: 'top' }}  />
                </div>
            )}
            {questionGroups.length==0 &&(
               <div class="table-header-inner-container mt-10">
                    <Button icon="pi pi-plus" className='p-button-outlined' onClick={() => setEventDialog(true)} 
                    tooltip={t('enterQuestion')} tooltipOptions={{ position: 'top' }} />
               </div> 
            )}
        </div>
    );

    const footer = (
        // <div style={styles.footer}>
        //     <Button type="button" label={t('enterQuestion')} style={styles.footer.button} className="m-tr-20 uppercase" />
        // </div>
        <div className='buttons'>
            <Button style={styles.buttonSucc} label={t('save')} onClick={save} />
            <Button style={styles.buttonDanger} label={t('close')} onClick={reset} />
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div className='card'>
            <DataTable value={questionGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                <Column field="question" header={t('question')} sortable></Column>
                <Column field="edit" body={editTemplate}></Column>
            </DataTable>

            <Dialog visible={!!eventDialog} style={{ 'maxWidth': '750px' }} header={t('online_registration_question')} footer={footer} modal closable onHide={() => setEventDialog(false)}>
                    <div className="p-fluid">
                        <div className='p-flex-group'>
                            <div className='group-block w-49'>
                                <label htmlFor='question'>{t('question')}</label>
                                <InputText  id='question' value={questionGroup.value} onChange={(e) => setQuestionGroup({ ...questionGroup, ...{question: e.target.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-49'>
                                <label htmlFor='answer_option'>{t('answer_option')}</label>
                                <InputText id="answer_option" value={questionGroup.answer_option}
                                onChange={(e) => setQuestionGroup({ ...questionGroup, ...{ answer_option: e.target.value } })} required={checkedRequired} autoFocus/>
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                            <div class="p-flex-group w-49 checkbox">
                                <div className="group-block w-20 ">
                                    <Checkbox  id='required_question' checked={checkedRequired} onChange={requiredChange}></Checkbox>
                                </div>
                                <div className="group-block w-80 ml-5" style={{ justifyContent: "center" }}>
                                    <label htmlFor='required_question'>{t('required_question')}</label>
                                </div>
                            </div>
                            <div class="p-flex-group w-49 checkbox">
                                <div className="group-block w-20 ">
                                    <Checkbox checked={checkedOptional} id='optional_question' onChange={optionalChange} ></Checkbox>
                                </div>
                                <div className="group-block w-80 ml-5" style={{ justifyContent: "center" }}>
                                    <label htmlFor='optional_question'>{t('optional_question')}</label>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group center'>
                         <div className='mobile-row'>
                            <Button icon="pi pi-plus" className='mt-10  mr-2' onClick={add}
                            tooltip={t('apply_changes')} tooltipOptions={{ position: 'top' }} ></Button>
                            <Button icon="pi pi-minus" className='mt-10 ' disabled onClick={remove}
                            tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
                        </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                        <DataTable value={questionWithTextGroups} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}
                        className={questionWithTextGroups.length === 0 ? 'hidden-tbody' : ''}>
                            <Column field="question" header={t('question')} sortable></Column>
                            <Column field="answer_option" header={t('answer_option')} sortable></Column>
                            <Column field="edit" body={editTemplate}></Column>
                        </DataTable>
                        </div>
                    </div>
            </Dialog>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SettingsQuestions, comparisonFn);
