import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Checkbox } from 'primereact/checkbox';

const SettingsGeneral = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const dateFormats = [{
            name: "dd.MM.yyyy (30.06.2023)",
            key: "dd.MM.yyyy"
        },{
            name: "dd.MM.yy (30.06.23)",
            key: "dd.MM.yy"
        },{
            name: "MM.dd.yyyy (06.30.2023)",
            key: "MM.dd.yyyy"
        },{
            name: "MM.dd.yy (06.30.23)",
            key: "MM.dd.yy"
        },{
            name: "dd-MMM-yyyy (30-Jun-2023)",
            key: "dd-MMM-yyyy"
        },{
            name: "dd-MMM-yy (30-Jun-23)",
            key: "dd-MMM-yy"
        },{
            name: "E, dd MMM yyyy (Fr, 30 Jun 2023)",
            key: "E, dd MMM yyyy"
        },{
            name: "MM/dd/yyyy (06/30/2023)",
            key: "MM/dd/yyyy"
        },{
            name: "MM/dd/yy (06/30/23)",
            key: "MM/dd/yy"
        },{
            name: "dd-MMM-yyyy (30-Jun-2023)",
            key: "dd-MMM-yyyy"
        },{
            name: "dd-MMM-yy (30-Jun-23)",
            key: "dd-MMM-yy"
        },{
            name: "E, dd MMM yyyy (Fr, 30 Jun 2023)",
            key: "E, dd MMM yyyy"
        }
    ];
    let dateFormat = dateFormats[0];

    const numberFormats = [{
            name: "###,##0.0 (123,5)",
            key: "###,##0.0"
        },{
            name: "###,##0.00 (123,45)",
            key: "###,##0.00"
        },{
             name: "###,##0.000 (123,450)",
            key: "###,##0.000"
        }
    ];
    let numberFormat = numberFormats[0];

    const distanceUnits = [{
        name: "km",
    }, {
        name: "mi"
    }];
    let distanceUnit = distanceUnits[0];

    const weightUnits = [{
        name: "kg"
    },{
        name: "lbs"
    }];
    let weightUnit = weightUnits[0];

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);

    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])

    const save = () => {
        setEventDialog(false);

        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)

        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };

    const reset = () => {
        const { title, start, end } = clickedEvent;
        setChangedEvent({ title, start, end, allDay: null });
    };

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
        </>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-2 mb-2" />
        </div>
    );


    return (
        <div class="card">
           <div class="p-flex-group jcsb">
                <div className="group-block w-49 mr-15">
                    <label class="sized">{t('language')}</label>
                    <Dropdown placeholder="de" className='border-custom' value={null} options={null}  />
                </div>
                <div className="group-block w-49">
                    <label class="sized">{t('currency')}</label>
                    <Dropdown placeholder="EUR" className='border-custom' value={null} options={null}  />
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="group-block w-49 mr-15">
                    <label class="sized">{t('defaultCategory')}</label>
                    <Dropdown placeholder="Ausdauersport" className='border-custom' value={null} options={null}  />
                </div>
                <div className="group-block w-49">
                    <label class="sized">{t('defaultSport')}</label>
                    <Dropdown placeholder="Bitte auswählen" className='border-custom' value={null} options={null}  />
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="group-block w-49 mr-15">
                    <label class="sized">{t('DATE_FORMAT')}</label>
                    <Dropdown placeholder={dateFormat.name} className='border-custom' optionLabel='name' value={dateFormat} options={dateFormats} />
                </div>
                <div className="group-block w-49">
                    <label class="sized">{t('NUMBER_FORMAT')}</label>
                    <Dropdown placeholder={numberFormat.name} className='border-custom' optionLabel='name' value={numberFormat} options={numberFormats} />
                </div>
            </div>
            <div class="p-flex-group jcsb mt-30">
                <div className="group-block w-49 mr-15">
                    <label class="sized">{t('DISTANCE_FORMAT')}</label>
                    <Dropdown placeholder={distanceUnit.name} className='border-custom' optionLabel='name' value={distanceUnit} options={distanceUnits} />
                </div>
                <div className="group-block w-49">
                    <label class="sized">{t('WEIGHT_FORMAT')}</label>
                    <Dropdown placeholder={weightUnit.name} className='border-custom' optionLabel='name' value={weightUnit} options={weightUnits} />
                </div>
            </div>

            <div class="p-flex-group mt-30">
                <Button label={t('save')} className='uppercase'></Button>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SettingsGeneral, comparisonFn);
