
import appData from '../assets/data/config.json';

export default class DomainService {
    activeDomain = 2;

    domains = [
        "http://localhost:8080/",
        "https://spooorts.de/",
        "https://spooorts.net/",
        "https://admin.spooorts.de/",
        "https://map.gofo.rest/",
        "https://goforest.spooorts.net/"
    ];

    constructor(){
        const index = this.domains.findIndex(d => d.includes(appData.domain));
        if(index > -1){
            this.activeDomain = index;
        }
    }

    getActiveDomain(){
        return this.domains[this.activeDomain];
    }
}   
