import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { TabPanel, TabView } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';


const EditEvent=()=>{
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        },
    }
    const [events, setEvents]=useState([]);
    const [event, setEvent]=useState(emptyEventGroup);
    const [activeDialogTabIndex, setActiveDialogTabIndex]=useState();
    const [dialogSportartVisible, setDialogSportartVisible]=useState(false);
    const location = useLocation();
    
    let emptyEventGroup={
        date:'',
        event:'',
        sportType:'',
        evaluation:'',
        distance:''
    }
    let eventStatus=[
        {label:t('registrations'), value:[
            {label:t('activate_participant_registration'), value:t('activate_participant_registration')},
            {label:t('unlock_spectator_ticketing'), value:t('unlock_spectator_ticketing')}
        ]},
        {label:'Status', value:[
            {label:t('private_event'), value:t('private_event')},
            {label:t('date_unconfirmed'), value:t('date_unconfirmed')},
            {label:t('event_cancelled'), value:t('event_cancelled')}
        ]}
    ]
    //     [title:t('registrations'), value:{[]} ]
    // }
    const currencies = [
        { label: "EUR", value: "EUR" },
        { label: "USD", value: "USD" }
    ];

    const onRedirectToEditEvent=()=>{}
    const openSportartDialog=()=>{
        setDialogSportartVisible(true);
        window.windowCheck();
    }
    const close=()=>{
        setDialogSportartVisible(false);
        window.windowCheck();
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            setTimeout(() => {
                window.windowCheck();
            }, 700);
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{": " + t('eventNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={onRedirectToEditEvent} className="p-button-outlined mr-2 mb-2" />
        </div>
    );
    const footer=(
        <div className="footer-more-buttons footer-to-start">
            <div className='footer-button-div'>
                <Button type="button" label={t('save')} className=" m-tr-20"/>
                <Button type="button" label={t('DELETE')} className="p-button-outlined m-tr-20" disabled />
            </div>
            <div className='footer-button-div'>
                <Button type="button" label={t('enter_next_event')}  className="p-button-outlined m-tr-20" disabled />
                <Button type="button" label={t('general_event_dates')}  className="p-button-outlined m-tr-20" disabled />
            </div>
        </div>
    )
    const dialogFooter=(
        <div style={styles.footer} className='mt-20 p-flex-group g-10 buttons'>
            <Button type="button" label={t('save')} onClick={null} />
            <Button type="button" className='p-button-outlined' onClick={close} label={t('close')} />
        </div>
    );

    const dialogSportart=(
        <Dialog className='dialog-container' header={t('add_sport_type')}  visible={dialogSportartVisible} onHide={() => setDialogSportartVisible(false)}>
            <div class="p-flex-group">
                <div className="group-block w-49">
                    <label htmlFor='category'>{t('category')}</label>
                    <Dropdown id='category' placeholder={t('category')} options={null} />
                </div>
                <div className="group-block 49">
                    <label htmlFor='sportType'>{t('sportType')}</label>
                    <InputText id='sportType'/>
                </div>
            </div>
            
            {dialogFooter}
        </Dialog>
    )

    return(
        <div>
        <TabView activeIndex={activeDialogTabIndex} onTabChange={(e) => setActiveDialogTabIndex(e.index)}>
            <TabPanel header='Event' className='edit-event-cards'>
                <div className='p-fluid'>
                    <div class="p-flex-group">
                        <div className="group-block w-49">
                            <label htmlFor='eventname'>Eventname</label>
                            <InputText id='eventname' />
                        </div>
                        <div className="group-block w-49">
                            <label htmlFor='status'>Status</label>
                            <Dropdown id='status' placeholder='Status' options={eventStatus} />
                        </div>
                    </div>
                
                    <div class="p-flex-group">
                        <div className="group-block w-49">
                            <label>{t('index')}</label>
                            <InputNumber />
                        </div>
                        <div className="group-block w-49">
                            <label>{t('area')}</label>
                            <InputText />
                        </div>
                    </div>

                    <div class="p-flex-group">
                        <div className="group-block w-49">
                            <label htmlFor='region'>{t('region')}</label>
                            <Dropdown id='region' placeholder={t('region')} options={null} />
                        </div>
                        <div className="group-block w-49">
                            <label htmlFor='country'>{t('country')}</label>
                            <Dropdown id='country' placeholder={t('country')} options={null} />
                        </div>
                    </div>

                    <div class="p-flex-group">
                        <div className="group-block w-49">
                            <label>{t('organizer_page')}</label>
                            <InputText placeholder='http://' />
                        </div>
                        <div className="group-block w-49">
                            <label>{t('type')}</label>
                            <Dropdown id='type' placeholder={t('type')} options={null} />
                        </div>
                    </div>

                    <div className='card darked-card'>
                        <div class="p-flex-group">
                            <div className="group-block w-35">
                                <label htmlFor=' category'>{t(' category')}</label>
                                <Dropdown id=' category' placeholder={t('category')} options={null} />
                            </div>
                            <div className="group-block w-35">
                                <label htmlFor='sportType'>{t('sportType')}</label>
                                <Dropdown id='sportType' placeholder={t('sportType')} options={null} />
                            </div>
                            <div className="group-block w-28">
                                <label>{t('sportType')}</label>
                                <Button icon="pi pi-plus" onClick={openSportartDialog} className="p-button-outlined mr-2 mb-2"
                                tooltip={t('add_sport_type')} tooltipOptions={{position:'top'}} />
                            </div>
                        </div>
                        {dialogSportart}
                        <div class="p-flex-group">
                            <div className="group-block w-49">
                                <label htmlFor='date_time'>{t('date_time')}</label>
                                <Calendar id='date_time' value={new Date()} showIcon />
                            </div>
                            <div className="group-block w-49">
                                <label>{t('participant_price')}</label>
                                <InputNumber placeholder='Euro' />
                            </div>
                        </div>
                        <div class="p-flex-group">
                            <div className="group-block w-32">
                                <label htmlFor='evaluation'>{t('evaluation')}</label>
                                <Dropdown id='evaluation' placeholder={t('evaluation')} options={null} />
                            </div>
                            <div className="group-block w-32">
                                <label htmlFor='from'>{t('from')}</label>
                                <Dropdown id='from' placeholder={t('from')} options={null} />
                            </div>
                            <div className="group-block w-32">
                                <label htmlFor='until'>{t('until')}</label>
                                <Dropdown id='until' placeholder={t('until')} options={null} />
                            </div>
                        </div>
                        <div class="p-flex-group">
                            <div className="group-block w-32">
                                <label htmlFor='distance_km1'>1. {t('distance_km')}</label>
                                <InputNumber id='distance_km2' showButtons mode="decimal" min={0.00} max={100} />
                            </div>
                            <div className="group-block w-32">
                                <label htmlFor='distance_km2'>2. {t('distance_km')}</label>
                                <InputNumber id='distance_km2' showButtons mode="decimal" min={0.00} max={100} disabled />
                            </div>
                            <div className="group-block w-32">
                                <label htmlFor='distance_km3'>3. {t('distance_km')}</label>
                                <InputNumber id='distance_km3' showButtons mode="decimal" min={0.00} max={100} disabled />
                            </div>
                        </div>
                        <div className=' info-ent'>
                            <label htmlFor="info">{t('more_info_about_event')}</label>
                            <InputTextarea id="info"/>
                        </div>
                        <div className='p-flex-group mini-buttons'>
                            <div className='group-block w-24 mr-2'>
                                <Button icon="pi pi-plus" onClick={null} className=" mr-2 mb-2"
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}} />
                            </div>
                            <div className='group-block w-24 mr-2'>
                                <Button icon="pi pi-minus" onClick={null} className="mr-2 mb-2" 
                                tooltip={t('DELETE')} tooltipOptions={{position:'top'}} />
                            </div>
                            <div className='group-block w-24 mr-2'>
                                <Button icon="pi pi-pencil" onClick={null} className=" mr-2 mb-2"
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}} />
                            </div>
                            <div className='group-block w-24'>
                                <Button icon="pi pi-list" onClick={null} className="mr-2 mb-2"
                                tooltip={t('attributes')} tooltipOptions={{position:'top'}} />
                            </div>
                        </div>
                        <hr/>
                        <DataTable value={events} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                            <Column field="date" header={t('date')} sortable></Column>
                            <Column field="event" header='Event' sortable></Column>
                            <Column field="sportType" header={t('sportType')} sortable></Column>
                            <Column field="evaluation" header={t('evaluation')} sortable></Column>
                            <Column field="distance" header={t('distance')} sortable></Column>
                            <Column field="participant_price" header={t('participant_price')} sortable></Column>
                            <Column field="more_info" header={t('more_info')}></Column>
                        </DataTable>
                    </div>
                    <hr/>
                    <div className='field info-ent'>
                        <label htmlFor="info_ent">{t('more_info_about_entire_event')}</label>
                        <InputTextarea id="info_ent"/>
                        <div className="footer-more-buttons">
                            <div className='footer-button-div'>
                                <Button type="button" icon="pi pi-list" label={t('results')} className="p-button-outlined m-tr-20" disabled />
                                <Button icon="pi pi-file-pdf" type="button" label={t('tender')} className="p-button-outlined m-tr-20" disabled />
                            </div>
                            <div className='footer-button-div'>
                                <Button icon="pi pi-map-marker" type="button" label={t('route')}  className="p-button-outlined m-tr-20" disabled />
                                <Button icon="pi pi-camera" type="button" label={t('photos')}  className="p-button-outlined m-tr-20" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header='Infos' className='edit-event-cards'>
                <div class="p-flex-group">
                    <div className="group-block w-49">
                        <label htmlFor='award_ceremony_at'>{t('award_ceremony_at')}</label>
                        <Calendar id='award_ceremony_at' value={new Date()} showIcon />
                    </div>
                    <div className="group-block w-49">
                        <label htmlFor='late_registrations'>{t('late_registrations')}</label>
                        <Calendar id='late_registrations' value={new Date()} showIcon />
                    </div>
                </div>
                <div class="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('route_profile')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('completely_flat')}</label>
                        </div>
                        <div className="group-block w-25">
                            
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('mostly_flat')}</label>
                        </div>
                        <div className="group-block w-25">
                           
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('hilly')}</label>
                        </div>
                        <div className="group-block w-25">
                            
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('mountainous')}</label>
                        </div>
                    </div>
                </div>
                <div class="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('underground')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('asphalt_only')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('forest_dirt')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('mixed')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>Cross</label>
                        </div>
                    </div>
                </div>
                <div class="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('distinction')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('medal')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('certificate')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>Shirt</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('gift')}</label>
                        </div>
                    </div>
                </div>
                <div className="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('winner')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('cup')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('medal')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('non_cash')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('cash')}</label>
                        </div>
                    </div>
                </div>
                <div className="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('COFFEE_ICON')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('along_the_route')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('free_at_the_finish')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('warm_food')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('cake')}</label>
                        </div>
                    </div>
                </div>
                <div class="checkbox-group">
                    <div>
                        <p className='checkbox-header'>{t('other')}</p>
                    </div>
                    <div class="p-flex-group checkbox-group-inner">
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('shower')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('changing_rooms')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>{t('wc')}</label>
                        </div>
                        <div className="group-block w-25">
                            <Checkbox checked={false}></Checkbox>
                            <label>Messe</label>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header={t('contacts')} className='edit-event-cards'>
                <div className='p-fluid'>
                    <div className='field'>
                        <label htmlFor='contact'>{t('contact')}</label>
                        <InputText id='contact' />
                    </div>
                    <div class="p-flex-group flex-align">
                        <div className="group-block w-80 mr-1">
                            <label htmlFor='street'>{t('street')}</label>
                            <InputText id='street' />
                        </div>
                        <div className="group-block w-39">
                            <label htmlFor='houseNum'>{t('houseNum')}</label>
                            <InputNumber id='houseNum' />
                        </div>
                    </div>
                    <div class="p-flex-group flex-align">
                        <div className="group-block w-39 mr-1">
                            <label>{t('index')}</label>
                            <InputNumber />
                        </div>
                        <div className="group-block w-80">
                            <label>{t('area')}</label>
                            <InputText />
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor='country'>{t('country')}</label>
                        <Dropdown id='country' placeholder={t('country')} options={null} />
                    </div>
                    <div class="p-flex-group flex-align">
                        <div className="group-block w-49 mr-1">
                            <label>eMail</label>
                            <InputText />
                        </div>
                        <div className="group-block w-70">
                            <label>{t('PHONE_NUMBER')}</label>
                            <InputNumber />
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>
        {footer}
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EditEvent, comparisonFn);