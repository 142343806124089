export default class AppLanguageService{
    /** 
   * @param {string} splitAppPath 
   * @returns {Promise<string>}
   */
  loadAppLanguage(splitAppPath) {
    return new Promise((resolve, reject) => {
      fetch(`${splitAppPath}/.language.json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((values) => {
          if (!values || !values.APP_LANGUAGE) {
            resolve('en');
          } else {
            resolve(values.APP_LANGUAGE);
          }
        })
        .catch((error) => {
          console.error('Error fetching language file:', error);
          reject('en');
        });
    });
  } 

}