import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { useLocation } from 'react-router-dom';

const ShopProductCategory = () => {
    const [categories, setCategories] = useState([]);
    const [eventDialog, setEventDialog] = useState(false);
    const [searchField, setSearchField] = useState('');
    const[dialogClosed, setDialogClosed]=useState(false)
    const location = useLocation();

    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }
    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    const save=()=>{
        window.windowCheck();
        setEventDialog(false)
        setDialogClosed(true);
    }
    const close=()=>{
        window.windowCheck();
        setEventDialog(false)
    }
    const addNewCategory = () => {
        setEventDialog(true);
    }
    const search=()=>{}
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            {/* <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button> */}
        </div>
    )


    const dialog = (
        <>
            <Dialog header={t('shop_categories')} visible={eventDialog} onHide={() => setEventDialog(false)} style={{ maxWidth: '850px' }} position="top" modal>
                {/* <div class="card"> */}
                    <div class="p-flex-group">
                        <div className="group-block w-100">
                            <label class="sized">{t('language')}</label>
                            <Dropdown placeholder="de" className='border-custom' value={null} options={null} />
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={null}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('first_level_categories')}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus"
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus"
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil"
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr/>
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={null}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('second_level_categories')}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus"  
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus"  
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil"  
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={null}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('third_level_categories')}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus"  
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus"  
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil"  
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={null}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('forth_level_categories')}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus"  
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus"  
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil"  
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>
                    <hr />
                    <div className='flex-row g-20'>
                        <div className='w-40'>
                            <Dropdown className='w-100' placeholder={t('ALL_CATEGORIES')} options={null}></Dropdown>
                        </div>
                        <div className='w-40'>
                            <InputText className='w-100' placeholder={t('fifth_level_categories')}></InputText>
                        </div>
                        <div className='flex jsfe g-10 w-20 ml-15'>
                            <Button icon="pi pi-plus" 
                                tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-minus" 
                                tooltip={t('DELETE_CATEGORY')} tooltipOptions={{position:'top'}}></Button>
                            <Button icon="pi pi-pencil" 
                                tooltip={t('apply_changes')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    </div>

                    <div className="flex g-10 buttons mt-30">
                        <Button type="button" label={t('save')} onClick={() => save()} />
                        <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => close()} />
                    </div>
                {/* </div> */}
            </Dialog>
        </>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>: {t('PRODUCT_NOTE_MSG')}</p>
            {categories.length!==0 &&(
                <div className="table-header-container flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={addNewCategory} className="p-button-outlined "
                    tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {categories.length==0 &&(
                <div className="table-header-container flex-center">
                    <Button icon="pi pi-plus" onClick={addNewCategory} className="p-button-outlined "
                    tooltip={t('ADD_CATEGORY')} tooltipOptions={{position:'top'}} />
                </div>
            )}
        </div>
    );

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={categories} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="title" header={t('language')} sortable></Column>
                    <Column field="amount" header={'1.' + t('category')} sortable></Column>
                    <Column field="unit" header={'2.' + t('category')} sortable></Column>
                    <Column field="from" header={'3.' + t('category')} sortable></Column>
                    <Column field="until" header={'4.' + t('category')} sortable></Column>
                    <Column field="until" header={'5.' + t('category')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialog}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopProductCategory, comparisonFn);
