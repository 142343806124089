import React, { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { Login } from './pages/auth/login/Login';
import { Register } from './pages/auth/registration/Register';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import { LeafletEditComponent } from './pages/map/leaflet-component';
import appConfig from './assets/data/config.json';

const AppWrapper = () => {

    let location = useLocation();

    const firstPageParse = () => {
        if (appConfig.appConfigs.firstPage.includes("LeafletEditComponent")) {
            return <LeafletEditComponent />
        } else {
            return <App />
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    switch (location.pathname) {
        case '/login':
            return <Route path="/login" component={Login} />
        case '/editRoute':
            return <Route path="/editRoute" component={LeafletEditComponent} />
        case '/register':
            return <Route path="/register" component={Register} />
        case '/error':
            return <Route path="/error" component={Error} />
        case '/notfound':
            return <Route path="/notfound" component={NotFound} />
        case '/access':
            return <Route path="/access" component={Access} />
        default:
            return firstPageParse();
    }
}

export default withRouter(AppWrapper);
