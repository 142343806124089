import { createStore } from 'redux';

export const filter = () => {
    return {
        type: "FILTER"
    };
};
export const addContact = () => {
    return {
        type: "ADD"
    };
};
export const fileUpload = () => {
    return {
        type: "UPLOAD_FILE"
    };
};
export const addContactCategory = () => {
    return {
        type: "ADD_CATEGORY"
    };
};
const counter = (state = 0, action) => {
    switch (action.type) {
        case "ADD": return 0;
        case "ADD_CATEGORY": return 1;
        case "UPLOAD_FILE": return 2;
        default: return -1;
    }
};

export let storeContact = createStore(counter);