import axios from 'axios';
import DomainService from './domain';

export default class ImageService {
    domainService = new DomainService();

    getImages() {
        return axios.get('assets/demo/data/photos.json').then(res => res.data.data);
    }

    deleteAllRouteImages(token, route){
        return new Promise((resolve, reject) => {
            return fetch(this.domainService.getActiveDomain() + `route/user/${token}/route/photos/delete`, 
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(route)
            }
            ).then(response => {
                if (response.ok) {
                    resolve(true);
                } else {
                    console.log('Images were not removed due to some error!');
                    resolve(false);
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                }
                reject();
            });
        });
    }

    saveImage(token, routeName, imageFormData) {
        return new Promise((resolve, reject) => {
            return fetch(this.domainService.getActiveDomain() + `route/user/${token}/route/${routeName}/image/upload`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(imageFormData)
            }).then(response => {
                if(response.ok){
                    resolve(true);
                }else{
                    console.log('Image not uploaded due to some error!');
                    resolve(false);
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 401) {
                    alert(err.error.message);
                }
                reject();
            });
        });
    }
}
