import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';

const ShowOrganizerMaps=()=>{
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        },
    }
    const [events, setEvents]=useState([]);
    const [event, setEvent]=useState(emptyEventGroup);
    const [searchField, setSearchField]=useState('')
    const history = useHistory();
    const location = useLocation();
    
    let emptyEventGroup={
        date:'',
        event:'',
        sportType:'',
        evaluation:'',
        distance:''
    }
    const search=()=>{}

    const onRedirectToEditEvent=()=>{
        history.push('/editEvent');
        window.windowCheck();
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('organizerMapsNoticeContent')}</p>
            {events.length!==0 &&(
                <div class="table-header-inner-container">
                    <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined mr-1" />
                    <Button icon="pi pi-plus" onClick={onRedirectToEditEvent} className="p-button-outlined" 
                    tooltip={t('new_event')} tooltipOptions={{position:'top'}}/>
                </div>
            )}
            {events.length==0 &&(
                <div class="table-header-inner-container">
                    <Button icon="pi pi-plus" onClick={onRedirectToEditEvent} className="p-button-outlined"
                    tooltip={t('new_event')} tooltipOptions={{position:'top'}} />
                </div>
            )}
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return(
        <div>
            <div className='card' >
                <DataTable value={events} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="date" header={t('date')} sortable></Column>
                    <Column field="event" header='Event' sortable></Column>
                    <Column field="sportType" header={t('sportType')} sortable></Column>
                    <Column field="evaluation" header={t('evaluation')} sortable></Column>
                    <Column field="distance" header={t('distance')} sortable></Column>
                    <Column field="edit" body={editTemplate}></Column>
                </DataTable>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShowOrganizerMaps, comparisonFn);