import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TrainingService from '../../service/TrainingService';
import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tooltip } from 'primereact/tooltip';
import { useLocation } from 'react-router-dom';
import trainingPlan from './training-plan';

const TrainingGroups = () => {
    let emptyGroup = {
        id: null,
        name: '',
        description: ''
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [trainingGroups, setTrainingGroups] = useState([]);
    const [trainingGroup, setTrainingGroup] = useState(emptyGroup)
    const [eventDialog, setEventDialog] = useState(false);
    const [searchField, setSearchField]=useState('');
    const[participants, setParticipant]=useState([]);
    const [trainingPlans, setTrainingPlans]=useState([]);
    const [ dialogClosed, setDialogClosed]=useState(false);
    const trainingService = new TrainingService();
    const location = useLocation();

    useEffect(() => {
        trainingService.getTrainingGroups().then(data => {
            console.log('data: ', data);
            setTrainingGroups(data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const handleWindowCheck = () => {
            setTimeout(() => {
                window.windowCheck();
            }, 100);
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);


    const styles = {
        table: {
            padding: "0px"
        },
        footer: {
            textAlign: "start"
        },
        button: {
            marginTop: "25px",
            backgroundColor: "#294f94",
            borderColor: "#294f94",
        },
        inputNote: {
            height: "200px"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
    }

    const save = () => {
        let _trainingGroups = [...trainingGroups];

        _trainingGroups.push(trainingGroup);

        setEventDialog(false);
        setTrainingGroups(_trainingGroups);
        setTrainingGroup(emptyGroup);
        setDialogClosed(true)
    };

    const reset = () => {
        const { id, name, description } = emptyGroup;
        setTrainingGroup({ id, name, description });
        setEventDialog(false);
        setDialogClosed(true);
    };
    const search =()=>{}

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('trainingGroupsNoticeContent')}</p>
            {trainingGroups.length!==0 &&(
                <div className="table-header-container flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                    tooltip={t('addTrainingGroup')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {trainingGroups.length==0 &&(
                <div className="table-header-container flex-center">
                   <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                   tooltip={t('addTrainingGroup')} tooltipOptions={{position:'top'}} /> 
                </div>
            )}
        </div>
    );

    const footer = (
        <div style={styles.footer} className='buttons'>
            <Button type="button" label={t('save')} onClick={save} />
            <Button type="button" label={t('close')} className="p-button-outlined" onClick={reset} />
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={trainingGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="name" header={t('trainingGroup')} sortable></Column>
                    <Column field="description" header={t('description')} sortable></Column>
                    <Column field="edit" body={editTemplate}></Column>
                </DataTable>
            </div>

    
            <Dialog visible={!!eventDialog} style={{ maxWidth: '750px'}} header={t('trainingGroups')} footer={footer} modal closable onHide={() => setEventDialog(false)}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={t('trainingGroup')}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="title">{t('trainingGroup')}</label>
                            <InputText id="title" value={trainingGroup.name} onChange={(e) => setTrainingGroup({ ...trainingGroup, ...{ name: e.target.value } })} required autoFocus />
                        </div>
                        <hr />
                        <div className="field">
                            <label htmlFor="start">{t('note')}</label>
                            <InputTextarea id="start" style={styles.inputNote} value={trainingGroup.description} onChange={(e) => setTrainingGroup({ ...trainingGroup, ...{ description: e.target.value } })} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header={t('participant')}>
                    <DataTable value={participants} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}
                    className={participants.length === 0 ? 'hidden-tbody' : ''}>
                        <Column field="title" header={t('firstName')} sortable></Column>
                        <Column field="amount" header={t('surname')} sortable></Column>
                        <Column field="unit" header="eMail" sortable></Column>
                        <Column field="edit" body={editTemplate}></Column>
                    </DataTable>
                </TabPanel>
                <TabPanel header={t('trainingPlan')}>
                    <DataTable value={trainingPlans} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}
                    className={trainingPlans.length === 0 ? 'hidden-tbody' : ''}>
                        <Column field="name" header="Name" sortable></Column>
                        <Column field="trainer" header={t('trainer')} sortable></Column>
                        <Column field="edit" body={editTemplate}></Column>
                    </DataTable>
                </TabPanel>
            </TabView>
            </Dialog>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TrainingGroups, comparisonFn);
