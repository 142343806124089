import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import EventService from '../../../service/EventService';
import { Image } from 'primereact/image';
import sponsorImage from '../../../assets/images/tempsponsors/spooorts.png';

const OurPhotos = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState(null);
    const imageUploadInputRef = useRef(null);
    const [imageUpload, setImageUpload]=useState('https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
    const [imagesUploaded, setImagesUploaded]=useState([
        sponsorImage,
        sponsorImage
    ])
    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
    }, [])

    const save = () => {
        setEventDialog(false);

        clickedEvent.setProp('title', changedEvent.title)
        clickedEvent.setStart(changedEvent.start)
        clickedEvent.setEnd(changedEvent.end)
        clickedEvent.setAllDay(changedEvent.allDay)

        setChangedEvent({ title: '', start: null, end: '', allDay: null });
    };

    const reset = () => {
        const { title, start, end } = clickedEvent;
        setChangedEvent({ title, start, end, allDay: null });
    };
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                saveImage(dataUrl, selectedFile.name.trim());
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        // setImageUpload(...imagesUploaded)
        console.log(imagesUploaded)
        setImagesUploaded(prevImages=>{
            const newItems = [...prevImages];
            const indexToReplace = newItems.findIndex(url => url === "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png");
            if (indexToReplace !== -1) {
                newItems[indexToReplace] = dataUrl;
            } else {
                newItems.push(dataUrl);
            }
            return newItems;
        })
        console.log(imagesUploaded)
    }
    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept=".png,.jpg,.jpeg"
        />
    );

    const footer = (
        <>
            <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
            <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
        </>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('productsNoticeContent')}</p>
            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-2 mb-2" />
        </div>
    );


    return (
        <div >
            <Button type="button" label={t('uploadPhoto')} icon="pi pi-plus" className='p-button-outlined' onClick={() => imageUploadInputRef.current.click()}/>

            <div className='card mt-20 images-container image-card-main-container' style={ { textAlign: "center"  } }>
                {imagesUploaded.map((url, index) => (
                    <div className='image-card-container mb-5 mr-2'>
                        <Image src={url} key={index} height='200' className='image-card'></Image>
                        <Button icon="pi pi-times" className="p-button-outlined close-button" onClick={null} tooltip={t('DELETE')}
                        tooltipOptions={{ position: 'top' }}/>
                    </div> 
                ))}
            </div>
            {hiddenInputImageUpload}
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OurPhotos, comparisonFn);
