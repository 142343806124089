import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
// import EventService from '../service/EventService';
import EventService from '../../service/EventService'
import AuthService from '../../service/auth.service';
import SalesService from '../../service/sales.service';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useLocation } from 'react-router-dom';

const EarlyBird = () => {
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        },
        buttonSucc: {
            backgroundColor: "#294f94",
            borderColor: "#294f94",
        },
        buttonDanger: {
            backgroundColor: "#fff",
            borderColor: 'rgba(0, 0, 0, 0.87)',
            color: 'rgba(0, 0, 0, 0.87)'
        },
    }
    let emptyGroup={
        id:null,
        titel:'',
        betrag: 0,
        einheit1: 'EUR',
        fruhbucherrabatte: 0,
        einheit2: '',
        note:''
    };
    let einheit1Options=[
        { label: 'EUR', value: 'EUR' },
        { label: 'USD', value: 'USD' },
        { label: '%', value: '%' },
    ]
    let einheit2Options=[
        { label: t('day'), value: t('day') },
        { label: t('week'), value: t('week')},
        { label: t('week'), value: t('week') },
    ]

    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [rabatteGroups, setRabatteGroups] = useState([]);
    const [rabatteGroup, setRabatteGroup] = useState(emptyGroup)
    const [events, setEvents] = useState(null);
    const [searchField, setSearchField] = useState("");
    const [dialogClosed, setDialogClosed]=useState(false);
    const authService = new AuthService();
    const location = useLocation();
    useEffect(() => {
        let token = authService.getToken();
    //     trainingService.getTrainingGroups().then(data => {
    //         console.log('data: ', data);
    //         setTrainingGroups(data);
    //     });
    }, []);
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);
        useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const eventClick = (e) => {
        const { title, start, end } = e.event;
        setEventDialog(true);
        setClickedEvent(e.event);
        setChangedEvent({ title, start, end, allDay: null });
    }

    useEffect(() => {
        const eventService = new EventService();
        eventService.getEvents().then(data => setEvents(data));
        //should get rabatte
    }, [])

    const save = () => {
        setEventDialog(false);

        // clickedEvent.setProp('title', changedEvent.title)
        // clickedEvent.setStart(changedEvent.start)
        // clickedEvent.setEnd(changedEvent.end)
        // clickedEvent.setAllDay(changedEvent.allDay)

        // setChangedEvent({ title: '', start: null, end: '', allDay: null });
        let _rabatteGroups = [...rabatteGroups];
        _rabatteGroups.push(rabatteGroup);
        setEventDialog(false);
        setRabatteGroups(_rabatteGroups);
        setRabatteGroup(emptyGroup);
        setDialogClosed(true)
    };
    const reset = () => {
        const { titel, betrag, einheit1, fruhbucherrabatte, einheit2, note } = emptyGroup;
        setRabatteGroup({ titel, betrag, einheit1, fruhbucherrabatte, einheit2, note: null});
        setEventDialog(false);
        window.windowCheck();
    };
    const search=()=>{}

    const footer = (
        <div className='buttons'>
            <Button style={styles.buttonSucc} label={t('save')} onClick={save} />
            <Button style={styles.buttonDanger} label={t('close')} onClick={reset} />
        </div>
    );

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('NOTE_SALES_MSG')}</p>
            {rabatteGroups.length!==0 &&(
                <div class="table-header-inner-container">
                    <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined mr-1" />
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                    tooltip={t(' addDiscount')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {rabatteGroups.length==0 &&(
                <div class="table-header-inner-container">
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined"
                    tooltip={t(' addDiscount')} tooltipOptions={{position:'top'}} />
                </div>
            )}
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    return (
        <div>
            <div className='card' >
                <DataTable value={rabatteGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="titel" header={t('title')} sortable></Column>
                    <Column field="betrag" header={t('amount')} sortable></Column>
                    <Column field="einheit1" header={t('unit')} sortable></Column>
                    <Column field="fruhbucherrabatte" header={t('ealyBirdRebate')} sortable></Column>
                    <Column field="einheit2" header={t('unit')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>

            <Dialog visible={!!eventDialog} style={{ 'maxWidth': '750px' }} header={t('discounts')} footer={footer} modal closable onHide={() => setEventDialog(false)}>
                    <div className="p-fluid">
                        <div className="field">
                            <label htmlFor="title">{t('designCampaign')}</label>
                            <InputText id="title" value={rabatteGroup.titel} onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ titel: e.target.value } })} required autoFocus />
                        </div>
                        <hr />
                        <div className='p-flex-group'>
                            <div className='group-block w-65'>
                                <label htmlFor='betrag'>{t('discount')}</label>
                                <InputNumber  id='betrag' value={rabatteGroup.betrag} onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ betrag: e.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-33'>
                                <label htmlFor='einheit1'>{t('unit')}</label>
                                <Dropdown value={rabatteGroup.einheit1} options={einheit1Options} 
                                onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ einheit1: e.target.value } })} required autoFocus
                                placeholder="EUR"/>
                            </div>
                        </div>
                        <hr/>
                        <div className='p-flex-group'>
                            <div className='group-block w-65'>
                                <label htmlFor='betrag'>{t('ealyBirdRebate')}</label>
                                <InputNumber  id='betrag' value={rabatteGroup.fruhbucherrabatte} onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ fruhbucherrabatte: e.value } })} required autoFocus/>
                            </div>
                            <div className='group-block w-33'>
                                <label htmlFor='einheit1'>{t('unit')}</label>
                                <Dropdown value={rabatteGroup.einheit2} options={einheit2Options} 
                                onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ einheit2: e.target.value } })} required autoFocus
                                placeholder={t('day')}/>
                            </div>
                        </div>
                        <hr/>
                        <div className="field">
                            <label htmlFor="note">{t('note')}</label>
                            <InputTextarea id="note"  value={rabatteGroup.note} onChange={(e) => setRabatteGroup({ ...rabatteGroup, ...{ note: e.target.value } })} />
                        </div>
                        <hr/>
                    </div>
            </Dialog>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EarlyBird, comparisonFn);
