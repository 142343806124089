import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import AuthService from '../../../service/auth.service';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import appConfig from '../../../assets/data/config.json';
import packageJson from '../../../../package.json';

export const Login = (props) => {
    const appName = packageJson.name;
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const[darkMode, setDarkMode]=useState(false);

    const styles = {
        backgroundColor: appConfig.color,
        width300: {
            width: "300px",
        },
        widthFull: {
            width: "100%"
        }
    }

    const authService = new AuthService();

    useEffect(()=>{
        const savedDarkMode = localStorage.getItem('darkMode') === 'true';
        if(!savedDarkMode && !appName.includes('GOForest')){
            setDarkMode(true)  
        }
        let body = document.querySelector('body');
        if (!savedDarkMode && !appName.includes('GOForest')) {
            body.classList.add('darkMode');
        } else {
            body.classList.remove('darkMode');
        }
    })
    const submit = () => {
        authService.login(name, password).then((token) => {
            authService.requestProfile(token).then(() => {
                let reverseUrl = props.location.state ? props.location.state.reverseUrl : "/";
                if (props.location.state && props.location.state.callback){
                    props.location.state.callback();
                }
                setTimeout(() => {
                    history.replace(reverseUrl);
                }, 1000);
            });
        });
    }

    const register = () => {
        if(props.location.state){
            redirectToPageWithCallback("/register", props.location.state ? props.location.state.reverseUrl : "/", props.location.state.callback);
        }else{
            redirectToPageWithCallback("/register");
        }
    }

    const redirectToPageWithCallback = (path, reverseUrl, callback) => {
        history.push({
            pathname: path,
            state: {
                reverseUrl,
                callback,
            },
        });
    }
    return (
        <>
        <div className='linear-bg'></div>
        <div className={`login-body ${appConfig.appName == 'Admin spooorts' ? '' : 'login-bg'}`}>
            <div className="login-wrapper">
                <div className="login-panel" style={styles.widthFull}>
                    <div className="login-form" style={styles.widthFull}>
                        <h2 style={{width:styles.width300.width, color: 'white'}}>{t('LOGIN1_SIMPLE')}</h2>
                        <InputText style={styles.width300} placeholder="Email" onChange={(e) => setName(e.target.value)} />
                        <Password style={styles.width300} feedback={false} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        <div class="login-button-group">
                            <div className='align-self-end underlined' style={{ color: 'white' }} onClick={() => window.open("https://spooorts.de/login/forgot-password")}>{t('FORGOT_PASSWORD')}</div>
                            <Button style={{width: styles.width300.width, backgroundColor: styles.backgroundColor, borderColor: styles.backgroundColor}} label={t('LOGIN2_SIMPLE')} type="button" onClick={submit}></Button>
                            <div style={{color: 'white'}}>{t('FORGOT_QSTN_MSG')}</div>
                            <Button style={{ width: styles.width300.width, color: 'white', borderColor: styles.backgroundColor }} label={t('REGISTER_SIMPLE')} type="button" className='p-button-outlined' onClick={register}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
