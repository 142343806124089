import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import TrainingService from '../../service/TrainingService';
import { t } from 'i18next';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useHistory } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import ShopService from '../../service/shop.service';
import { Image } from 'primereact/image';
import ShopProductDialog from './shop-product-dialog';
import { Dialog } from 'primereact/dialog';
import EditContactDialog from '../contact/edit-contact-dialog';
import { InputNumber } from 'primereact/inputnumber';
import { useLocation } from 'react-router-dom';

const ShopSalesNew = () => {
    const location = useLocation();
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [deliveryModes, setDeliveryModes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const imageUploadInputRef = useRef(null);
    const [imageUpload, setImageUpload]=useState('')
    const [isImage, setIsImage]=useState(false);
    const [editContactDialoVisible, setEditContactDialogVisible]=useState(false)
    const [openProductDialogVisible, setOpenProductDialogVisible]=useState(false);
    const [products, setProducts]=useState([]);
    const [recipents, setRecipents]=useState([]);
    const [shippingTypes, setShippingTypes]=useState([]);
    const [searchField, setSearchField]=useState('');
    const [shippingTypeDialogVisible, setShippingTypeDialogVisible]=useState(false);

    const history = useHistory()
    const styles = {
        footer: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "10px 0px"
        },
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {

        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        }
    }

    const trainingService = new TrainingService();
    const shopService = new ShopService();

    useEffect(() => {
        trainingService.getTrainingPlans().then(plans => setTrainingPlans(plans));

    }, [])

    useEffect(() => {
    });

    const planCreateRedirect = () => {
        history.push('/trainingPlanCreate');
    }

    const addNewProduct = () => {

    }
    const search=()=>{}
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                saveImage(dataUrl, selectedFile.name.trim());
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        setImageUpload(dataUrl)
        setIsImage(true)
    }
    const openProductsDialog=()=>{
        setOpenProductDialogVisible(true);
    }
    const saveNewProduct = () => {}

    const openEditContactDialog=()=>{
        setEditContactDialogVisible(true)
    }
    const openShippingTypeDialog=()=>{
        setShippingTypeDialogVisible(true)
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        if (activeIndex == 1 || activeIndex === 2 || activeIndex === 3) {
            handleWindowCheck()
        }
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location, activeIndex]);
    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept=".png,.jpg,.jpeg"
        />
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const footer = (
        <div style={styles.footer} className="mt-20 buttons">
            <Button type="button" label={t('save')} onClick={addNewProduct} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={addNewProduct} />
        </div>
    );
    const editContactDialog=(
        <Dialog visible={editContactDialoVisible} header={t('contacts')}  onHide={() => {setEditContactDialogVisible(false)}} style={{ maxWidth: '850px' }} position="top" modal>
            <EditContactDialog/>
            <div className="flex g-10 buttons mt-30">
                <Button type="button" label={t('save')} onClick={() => saveNewProduct()} />
                <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => { setOpenProductDialogVisible(false)}} />
            </div>
        </Dialog>
    )
    const productDialog=(
        <Dialog visible={openProductDialogVisible} header={t('productSale')}  onHide={() => {setOpenProductDialogVisible(false)}} style={{ maxWidth: '850px' }} position="top" modal>
            <ShopProductDialog/>
            <div className="flex g-10 buttons">
                <Button type="button" label={t('save')} onClick={() => saveNewProduct()} />
                <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => { setOpenProductDialogVisible(false)}} />
            </div>
        </Dialog>
    );

    const shippingTypeDialog=(
        <Dialog visible={shippingTypeDialogVisible} header={t('enter_shippingMethod')}  onHide={() => {setShippingTypeDialogVisible(false)}} style={{ maxWidth: '850px' }} position="center" modal>
            <div className='p-fluid'>
                <div className='p-flex-group flex-align'>
                    <div className='group-block w-49'>
                        <label htmlFor=' shippingType'>{t('shippingType')}</label>
                        <InputText  className='mt-2' id=' shippingType' />
                    </div>
                    <div className='group-block w-49'>
                        <label ></label>
                        <Dropdown placeholder='Land' />
                    </div>
                </div>
                <hr/>
                <div className='p-flex-group flex-align'>
                    <div className='group-block w-49'>
                        <label htmlFor=' price'>{t('price')}</label>
                        <InputNumber  className='mt-2' id='price' />
                    </div>
                    <div className='group-block w-49'>
                        <label htmlFor='currency'>{t('currency')}</label>
                        <Dropdown placeholder='EUR' id='currency' />
                    </div>
                </div>
                <hr/>
                <div className='field'>
                    <label htmlFor='description'>{t('description')}</label>
                    <InputTextarea  className='mt-2' id='description' />
                </div>
            </div>
            <div className="flex g-10 buttons mt-30">
                <Button type="button" label={t('save')} onClick={() => null} />
                <Button type="button" className='p-button-outlined' label={t('close')} onClick={() => { setShippingTypeDialogVisible(false)}} />
            </div>
        </Dialog>
    )

    return (
        // <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={t('sales')}>
                    <div className='p-fluid'>
                        <div className='field w-100'>
                            <label htmlFor='title'>{t('title')}</label>
                            <InputText  className='mt-2' id='title' />
                        </div>
                        <hr/>
                        <div class="p-flex-group jcsb">
                            <div className="group-block w-40">
                                <label>Status</label>
                                <Dropdown />
                            </div>
                            <div className="group-block w-25 ml-10">
                                <label>{t('discount')}</label>
                                <InputText placeholder="1" />
                            </div>
                            <div className="group-block w-25">
                                <label>{t('unit')}</label>
                                <Dropdown placeholder="1" />
                            </div>
                        </div>
                        <hr/>
                        <div className='field'>
                            <label htmlFor='note'>{t('note')}</label>
                            <InputTextarea id='note' className='mt-2'></InputTextarea>
                        </div>
                        {footer}
                    </div>
                </TabPanel>
                <TabPanel header={t('products')}>
                    {products.length!==0 &&(
                    <div className='table-header-inner-container mb-5'>
                        <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                        <Button icon="pi pi-search" onClick={search} className="p-button-outlined mr-1" />
                        <Button icon="pi pi-plus" className='p-button-outlined' onClick={()=>openProductsDialog()}
                        tooltip={t('enterProduct')} tooltipOptions={{position:'top'}}></Button>
                    </div>
                    )}
                    {products.length==0 &&(
                        <div className='table-header-inner-container mb-5'>
                            <Button icon="pi pi-plus" className='p-button-outlined' onClick={()=>openProductsDialog()}
                            tooltip={t('enterProduct')} tooltipOptions={{position:'top'}}></Button>
                        </div>
                    )}
                    <DataTable value={products} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="title" header={t('product')} sortable></Column>
                        <Column field="amount" header={t('price')} sortable></Column>
                        <Column field="unit" header={t('currency')} sortable></Column>
                        <Column field="from" header={t('number')} sortable></Column>
                        <Column field='edit' body={editTemplate}></Column>
                    </DataTable> 
                    {footer}
                    {productDialog}
                </TabPanel>

                <TabPanel header={t('recipient')}>
                    <div class="p-flex-group jcsb">
                        {recipents.length!==0 &&(
                            <div className="table-header-inner-container mb-5">
                                <div className="table-header-inner-container">
                                    <InputText placeholder='Suche' />
                                    <Button icon="pi pi-search" className='p-button-outlined'></Button>
                                </div>
                                <div className="table-header-inner-container">
                                    <Dropdown placeholder="Alle" />
                                    <Button icon="pi pi-plus" className='ml-10 p-button-outlined' onClick={openEditContactDialog}
                                    tooltip={t('add_cotact')} tooltipOptions={{position:'top'}}></Button>
                                </div>
                            </div>
                        )}
                        {recipents.length==0 &&(
                            <div className="table-header-inner-container mb-5">
                                <Button icon="pi pi-plus" className='ml-10 p-button-outlined' onClick={openEditContactDialog}
                                tooltip={t('add_cotact')} tooltipOptions={{position:'top'}}></Button>
                            </div>
                        )}
                    </div>
                    {/* <hr/> */}
                    <DataTable value={recipents} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="title" header={t('firstName')} sortable></Column>
                        <Column field="amount" header={t('surname')} sortable></Column>
                        <Column field="unit" header="eMail" sortable></Column>
                        <Column field='edit' body={editTemplate}></Column>
                    </DataTable>
                    {footer}
                    {editContactDialog}
                </TabPanel>

                <TabPanel header={t('shippingType')}>
                    {shippingTypes.length!==0 &&(
                        <div className="table-header-inner-container mb-2">
                            <div className="table-header-inner-container">
                                <InputText placeholder='Suche' />
                                <Button icon="pi pi-search" className='p-button-outlined'></Button>
                            </div>
                            <div className="table-header-inner-container">
                                <Dropdown placeholder="Alle" />
                                <Button icon="pi pi-plus" className='ml-10 p-button-outlined' onClick={openShippingTypeDialog}></Button>
                            </div>
                        </div>
                    )}
                    {shippingTypes.length==0 &&(
                        <div className="table-header-inner-container mb-2">
                            <Button icon="pi pi-plus" className='ml-10 p-button-outlined' onClick={openShippingTypeDialog}></Button>
                        </div>
                    )}
                    <DataTable value={shippingTypes} responsiveLayout="scroll" paginator rows={10} emptyMessage={t('emptyMessage')}>
                        <Column field="title" header={t('title')} sortable></Column>
                        <Column field="amount" header={t('amount')} sortable></Column>
                        <Column field="unit" header={t('unit')} sortable></Column>
                        <Column field="from" header={t('from')} sortable></Column>
                        <Column field="until" header={t('until')} sortable></Column>
                        <Column field='edit' body={editTemplate}></Column>
                    </DataTable>
                    {footer}
                    {shippingTypeDialog}
                </TabPanel>

                {/* <TabPanel header={t('enterProduct')}>
                    <div class="p-flex-group">
                        <div className="group-block w-65">
                            <label>{t('product')}</label>
                            <InputText />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('language')}</label>
                            <Dropdown placeholder="1" />
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group">
                        <div className="group-block w-65 import-button">
                            <Button icon="pi pi-plus" className="w-65 p-button-outlined" label={t('addPhoto')} onClick={() => imageUploadInputRef.current.click()} />
                        </div>
                        {!isImage &&(<div className="group-block w-20 " style={styles.icon}>
                            <i class="pi pi-shopping-cart"></i>
                        </div>
                        )}
                        {isImage &&(
                        <div className="group-block w-20" style={styles.icon}>
                            <div class="smallimg">
                                <Image src={imageUpload} width='35px' height='35px'></Image> 
                            </div>
                        </div>
                        )}
                    </div>
                    <hr />
                    <div class="p-flex-group">
                        <div className="group-block w-65">
                            <label>{t('price')}</label>
                            <InputText />
                        </div>
                        <div className="group-block w-33">
                            <label>{t('currency')}</label>
                            <Dropdown placeholder="1" />
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group checkbox">
                        <div className="group-block">
                            <Checkbox checked={false}></Checkbox>
                        </div>
                        <div className="group-block w-85 ml-10" style={{ justifyContent: "center" }}>
                            <label>{t('showProductInPhoto')}</label>
                        </div>
                    </div>
                    <hr />
                    <div class="p-flex-group">
                        <div className="group-block w-100">
                            <label>{t('description')}</label>
                            <InputTextarea className="h-100-fixed"></InputTextarea>
                        </div>
                    </div>
                    {footer}
                    {hiddenInputImageUpload}
                </TabPanel> */}
            </TabView>
        // </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    // return prevProps.location.pathname === nextProps.location.pathname;
    return true
};

export default React.memo(ShopSalesNew, comparisonFn);
