import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';
import sponsorImg from '../../assets/images/tempsponsors/spooorts.png';
import { useLocation } from 'react-router-dom';

const ContactNews = () => {
    const formatDate = (date) => {
        const currentdate = new Date (date)
        const day = String(currentdate.getDate()).padStart(2, '0');
        const month = String(currentdate.getMonth() + 1).padStart(2, '0')
        const year = currentdate.getFullYear();
        const hours = currentdate.getHours();
        const minutes = currentdate.getMinutes();
        const seconds = currentdate.getSeconds();
        const fullTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return `${day}/${month}/${year} ${fullTime}`
    };
    const location = useLocation();
    const [trainingPlans, setTrainingPlans] = useState([]);
    const[newsDialogVisible, setNewsDialogVisible]=useState(false);
    const [newsGroup, setNewsGroup]=useState(emptyNewsGroup);
    const [newsGroups, setNewsGroups]=useState([]);
    const imageUploadInputRef = useRef(null);
    const [imageUpload, setImageUpload]=useState(sponsorImg);
    const [searchField, setSearchField]=useState('');
    const [ dialogClosed, setDialogClosed]=useState(false);
    const history = useHistory()
    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        },
        icon: {
            justifyContent: "center",
            alignItems: "center",
            transform: "scale(1.5)"
        },
    }
    const language=[
        t('german'), t('english')
    ];
    let emptyNewsGroup={
        date: formatDate(new Date()),
        title:'',
        language:'',
        img:imageUpload,
        news: ''
    }
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = event => {
                const dataUrl = event.target.result;
                setImageUpload(dataUrl);
                saveImage(dataUrl, selectedFile.name.trim());
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const saveImage = (dataUrl, imageName) => {
        setImageUpload(dataUrl)
    }
    const saveNews =()=>{
        setNewsDialogVisible(false);
        let _newsGroups = [...newsGroups];
        _newsGroups.push(newsGroup);
        setNewsGroups(_newsGroups);
        setNewsGroup(emptyNewsGroup);
        setDialogClosed(true)
    }
    const closeNewsDialog=()=>{
        setNewsDialogVisible(false)
       setDialogClosed(true)
    }
    const openNewsDialog=()=>{
        setNewsDialogVisible(true);
    }
    const search=()=>{}
    const hiddenInputImageUpload = (
        <input
            type="file"
            ref={imageUploadInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept=".png,.jpg,.jpeg"
        />
    );

    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{": " + t('NEWS_NOTE_MSG')}</p>
            {newsGroups.length!==0 &&(
                <div class="flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={openNewsDialog} className="p-button-outlined"
                    tooltip={t('add_news')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {newsGroups.length==0 &&(
                <div class="flex-center">
                    <Button icon="pi pi-plus" onClick={openNewsDialog} className="p-button-outlined" 
                    tooltip={t('add_news')} tooltipOptions={{position:'right'}}/>
                </div>
            )}
        </div>
    );

    const footer = (
        <div className="flex g-10 buttons">
            <Button type="button" label={t('save')} onClick={() => saveNews()} />
            <Button type="button" className='p-button-outlined' label={t('close')} onClick={(e) => closeNewsDialog()} />
        </div>
    );
    const imageBody=(
        <div class="smallimg">
            <Image src={imageUpload} width='35px' height='35px' /> 
        </div>
    )
    const editNewsDialog=(
        <Dialog visible={!!newsDialogVisible} style={{ 'maxWidth': '750px' }} header='Newsletter' footer={footer} modal closable onHide={() =>{closeNewsDialog()}}>
            <div className='p-flex-group'>
                <div className="group-block w-49">
                    <label htmlFor='title'>{t('title')}</label>
                    <InputText  id='title' value={newsGroup?.title} onChange={(e) => setNewsGroup({ ...newsGroup, ...{ title: e.target.value } })} required autoFocus/>
                </div>
                <div className='group-block w-49'>
                    <label htmlFor='language'>{t('language')}</label>
                    <Dropdown id='language' placeholder={t('german')} options={language} onChange={e => setNewsGroup(prev => ({
                        ...prev, 
                        language: e.value
                    }))} />
                </div>
            </div>
            <hr className='mb-4' />
            <div class="p-flex-group align-center">
                <div className="group-block w-50">
                    <Button icon="pi pi-plus" className="w-100 p-button-outlined" label={t('addNewsPhoto')} onClick={() => imageUploadInputRef.current.click()} />
                </div>
                <div className="group-block w-20" style={styles.icon}>
                    <div class="smallimg">
                        <Image src={imageUpload} width='35px' height='35px'></Image> 
                    </div>
                </div>
            </div>
            <hr/>
            <div className="fluid">
                <label htmlFor="news mb-2">{t('news')}</label>
                <InputTextarea id="news" className='w-100 mt-2' value={newsGroup?.news} onChange={(e) => setNewsGroup({ ...newsGroup, ...{ news: e.target.value } })} />
            </div>
            {hiddenInputImageUpload}   
        </Dialog>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={newsGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="date" header={t('date')} sortable></Column>
                    <Column field="title" header={t('title')} sortable></Column>
                    <Column field="img" body={imageBody}></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {editNewsDialog}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContactNews, comparisonFn);
