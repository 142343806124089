import React from 'react';

const AppFooter = () => {
    const currentYear = new Date().getFullYear();

    // return (
    //     <div className="layout-footer">
    //         <div class="footer-logo">
    //             <img id="app-logo" class="logo-image spooortsLogo" />
    //         </div>
    //         <span className="copyright">&#169; spooorts - {currentYear}</span>
    //     </div>
    // );
    return (
        <>
        <div className="layout-footer grid mobile-footer">
            <div className='col-12 desktop-footer-icons'>
                <h4 className='mr-5 footer-text'>Apps</h4>
                <a href="https://apps.apple.com/de/app/spooorts-events/id1223827266?l=de#?platform=iphone" target="_blank" title="iOS app">
                    <i className='pi pi-apple mr-5' style={{ fontSize: '28px' }} ></i>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.spooorts.event" target="_blank" title="Android app">
                    <i className='pi pi-android mr-5' style={{ fontSize: '28px' }}></i>
                </a>
                <a href="https://www.facebook.com/spooorts.info/" target="_blank" title="Facebook">
                    <i className='pi pi-facebook mr-3' style={{ fontSize: '27px' }}></i>
                </a>
                <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fspooorts.info&amp;layout=button_count&amp;action=like&amp;show_faces=false&amp;share=false"  style={{border:'none', overflow:'hidden', width:'125px', height:'21px'}} allowtransparency="true"></iframe>
            </div>
        </div>
        <div className="layout-footer grid desktop-footer">
            <div className='col-8 desktop-footer-icons'>
                <h4 className='mr-5 footer-text'>Apps</h4>
                    <a href="https://apps.apple.com/de/app/spooorts-events/id1223827266?l=de#?platform=iphone" target="_blank" title="iOS app">
                        <i className='pi pi-apple mr-5' style={{ fontSize: '27px' }} ></i>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.spooorts.event" target="_blank" title="Android app">
                        <i className='pi pi-android' style={{ fontSize: '28px' }}></i>
                    </a>
            </div>
            <div className='col-4 desktop-footer-fb'>
                    <a href="https://www.facebook.com/spooorts.info/" target="_blank" title="Facebook">
                        <i className='pi pi-facebook mr-3' style={{ fontSize: '28px' }}></i>
                    </a>
                <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fspooorts.info&amp;layout=button_count&amp;action=like&amp;show_faces=false&amp;share=false" style={{border:'none', overflow:'hidden', width:'125px', height:'21px'}} allowtransparency="true"></iframe>
            </div>
        </div>
        </>
    )
}

export default AppFooter;
