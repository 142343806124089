import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { storeContact } from '../../StoreContact';
import TrainingService from '../../service/TrainingService';
import ContactService from '../../service/contact.service';
import { useLocation } from 'react-router-dom';

const ContactOur = () => {
    const location = useLocation();
    const [eventDialog, setEventDialog] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const fileInputRef = useRef(null);
    const [searchField, setSearchField]=useState('');
    const [contacts, setContacts]=useState([]);
    const[dialogClosed, setDialogClosed]=useState(false);
    const history = useHistory();

    const styles = {
        table: {
            padding: "0"
        },
        p: {
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const contactService = new ContactService();
  
    useEffect(() => {
        storeContact.subscribe(() => {
            switch (storeContact.getState()) {
                case 0: setEventDialog(true); break;
                case 2: fileInputRef.current.click(); break;
                default: break;
            };
        });
    }, []);
    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    useEffect(() => {
        if (dialogClosed) {
            window.windowCheck();
        }
    }, [dialogClosed]);

    const handleContactImport = (event) => {
        contactService.handleContactImport(event);
    }
    const planCreateRedirect = () => {
        history.push('/shopDeliveryCreate');
    }
    const close =()=>{
        setEventDialog(false);
        setDialogClosed(true)
    }
    const search=()=>{}

   const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>: {t('CONTACT_OUR')}</p>
            {contacts.length!==0 && (
                <div class="table-header-inner-container">
                    <div class="table-header-inner-container">
                        <InputText onChange={(e) => setSearchField(e.target.value)} className='mr-1'></InputText>
                        <Button icon="pi pi-search" onClick={planCreateRedirect} className="p-button-outlined mr-1" />
                    </div>
                    <div class="table-header-inner-container mt-10">
                        <Dropdown placeholder="Alle"  className='mr-1'/>
                        <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-1"
                        tooltip={t('new_contact')} tooltipOptions={{position:'top'}} />
                        <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
                    </div>
                </div>
            )}
            {contacts.length==0 && (
                <div class="table-header-inner-container">
                    <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined mr-1" 
                        tooltip={t('new_contact')} tooltipOptions={{position:'top'}}/>
                    <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
                </div>
            )}
                {/* {
                    !isMobile ? (
                        <div className='flex g-5'>
                            <Button icon="pi pi-search" onClick={planCreateRedirect} className="p-button-outlined" />
                            <Button icon="pi pi-plus" onClick={() => setEventDialog(true)} className="p-button-outlined" />
                            <Button icon="pi pi-upload" onClick={() => fileInputRef.current.click()} className="p-button-outlined" />
                        </div>
                    ) : <span></span>
                } */}
        </div>
    );

    const dialog = (
        <>
            <Dialog header={t('contacts')} visible={eventDialog} onHide={() => close()} style={{ width: '850px' }} position="top" modal>
                {/* <div class="card"> */}
                    <div class="flex-row">
                        <div class="flex-column w-25">
                            <p>{t('SALUTATION_SIMPLE')}</p>
                            <Dropdown className='w-100'></Dropdown>
                        </div>
                        <div class="flex-column">
                            <p>{t('title')}</p>
                            <InputText></InputText>
                        </div>
                        <div class="flex-column">
                            <p>{t('firstName')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('surname')}</p>
                            <InputText></InputText>

                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row">
                        <div class="flex-column w-25">
                            <p>{t('street')}</p>
                            <InputText className='w-100'></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('houseNum')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('index')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('area')}</p>
                            <InputText></InputText>

                        </div>
                    </div>
                    <hr />
                    <div class="flex-row">
                        <div class="flex-column w-25">
                            <p>{t('country')}</p>
                            <Dropdown className='w-100'></Dropdown>
                        </div>
                        <div class="flex-column">
                            <p>eMail</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('DATE_OF_BIRTH')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>Url</p>
                            <InputText></InputText>

                        </div>
                    </div>
                    <hr />
                    <div class="flex-row">
                        <div class="flex-column w-25">
                            <p>{t('category')}</p>
                            <Dropdown className='w-100'></Dropdown>
                        </div>
                        <div class="flex-column">
                            <p>{t('MOBILE')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>{t('PHONE_NUMBER')}</p>
                            <InputText></InputText>

                        </div>
                        <div class="flex-column">
                            <p>Fax</p>
                            <InputText></InputText>
                        </div>
                    </div>

                    <div class="p-flex-group mt-30 g-10 buttons">
                        <Button label={t('save')} ></Button>
                        <Button label={t('close')} className='btn-outline colored' onClick={close}></Button>
                    </div>
                {/* </div> */}
            </Dialog>
        </>
    )
    const hiddenInput = (
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleContactImport}
            accept=".csv,.xlsx"
        />
    );

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={contacts} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="type" header={t('firstName')} sortable></Column>
                    <Column field="price" header={t('surname')} sortable></Column>
                    <Column field="currency" header="eMail" sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialog}
            {hiddenInput}
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContactOur, comparisonFn);
