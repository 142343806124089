
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { t } from 'i18next';
import TrainingService from '../../service/TrainingService';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';

const EditContactsDialog=()=>{
    const [eventDialog, setEventDialog] = useState(false);

    return(
        // <Dialog header="Kontakt" visible={eventDialog} onHide={() => setEventDialog(false)} style={{ width: '850px' }} position="top" modal>
            <div>
                <div class="flex-row">
                    <div class="flex-column w-25">
                        <p>{t('SALUTATION_SIMPLE')}</p>
                        <Dropdown className='w-100'></Dropdown>
                    </div>
                    <div class="flex-column">
                        <p>{t('title')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('firstName')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('surname')}</p>
                        <InputText></InputText>
                    </div>
                </div>
                <hr/>
                <div class="flex-row">
                    <div class="flex-column w-25">
                        <p>{t('street')}</p>
                        <InputText className='w-100'></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('houseNum')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('index')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('area')}</p>
                        <InputText></InputText>
                    </div>
                </div>
                <hr />
                <div class="flex-row">
                    <div class="flex-column w-25">
                        <p>{t('country')}</p>
                        <Dropdown className='w-100'></Dropdown>
                    </div>
                    <div class="flex-column">
                        <p>eMail</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('DATE_OF_BIRTH')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>Url</p>
                        <InputText></InputText>
                    </div>
                </div>
                <hr />
                <div class="flex-row">
                    <div class="flex-column w-25">
                        <p>{t('category')}</p>
                        <Dropdown className='w-100'></Dropdown>
                    </div>
                    <div class="flex-column">
                        <p>Mobil</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>{t('PHONE_NUMBER')}</p>
                        <InputText></InputText>
                    </div>
                    <div class="flex-column">
                        <p>Fax</p>
                        <InputText></InputText>
                    </div>
                </div>
            </div>
        // </Dialog>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    // return prevProps.location.pathname === nextProps.location.pathname;
    return true
};

export default React.memo(EditContactsDialog, comparisonFn);