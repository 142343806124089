import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import TrainingService from '../../service/TrainingService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ShopSalesNew from './shop-sales-new';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';

const ShopSales = () => {
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [planDialogVisible, setPlanDialogVisible]=useState(false);
    const [searchField, setSearchField]=useState('');
    const [sellGroups, setSellGreoups]=useState([]);
    const history = useHistory();
    const location = useLocation();
    const styles = {
        table: {
            padding: "0"
        },
        p:{
            fontWeight: "400",
            fontSize: "11px",
            color: "grey"
        },
        span: {
            fontWeight: "bold"
        },
        button: {
            textTransform: "uppercase"
        }
    }

    const trainingService = new TrainingService();

    useEffect(() => {
        trainingService.getTrainingPlans().then(plans =>{ setTrainingPlans(plans); console.log(plans)});
    }, [])

    useEffect(() => {
        const handleWindowCheck = () => {
            window.windowCheck();
        };
        handleWindowCheck();
        window.addEventListener('resize', window.resize);
        return () => {
            window.removeEventListener('resize', window.resize);
        };
    }, [location]);

    const planCreateRedirect = () => {
        // history.push('/shopSaleCreate');
        setPlanDialogVisible(true);
        window.windowCheck();
    }

    const enterProductSale = () => {}
    const search=()=>{}

    const header = (
        <div className="table-header-container">
            <p style={styles.p}><span style={styles.span}>{t('notice')}</span>{t('salesNoticeContent')}</p>
            {sellGroups.length!==0 &&(
                <div className="table-header-container flex-center">
                    <InputText onChange={(e) => setSearchField(e.target.value)}></InputText>
                    <Button icon="pi pi-search" onClick={search} className="p-button-outlined" />
                    <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined"
                    tooltip={t('enterProductSale')} tooltipOptions={{position:'top'}} />
                </div>
            )}
            {sellGroups.length==0 &&(
                <div className="table-header-container flex-center">
                    <Button icon="pi pi-plus" onClick={planCreateRedirect} className="p-button-outlined"
                    tooltip={t('enterProductSale')} tooltipOptions={{position:'top'}} />
                </div>
            )}
        </div>
    );

    const footer = (
        <div style={styles.footer}>
            <Button type="button" style={styles.button} label={t('enterProductSale')}  onClick={planCreateRedirect} />
        </div>
    );
    const editTemplate=(
        <div className='table-row'>
            <Button type="button" icon="pi pi-pencil" className='mr-3' onClick={() => null} 
                tooltip={t('EDIT_TEMPLATE')} tooltipOptions={{ position: 'top' }}></Button>
            <Button type="button" icon="pi pi-trash" onClick={() => null}
                tooltip={t('DELETE')} tooltipOptions={{ position: 'top' }}></Button>
        </div>
    )

    const dialogPlanCreate =(
        <Dialog visible={planDialogVisible} header={t('productSale')} onHide={() => {setPlanDialogVisible(false)}} style={{ maxWidth: '850px' }} position="center" modal>
            <ShopSalesNew/>
        </Dialog>
    )

    return (
        <div>
            <div className='card' style={styles.table}>
                <DataTable value={sellGroups} responsiveLayout="scroll" header={header} paginator rows={10} emptyMessage={t('emptyMessage')}>
                    <Column field="date" header={t('date')} sortable></Column>
                    <Column field="usage" header={t('usage')} sortable></Column>
                    <Column field="price" header={t('price')} sortable></Column>
                    <Column field="currency" header={t('currency')} sortable></Column>
                    <Column field='edit' body={editTemplate}></Column>
                </DataTable>
            </div>
            {dialogPlanCreate}
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopSales, comparisonFn);
